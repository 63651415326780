import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Base64: any;
  Date: string;
  DateTime: string;
  JSONString: any;
  UUID: string;
  Upload: File | false | undefined;
};

export type AccountListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['ID']>>>;
  region?: Maybe<Array<Maybe<Scalars['ID']>>>;
  owner?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AccountListType = {
  __typename?: 'AccountListType';
  items: Array<AccountType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type AccountRegionListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type AccountRegionListType = {
  __typename?: 'AccountRegionListType';
  items: Array<AccountRegionType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type AccountRegionType = {
  __typename?: 'AccountRegionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  tenant: TenantType;
  accountSet: Array<AccountType>;
};

export type AccountType = {
  __typename?: 'AccountType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  legalName: Scalars['String'];
  taxId?: Maybe<Scalars['String']>;
  taxIdFormat?: Maybe<AccountsAccountTaxIdFormatChoices>;
  type?: Maybe<AccountTypeType>;
  region?: Maybe<AccountRegionType>;
  externalId: Scalars['String'];
  owner?: Maybe<UserType>;
  notes: Scalars['String'];
  tenant: TenantType;
  active: Scalars['Boolean'];
  contacts: Array<ContactType>;
  activities: Array<ActivityType>;
  quotes: Array<QuoteType>;
};

export type AccountTypeListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type AccountTypeListType = {
  __typename?: 'AccountTypeListType';
  items: Array<AccountTypeType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type AccountTypeType = {
  __typename?: 'AccountTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  tenant: TenantType;
  accountSet: Array<AccountType>;
};

export enum AccountsAccountTaxIdFormatChoices {
  ArCuit = 'AR_CUIT',
  ChRut = 'CH_RUT',
  UyRut = 'UY_RUT',
  BrCnpj = 'BR_CNPJ',
  UsaEin = 'USA_EIN',
  Other = 'OTHER'
}

export type ActiveTogglerAccountMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActiveTogglerAccountMutationPayload = {
  __typename?: 'ActiveTogglerAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActiveTogglerContactMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActiveTogglerContactMutationPayload = {
  __typename?: 'ActiveTogglerContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActivitiesAnalyticsFiltersInput = {
  season: Scalars['String'];
  user?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
};

export type ActivitiesAnalyticsType = {
  __typename?: 'ActivitiesAnalyticsType';
  season: SeasonType;
  total: ActivityTotalType;
  byChannel: Array<ActivityCountDatumType>;
  byReason: Array<ActivityCountDatumType>;
  byDate: Array<ActivityDateSeriesDatumType>;
};

export type ActivityChannelListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type ActivityChannelListType = {
  __typename?: 'ActivityChannelListType';
  items: Array<ActivityChannelType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ActivityChannelType = {
  __typename?: 'ActivityChannelType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  tenant: TenantType;
};

export type ActivityCountDatumType = {
  __typename?: 'ActivityCountDatumType';
  label: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Float'];
};

export type ActivityDateSeriesDatumType = {
  __typename?: 'ActivityDateSeriesDatumType';
  date: Scalars['Date'];
  count: Scalars['Int'];
};

export type ActivityListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  channelIexact?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reasonIexact?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contact?: Maybe<Array<Maybe<Scalars['ID']>>>;
  account?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ActivityListType = {
  __typename?: 'ActivityListType';
  items: Array<ActivityType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ActivityReasonListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type ActivityReasonListType = {
  __typename?: 'ActivityReasonListType';
  items: Array<ActivityReasonType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ActivityReasonType = {
  __typename?: 'ActivityReasonType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  tenant: TenantType;
};

export type ActivityTotalType = {
  __typename?: 'ActivityTotalType';
  count: Scalars['Int'];
};

export type ActivityType = {
  __typename?: 'ActivityType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  date: Scalars['Date'];
  channel: Scalars['String'];
  reason: Scalars['String'];
  notes: Scalars['String'];
  contact: ContactType;
  account: AccountType;
  assignedTo?: Maybe<UserType>;
};

export type AggregatedSalesTargetType = {
  __typename?: 'AggregatedSalesTargetType';
  amount: Scalars['Float'];
  season: SeasonType;
};

export type AnnulSaleMutationInput = {
  annuledReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AnnulSaleMutationPayload = {
  __typename?: 'AnnulSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type BillingTypeListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type BillingTypeListType = {
  __typename?: 'BillingTypeListType';
  items: Array<BillingTypeType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type BillingTypeType = {
  __typename?: 'BillingTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  tenant: TenantType;
};

export type ContactListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['ID']>>>;
  accounts?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ContactListType = {
  __typename?: 'ContactListType';
  items: Array<ContactType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ContactThoughAccountType = {
  __typename?: 'ContactThoughAccountType';
  contact?: Maybe<ContactType>;
  account: AccountType;
};

export type ContactType = {
  __typename?: 'ContactType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phone: Scalars['String'];
  mobileCountryCode: Scalars['String'];
  mobilePhone: Scalars['String'];
  type?: Maybe<ContactTypeType>;
  accounts: Array<AccountType>;
  notes: Scalars['String'];
  tenant: TenantType;
  active: Scalars['Boolean'];
  activities: Array<ActivityType>;
  quotes: Array<QuoteType>;
  displayName: Scalars['String'];
};

export type ContactTypeListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type ContactTypeListType = {
  __typename?: 'ContactTypeListType';
  items: Array<ContactTypeType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ContactTypeType = {
  __typename?: 'ContactTypeType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  tenant: TenantType;
  contactSet: Array<ContactType>;
};

export type CreateAccountMutationInput = {
  name: Scalars['String'];
  legalName: Scalars['String'];
  taxId: Scalars['String'];
  taxIdFormat?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAccountMutationPayload = {
  __typename?: 'CreateAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAccountRegionMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAccountRegionMutationPayload = {
  __typename?: 'CreateAccountRegionMutationPayload';
  accountRegion?: Maybe<AccountRegionType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAccountTypeMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAccountTypeMutationPayload = {
  __typename?: 'CreateAccountTypeMutationPayload';
  accountType?: Maybe<AccountTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateActivityChannelMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateActivityChannelMutationPayload = {
  __typename?: 'CreateActivityChannelMutationPayload';
  activityChannel?: Maybe<ActivityChannelType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateActivityMutationInput = {
  date: Scalars['Date'];
  channel: Scalars['String'];
  reason: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  contact: Scalars['ID'];
  account: Scalars['ID'];
  assignedTo: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateActivityMutationPayload = {
  __typename?: 'CreateActivityMutationPayload';
  activity?: Maybe<ActivityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateActivityReasonMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateActivityReasonMutationPayload = {
  __typename?: 'CreateActivityReasonMutationPayload';
  activityReason?: Maybe<ActivityReasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBillingTypeMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBillingTypeMutationPayload = {
  __typename?: 'CreateBillingTypeMutationPayload';
  billingType?: Maybe<BillingTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactMutationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneCountryCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
  accounts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notes?: Maybe<Scalars['String']>;
  tenant: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactMutationPayload = {
  __typename?: 'CreateContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactTypeMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContactTypeMutationPayload = {
  __typename?: 'CreateContactTypeMutationPayload';
  contactType?: Maybe<ContactTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLostReasonMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLostReasonMutationPayload = {
  __typename?: 'CreateLostReasonMutationPayload';
  lostReason?: Maybe<LostReasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationMutationInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationMutationPayload = {
  __typename?: 'CreateOrganizationMutationPayload';
  organization?: Maybe<OrganizationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodMutationInput = {
  name: Scalars['String'];
  minMultiplier: Scalars['Float'];
  maxMultiplier: Scalars['Float'];
  allowsFinancing?: Maybe<Scalars['Boolean']>;
  defaultFinancingMonths?: Maybe<Scalars['Int']>;
  defaultInterestRate: Scalars['Float'];
  order: Scalars['Int'];
  isAnnualInterestRate?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodMutationPayload = {
  __typename?: 'CreatePaymentMethodMutationPayload';
  paymentMethod?: Maybe<PaymentMethodType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductCategoryMutationInput = {
  name: Scalars['String'];
  published?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductCategoryMutationPayload = {
  __typename?: 'CreateProductCategoryMutationPayload';
  productCategory?: Maybe<ProductCategoryType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductMutationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  unit: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  suppliesCompany: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  priceUpdated?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductMutationPayload = {
  __typename?: 'CreateProductMutationPayload';
  product?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductPriceModifierMutationInput = {
  name: Scalars['String'];
  minDiscount: Scalars['Float'];
  maxDiscount: Scalars['Float'];
  maxWorkflowDiscount?: Maybe<Scalars['Float']>;
  discountHasWorkflowRange?: Maybe<Scalars['Boolean']>;
  minSurcharge: Scalars['Float'];
  maxSurcharge: Scalars['Float'];
  maxWorkflowSurcharge?: Maybe<Scalars['Float']>;
  surchargeHasWorkflowRange?: Maybe<Scalars['Boolean']>;
  expirationDate: Scalars['Date'];
  order: Scalars['Int'];
  categories: Array<Maybe<Scalars['ID']>>;
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductPriceModifierMutationPayload = {
  __typename?: 'CreateProductPriceModifierMutationPayload';
  productPriceModifier?: Maybe<ProductPriceModifierType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductSuppliesCompanyMutationInput = {
  name: Scalars['String'];
  category: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductSuppliesCompanyMutationPayload = {
  __typename?: 'CreateProductSuppliesCompanyMutationPayload';
  productSuppliesCompany?: Maybe<ProductSuppliesCompanyType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductVariationMutationInput = {
  name: Scalars['String'];
  multiplier: Scalars['Float'];
  categories: Array<Maybe<Scalars['ID']>>;
  published?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProductVariationMutationPayload = {
  __typename?: 'CreateProductVariationMutationPayload';
  productVariation?: Maybe<ProductVariationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteExport = {
  __typename?: 'CreateQuoteExport';
  job: ExportJobType;
};

export type CreateQuoteMutationInput = {
  contact?: Maybe<Scalars['ID']>;
  account: Scalars['ID'];
  successChance?: Maybe<Scalars['ID']>;
  billingType?: Maybe<Scalars['ID']>;
  internalNotes?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  subTotal: Scalars['Float'];
  paymentMethods: Array<QuotePaymentMethodFormInput>;
  products: Array<QuoteProductFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteMutationPayload = {
  __typename?: 'CreateQuoteMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuotePdfMutationInput = {
  quote: Scalars['ID'];
  type: Scalars['String'];
  recipients: Array<QuotePdfRecipientFormInput>;
  mode: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuotePdfMutationPayload = {
  __typename?: 'CreateQuotePDFMutationPayload';
  quotePDF?: Maybe<QuotePdfType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteSuccessChanceMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateQuoteSuccessChanceMutationPayload = {
  __typename?: 'CreateQuoteSuccessChanceMutationPayload';
  quoteSuccessChance?: Maybe<QuoteSuccessChanceType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRoleMutationInput = {
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRoleMutationPayload = {
  __typename?: 'CreateRoleMutationPayload';
  role?: Maybe<RoleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSaleExport = {
  __typename?: 'CreateSaleExport';
  job: ExportJobType;
};

export type CreateSaleMutationInput = {
  quote: Scalars['ID'];
  date: Scalars['Date'];
  internalNotes?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  annuledReason?: Maybe<Scalars['String']>;
  annuled?: Maybe<Scalars['Boolean']>;
  paymentMethods: Array<SalePaymentMethodFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSaleMutationPayload = {
  __typename?: 'CreateSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalePdfMutationInput = {
  sale: Scalars['ID'];
  recipients: Array<SalePdfRecipientFormInput>;
  type: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalePdfMutationPayload = {
  __typename?: 'CreateSalePDFMutationPayload';
  salePDF?: Maybe<SalePdfType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalesTargetMutationInput = {
  season: Scalars['ID'];
  user: Scalars['ID'];
  amount: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalesTargetMutationPayload = {
  __typename?: 'CreateSalesTargetMutationPayload';
  salesTarget?: Maybe<SalesTargetType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSalesTargetsMutation = {
  __typename?: 'CreateSalesTargetsMutation';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  salesTargets: Array<Maybe<SalesTargetType>>;
};

export type CreateSeasonMutationInput = {
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSeasonMutationPayload = {
  __typename?: 'CreateSeasonMutationPayload';
  season?: Maybe<SeasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUnitMutationInput = {
  name: Scalars['String'];
  symbol: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUnitMutationPayload = {
  __typename?: 'CreateUnitMutationPayload';
  unit?: Maybe<UnitType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserMutationInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isTenantStaff?: Maybe<Scalars['Boolean']>;
  parentUser?: Maybe<Scalars['ID']>;
  teamNode?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserMutationPayload = {
  __typename?: 'CreateUserMutationPayload';
  user?: Maybe<UserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CumulativeSalesDatumType = {
  __typename?: 'CumulativeSalesDatumType';
  date: Scalars['Date'];
  amount: Scalars['Float'];
  forecastAmount: Scalars['Float'];
};

export type CurrentUsersSalesTargetsListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type CurrentUsersSalesTargetsListType = {
  __typename?: 'CurrentUsersSalesTargetsListType';
  items: Array<UserType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};



export type DatumType = {
  __typename?: 'DatumType';
  label: Scalars['String'];
  count: Scalars['Int'];
  amount: Scalars['Float'];
};

export type DefaultProductCategoryType = {
  __typename?: 'DefaultProductCategoryType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type DefaultProductSuppliesCompanyType = {
  __typename?: 'DefaultProductSuppliesCompanyType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type DeleteAccountMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAccountMutationPayload = {
  __typename?: 'DeleteAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAccountRegionMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAccountRegionMutationPayload = {
  __typename?: 'DeleteAccountRegionMutationPayload';
  accountRegion?: Maybe<AccountRegionType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAccountTypeMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAccountTypeMutationPayload = {
  __typename?: 'DeleteAccountTypeMutationPayload';
  accountType?: Maybe<AccountTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteActivityChannelMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteActivityChannelMutationPayload = {
  __typename?: 'DeleteActivityChannelMutationPayload';
  activityChannel?: Maybe<ActivityChannelType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteActivityMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteActivityMutationPayload = {
  __typename?: 'DeleteActivityMutationPayload';
  activity?: Maybe<ActivityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteActivityReasonMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteActivityReasonMutationPayload = {
  __typename?: 'DeleteActivityReasonMutationPayload';
  activityReason?: Maybe<ActivityReasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteBillingTypeMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteBillingTypeMutationPayload = {
  __typename?: 'DeleteBillingTypeMutationPayload';
  billingType?: Maybe<BillingTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteContactMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteContactMutationPayload = {
  __typename?: 'DeleteContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteContactTypeMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteContactTypeMutationPayload = {
  __typename?: 'DeleteContactTypeMutationPayload';
  contactType?: Maybe<ContactTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLostReasonMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLostReasonMutationPayload = {
  __typename?: 'DeleteLostReasonMutationPayload';
  lostReason?: Maybe<LostReasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteNotification = {
  __typename?: 'DeleteNotification';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteOrganizationMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteOrganizationMutationPayload = {
  __typename?: 'DeleteOrganizationMutationPayload';
  organization?: Maybe<OrganizationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePaymentMethodMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePaymentMethodMutationPayload = {
  __typename?: 'DeletePaymentMethodMutationPayload';
  paymentMethod?: Maybe<PaymentMethodType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductCategoryMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductCategoryMutationPayload = {
  __typename?: 'DeleteProductCategoryMutationPayload';
  productCategory?: Maybe<ProductCategoryType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductMutationPayload = {
  __typename?: 'DeleteProductMutationPayload';
  product?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductPriceModifierMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductPriceModifierMutationPayload = {
  __typename?: 'DeleteProductPriceModifierMutationPayload';
  productPriceModifier?: Maybe<ProductPriceModifierType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductSuppliesCompanyMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductSuppliesCompanyMutationPayload = {
  __typename?: 'DeleteProductSuppliesCompanyMutationPayload';
  productSuppliesCompany?: Maybe<ProductSuppliesCompanyType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductVariationMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProductVariationMutationPayload = {
  __typename?: 'DeleteProductVariationMutationPayload';
  productVariation?: Maybe<ProductVariationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteMutationPayload = {
  __typename?: 'DeleteQuoteMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteSuccessChanceMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteQuoteSuccessChanceMutationPayload = {
  __typename?: 'DeleteQuoteSuccessChanceMutationPayload';
  quoteSuccessChance?: Maybe<QuoteSuccessChanceType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRoleMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRoleMutationPayload = {
  __typename?: 'DeleteRoleMutationPayload';
  role?: Maybe<RoleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSaleMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSaleMutationPayload = {
  __typename?: 'DeleteSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSalesTargetMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSalesTargetMutationPayload = {
  __typename?: 'DeleteSalesTargetMutationPayload';
  salesTarget?: Maybe<SalesTargetType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSalesTargetsMutation = {
  __typename?: 'DeleteSalesTargetsMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type DeleteSeasonMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSeasonMutationPayload = {
  __typename?: 'DeleteSeasonMutationPayload';
  season?: Maybe<SeasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUnitMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUnitMutationPayload = {
  __typename?: 'DeleteUnitMutationPayload';
  unit?: Maybe<UnitType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DownloadType = {
  __typename?: 'DownloadType';
  contentType: Scalars['String'];
  data: Scalars['Base64'];
  filename: Scalars['String'];
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type ExportJobType = {
  __typename?: 'ExportJobType';
  id: Scalars['UUID'];
  status: JobStatus;
  result?: Maybe<DownloadType>;
};

export type FeatureTutorialType = {
  __typename?: 'FeatureTutorialType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  codename: SystemFeatureTutorialCodenameChoices;
  language: SystemFeatureTutorialLanguageChoices;
  published: Scalars['Boolean'];
  steps?: Maybe<Array<Maybe<TutorialStepType>>>;
};

export type ImportAccountsMutationInput = {
  file: Scalars['Upload'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportAccountsMutationPayload = {
  __typename?: 'ImportAccountsMutationPayload';
  file: Scalars['Upload'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportContactsMutationInput = {
  file: Scalars['Upload'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportContactsMutationPayload = {
  __typename?: 'ImportContactsMutationPayload';
  file: Scalars['Upload'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportProductsMutationInput = {
  file: Scalars['Upload'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportProductsMutationPayload = {
  __typename?: 'ImportProductsMutationPayload';
  file: Scalars['Upload'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportSalesTargetMutationInput = {
  file: Scalars['Upload'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportSalesTargetMutationPayload = {
  __typename?: 'ImportSalesTargetMutationPayload';
  file: Scalars['Upload'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportUsersMutationInput = {
  file: Scalars['Upload'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ImportUsersMutationPayload = {
  __typename?: 'ImportUsersMutationPayload';
  file: Scalars['Upload'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export enum JobStatus {
  Queued = 'QUEUED',
  Finished = 'FINISHED',
  Failed = 'FAILED',
  Started = 'STARTED',
  Deferred = 'DEFERRED',
  Scheduled = 'SCHEDULED',
  Stopped = 'STOPPED',
  Canceled = 'CANCELED'
}

export type JobType = {
  __typename?: 'JobType';
  id: Scalars['UUID'];
  status: JobStatus;
  result?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  email: Scalars['String'];
  password: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  user?: Maybe<UserType>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LogoMutationInput = {
  logo?: Maybe<Scalars['Upload']>;
  pdfLogo?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LogoMutationPayload = {
  __typename?: 'LogoMutationPayload';
  tenant?: Maybe<TenantType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Logout = {
  __typename?: 'Logout';
  success?: Maybe<Scalars['Boolean']>;
};

export type LostReasonListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type LostReasonListType = {
  __typename?: 'LostReasonListType';
  items: Array<LostReasonType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type LostReasonType = {
  __typename?: 'LostReasonType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  tenant: TenantType;
  quoteSet: Array<QuoteType>;
};

export type MarkAllNotificationsAsRead = {
  __typename?: 'MarkAllNotificationsAsRead';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MarkNotificationAsRead = {
  __typename?: 'MarkNotificationAsRead';
  ok?: Maybe<Scalars['Boolean']>;
};

export type MarkQuoteAsLostMutationInput = {
  lostReasonId: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkQuoteAsLostMutationPayload = {
  __typename?: 'MarkQuoteAsLostMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkQuoteAsRequestedExceptionMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MarkQuoteAsRequestedExceptionMutationPayload = {
  __typename?: 'MarkQuoteAsRequestedExceptionMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  markNotificationAsRead?: Maybe<MarkNotificationAsRead>;
  markAllNotificationsAsRead?: Maybe<MarkAllNotificationsAsRead>;
  deleteNotification?: Maybe<DeleteNotification>;
  updateWorkflow?: Maybe<UpdateWorkflowMutationPayload>;
  updateQuoteDisclaimer?: Maybe<UpdateQuoteDisclaimerMutationPayload>;
  updateSaleDisclaimer?: Maybe<UpdateSaleDisclaimerMutationPayload>;
  updateQuoteExpirationDays?: Maybe<UpdateQuoteExpirationDaysFormMutationPayload>;
  updateCurrency?: Maybe<UpdateCurrencyMutationPayload>;
  logo?: Maybe<LogoMutationPayload>;
  updatePdfConfig?: Maybe<PdfConfigMutation>;
  login?: Maybe<LoginPayload>;
  logout?: Maybe<Logout>;
  createUser?: Maybe<CreateUserMutationPayload>;
  updateUser?: Maybe<UpdateUserMutationPayload>;
  updatePassword?: Maybe<UpdatePasswordMutationPayload>;
  resetPassword?: Maybe<ResetPasswordMutationPayload>;
  setPassword?: Maybe<SetPasswordMutationPayload>;
  updateUserProfile?: Maybe<UpdateUserProfileMutationPayload>;
  sendResetPasswordEmail?: Maybe<SendResetPasswordEmailMutationPayload>;
  sendUserActivationEmail?: Maybe<SendUserActivationEmailMutationPayload>;
  createRole?: Maybe<CreateRoleMutationPayload>;
  updateRole?: Maybe<UpdateRoleMutationPayload>;
  deleteRole?: Maybe<DeleteRoleMutationPayload>;
  createOrganization?: Maybe<CreateOrganizationMutationPayload>;
  updateOrganization?: Maybe<UpdateOrganizationMutationPayload>;
  deleteOrganization?: Maybe<DeleteOrganizationMutationPayload>;
  importUsers?: Maybe<ImportUsersMutationPayload>;
  toggleEmailNotifications?: Maybe<ToggleEmailNotifications>;
  createSeason?: Maybe<CreateSeasonMutationPayload>;
  updateSeason?: Maybe<UpdateSeasonMutationPayload>;
  deleteSeason?: Maybe<DeleteSeasonMutationPayload>;
  createSalesTarget?: Maybe<CreateSalesTargetMutationPayload>;
  createMultipleSalesTargets?: Maybe<CreateSalesTargetsMutation>;
  updateSalesTarget?: Maybe<UpdateSalesTargetMutationPayload>;
  deleteSalesTarget?: Maybe<DeleteSalesTargetMutationPayload>;
  deleteMultipleSalesTargets?: Maybe<DeleteSalesTargetsMutation>;
  createSale?: Maybe<CreateSaleMutationPayload>;
  updateSale?: Maybe<UpdateSaleMutationPayload>;
  deleteSale?: Maybe<DeleteSaleMutationPayload>;
  importSalesTarget?: Maybe<ImportSalesTargetMutationPayload>;
  createSalePDF?: Maybe<CreateSalePdfMutationPayload>;
  annulSale?: Maybe<AnnulSaleMutationPayload>;
  createSaleExport?: Maybe<CreateSaleExport>;
  createQuote?: Maybe<CreateQuoteMutationPayload>;
  updateQuote?: Maybe<UpdateQuoteMutationPayload>;
  deleteQuote?: Maybe<DeleteQuoteMutationPayload>;
  createQuotePDF?: Maybe<CreateQuotePdfMutationPayload>;
  startQuoteReview?: Maybe<StartQuoteReviewMutationPayload>;
  quoteTransition?: Maybe<QuoteTransitionMutationPayload>;
  markQuoteAsLost?: Maybe<MarkQuoteAsLostMutationPayload>;
  markQuoteAsRequestedException?: Maybe<MarkQuoteAsRequestedExceptionMutationPayload>;
  transitionQuoteWithException?: Maybe<TransitionQuoteWithExceptionMutationPayload>;
  createQuoteSuccessChance?: Maybe<CreateQuoteSuccessChanceMutationPayload>;
  updateQuoteSuccessChance?: Maybe<UpdateQuoteSuccessChanceMutationPayload>;
  deleteQuoteSuccessChance?: Maybe<DeleteQuoteSuccessChanceMutationPayload>;
  createBillingType?: Maybe<CreateBillingTypeMutationPayload>;
  updateBillingType?: Maybe<UpdateBillingTypeMutationPayload>;
  deleteBillingType?: Maybe<DeleteBillingTypeMutationPayload>;
  updateQuotePrices?: Maybe<UpdateQuotePricesMutationPayload>;
  createLostReason?: Maybe<CreateLostReasonMutationPayload>;
  updateLostReason?: Maybe<UpdateLostReasonMutationPayload>;
  deleteLostReason?: Maybe<DeleteLostReasonMutationPayload>;
  createQuoteExport?: Maybe<CreateQuoteExport>;
  createProduct?: Maybe<CreateProductMutationPayload>;
  updateProduct?: Maybe<UpdateProductMutationPayload>;
  deleteProduct?: Maybe<DeleteProductMutationPayload>;
  createProductCategory?: Maybe<CreateProductCategoryMutationPayload>;
  updateProductCategory?: Maybe<UpdateProductCategoryMutationPayload>;
  deleteProductCategory?: Maybe<DeleteProductCategoryMutationPayload>;
  createProductSuppliesCompany?: Maybe<CreateProductSuppliesCompanyMutationPayload>;
  updateProductSuppliesCompany?: Maybe<UpdateProductSuppliesCompanyMutationPayload>;
  deleteProductSuppliesCompany?: Maybe<DeleteProductSuppliesCompanyMutationPayload>;
  createUnit?: Maybe<CreateUnitMutationPayload>;
  updateUnit?: Maybe<UpdateUnitMutationPayload>;
  deleteUnit?: Maybe<DeleteUnitMutationPayload>;
  createProductVariation?: Maybe<CreateProductVariationMutationPayload>;
  updateProductVariation?: Maybe<UpdateProductVariationMutationPayload>;
  deleteProductVariation?: Maybe<DeleteProductVariationMutationPayload>;
  createProductPriceModifier?: Maybe<CreateProductPriceModifierMutationPayload>;
  updateProductPriceModifier?: Maybe<UpdateProductPriceModifierMutationPayload>;
  deleteProductPriceModifier?: Maybe<DeleteProductPriceModifierMutationPayload>;
  importProducts?: Maybe<ImportProductsMutationPayload>;
  createPaymentMethod?: Maybe<CreatePaymentMethodMutationPayload>;
  updatePaymentMethod?: Maybe<UpdatePaymentMethodMutationPayload>;
  deletePaymentMethod?: Maybe<DeletePaymentMethodMutationPayload>;
  createContact?: Maybe<CreateContactMutationPayload>;
  updateContact?: Maybe<UpdateContactMutationPayload>;
  deleteContact?: Maybe<DeleteContactMutationPayload>;
  activeTogglerContact?: Maybe<ActiveTogglerContactMutationPayload>;
  createContactType?: Maybe<CreateContactTypeMutationPayload>;
  updateContactType?: Maybe<UpdateContactTypeMutationPayload>;
  deleteContactType?: Maybe<DeleteContactTypeMutationPayload>;
  importContacts?: Maybe<ImportContactsMutationPayload>;
  createActivity?: Maybe<CreateActivityMutationPayload>;
  updateActivity?: Maybe<UpdateActivityMutationPayload>;
  deleteActivity?: Maybe<DeleteActivityMutationPayload>;
  createActivityChannel?: Maybe<CreateActivityChannelMutationPayload>;
  updateActivityChannel?: Maybe<UpdateActivityChannelMutationPayload>;
  deleteActivityChannel?: Maybe<DeleteActivityChannelMutationPayload>;
  createActivityReason?: Maybe<CreateActivityReasonMutationPayload>;
  updateActivityReason?: Maybe<UpdateActivityReasonMutationPayload>;
  deleteActivityReason?: Maybe<DeleteActivityReasonMutationPayload>;
  createAccount?: Maybe<CreateAccountMutationPayload>;
  updateAccount?: Maybe<UpdateAccountMutationPayload>;
  deleteAccount?: Maybe<DeleteAccountMutationPayload>;
  activeTogglerAccount?: Maybe<ActiveTogglerAccountMutationPayload>;
  createAccountType?: Maybe<CreateAccountTypeMutationPayload>;
  updateAccountType?: Maybe<UpdateAccountTypeMutationPayload>;
  deleteAccountType?: Maybe<DeleteAccountTypeMutationPayload>;
  createAccountRegion?: Maybe<CreateAccountRegionMutationPayload>;
  updateAccountRegion?: Maybe<UpdateAccountRegionMutationPayload>;
  deleteAccountRegion?: Maybe<DeleteAccountRegionMutationPayload>;
  importAccounts?: Maybe<ImportAccountsMutationPayload>;
};


export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['UUID'];
};


export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowMutationInput;
};


export type MutationUpdateQuoteDisclaimerArgs = {
  input: UpdateQuoteDisclaimerMutationInput;
};


export type MutationUpdateSaleDisclaimerArgs = {
  input: UpdateSaleDisclaimerMutationInput;
};


export type MutationUpdateQuoteExpirationDaysArgs = {
  input: UpdateQuoteExpirationDaysFormMutationInput;
};


export type MutationUpdateCurrencyArgs = {
  input: UpdateCurrencyMutationInput;
};


export type MutationLogoArgs = {
  input: LogoMutationInput;
};


export type MutationUpdatePdfConfigArgs = {
  pdfConfigs: Array<PdfConfigInput>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserMutationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserMutationInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordMutationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordMutationInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordMutationInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileMutationInput;
};


export type MutationSendResetPasswordEmailArgs = {
  input: SendResetPasswordEmailMutationInput;
};


export type MutationSendUserActivationEmailArgs = {
  input: SendUserActivationEmailMutationInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleMutationInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleMutationInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleMutationInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationMutationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationMutationInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationMutationInput;
};


export type MutationImportUsersArgs = {
  input: ImportUsersMutationInput;
};


export type MutationCreateSeasonArgs = {
  input: CreateSeasonMutationInput;
};


export type MutationUpdateSeasonArgs = {
  input: UpdateSeasonMutationInput;
};


export type MutationDeleteSeasonArgs = {
  input: DeleteSeasonMutationInput;
};


export type MutationCreateSalesTargetArgs = {
  input: CreateSalesTargetMutationInput;
};


export type MutationCreateMultipleSalesTargetsArgs = {
  deleteSalesTargets?: Maybe<Array<Scalars['UUID']>>;
  salesTargets: Array<SalesTargetFormInput>;
};


export type MutationUpdateSalesTargetArgs = {
  input: UpdateSalesTargetMutationInput;
};


export type MutationDeleteSalesTargetArgs = {
  input: DeleteSalesTargetMutationInput;
};


export type MutationDeleteMultipleSalesTargetsArgs = {
  salesTargets?: Maybe<Array<Scalars['UUID']>>;
};


export type MutationCreateSaleArgs = {
  input: CreateSaleMutationInput;
};


export type MutationUpdateSaleArgs = {
  input: UpdateSaleMutationInput;
};


export type MutationDeleteSaleArgs = {
  input: DeleteSaleMutationInput;
};


export type MutationImportSalesTargetArgs = {
  input: ImportSalesTargetMutationInput;
};


export type MutationCreateSalePdfArgs = {
  input: CreateSalePdfMutationInput;
};


export type MutationAnnulSaleArgs = {
  input: AnnulSaleMutationInput;
};


export type MutationCreateSaleExportArgs = {
  options?: Maybe<SaleListOptionsInputType>;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteMutationInput;
};


export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteMutationInput;
};


export type MutationDeleteQuoteArgs = {
  input: DeleteQuoteMutationInput;
};


export type MutationCreateQuotePdfArgs = {
  input: CreateQuotePdfMutationInput;
};


export type MutationStartQuoteReviewArgs = {
  input: StartQuoteReviewMutationInput;
};


export type MutationQuoteTransitionArgs = {
  input: QuoteTransitionMutationInput;
};


export type MutationMarkQuoteAsLostArgs = {
  input: MarkQuoteAsLostMutationInput;
};


export type MutationMarkQuoteAsRequestedExceptionArgs = {
  input: MarkQuoteAsRequestedExceptionMutationInput;
};


export type MutationTransitionQuoteWithExceptionArgs = {
  input: TransitionQuoteWithExceptionMutationInput;
};


export type MutationCreateQuoteSuccessChanceArgs = {
  input: CreateQuoteSuccessChanceMutationInput;
};


export type MutationUpdateQuoteSuccessChanceArgs = {
  input: UpdateQuoteSuccessChanceMutationInput;
};


export type MutationDeleteQuoteSuccessChanceArgs = {
  input: DeleteQuoteSuccessChanceMutationInput;
};


export type MutationCreateBillingTypeArgs = {
  input: CreateBillingTypeMutationInput;
};


export type MutationUpdateBillingTypeArgs = {
  input: UpdateBillingTypeMutationInput;
};


export type MutationDeleteBillingTypeArgs = {
  input: DeleteBillingTypeMutationInput;
};


export type MutationUpdateQuotePricesArgs = {
  input: UpdateQuotePricesMutationInput;
};


export type MutationCreateLostReasonArgs = {
  input: CreateLostReasonMutationInput;
};


export type MutationUpdateLostReasonArgs = {
  input: UpdateLostReasonMutationInput;
};


export type MutationDeleteLostReasonArgs = {
  input: DeleteLostReasonMutationInput;
};


export type MutationCreateQuoteExportArgs = {
  options?: Maybe<QuoteListOptionsInputType>;
};


export type MutationCreateProductArgs = {
  input: CreateProductMutationInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductMutationInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductMutationInput;
};


export type MutationCreateProductCategoryArgs = {
  input: CreateProductCategoryMutationInput;
};


export type MutationUpdateProductCategoryArgs = {
  input: UpdateProductCategoryMutationInput;
};


export type MutationDeleteProductCategoryArgs = {
  input: DeleteProductCategoryMutationInput;
};


export type MutationCreateProductSuppliesCompanyArgs = {
  input: CreateProductSuppliesCompanyMutationInput;
};


export type MutationUpdateProductSuppliesCompanyArgs = {
  input: UpdateProductSuppliesCompanyMutationInput;
};


export type MutationDeleteProductSuppliesCompanyArgs = {
  input: DeleteProductSuppliesCompanyMutationInput;
};


export type MutationCreateUnitArgs = {
  input: CreateUnitMutationInput;
};


export type MutationUpdateUnitArgs = {
  input: UpdateUnitMutationInput;
};


export type MutationDeleteUnitArgs = {
  input: DeleteUnitMutationInput;
};


export type MutationCreateProductVariationArgs = {
  input: CreateProductVariationMutationInput;
};


export type MutationUpdateProductVariationArgs = {
  input: UpdateProductVariationMutationInput;
};


export type MutationDeleteProductVariationArgs = {
  input: DeleteProductVariationMutationInput;
};


export type MutationCreateProductPriceModifierArgs = {
  input: CreateProductPriceModifierMutationInput;
};


export type MutationUpdateProductPriceModifierArgs = {
  input: UpdateProductPriceModifierMutationInput;
};


export type MutationDeleteProductPriceModifierArgs = {
  input: DeleteProductPriceModifierMutationInput;
};


export type MutationImportProductsArgs = {
  input: ImportProductsMutationInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodMutationInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodMutationInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodMutationInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactMutationInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactMutationInput;
};


export type MutationDeleteContactArgs = {
  input: DeleteContactMutationInput;
};


export type MutationActiveTogglerContactArgs = {
  input: ActiveTogglerContactMutationInput;
};


export type MutationCreateContactTypeArgs = {
  input: CreateContactTypeMutationInput;
};


export type MutationUpdateContactTypeArgs = {
  input: UpdateContactTypeMutationInput;
};


export type MutationDeleteContactTypeArgs = {
  input: DeleteContactTypeMutationInput;
};


export type MutationImportContactsArgs = {
  input: ImportContactsMutationInput;
};


export type MutationCreateActivityArgs = {
  input: CreateActivityMutationInput;
};


export type MutationUpdateActivityArgs = {
  input: UpdateActivityMutationInput;
};


export type MutationDeleteActivityArgs = {
  input: DeleteActivityMutationInput;
};


export type MutationCreateActivityChannelArgs = {
  input: CreateActivityChannelMutationInput;
};


export type MutationUpdateActivityChannelArgs = {
  input: UpdateActivityChannelMutationInput;
};


export type MutationDeleteActivityChannelArgs = {
  input: DeleteActivityChannelMutationInput;
};


export type MutationCreateActivityReasonArgs = {
  input: CreateActivityReasonMutationInput;
};


export type MutationUpdateActivityReasonArgs = {
  input: UpdateActivityReasonMutationInput;
};


export type MutationDeleteActivityReasonArgs = {
  input: DeleteActivityReasonMutationInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountMutationInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountMutationInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountMutationInput;
};


export type MutationActiveTogglerAccountArgs = {
  input: ActiveTogglerAccountMutationInput;
};


export type MutationCreateAccountTypeArgs = {
  input: CreateAccountTypeMutationInput;
};


export type MutationUpdateAccountTypeArgs = {
  input: UpdateAccountTypeMutationInput;
};


export type MutationDeleteAccountTypeArgs = {
  input: DeleteAccountTypeMutationInput;
};


export type MutationCreateAccountRegionArgs = {
  input: CreateAccountRegionMutationInput;
};


export type MutationUpdateAccountRegionArgs = {
  input: UpdateAccountRegionMutationInput;
};


export type MutationDeleteAccountRegionArgs = {
  input: DeleteAccountRegionMutationInput;
};


export type MutationImportAccountsArgs = {
  input: ImportAccountsMutationInput;
};

export type NotificationListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  isReadBool?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isArchivedBool?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tenant?: Maybe<Array<Maybe<Scalars['ID']>>>;
  user?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type NotificationListType = {
  __typename?: 'NotificationListType';
  items: Array<NotificationType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
  notReadCount: Scalars['Int'];
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  tenant: TenantType;
  user: UserType;
  isRead: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  path: Scalars['String'];
  content: Scalars['String'];
  icon?: Maybe<NotificationsNotificationIconChoices>;
  actionLabel?: Maybe<Scalars['String']>;
  actionPath?: Maybe<Scalars['String']>;
};

export enum NotificationsNotificationIconChoices {
  Default = 'DEFAULT',
  Quote = 'QUOTE',
  Sale = 'SALE',
  News = 'NEWS',
  PortalSappio = 'PORTAL_SAPPIO'
}

export type OrganizationListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type OrganizationListType = {
  __typename?: 'OrganizationListType';
  items: Array<OrganizationType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  tenant: TenantType;
  usernodeSet: Array<UserNodeType>;
};

export type PaymentMethodListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type PaymentMethodListType = {
  __typename?: 'PaymentMethodListType';
  items: Array<PaymentMethodType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  minMultiplier: Scalars['Float'];
  maxMultiplier: Scalars['Float'];
  allowsFinancing: Scalars['Boolean'];
  defaultFinancingMonths?: Maybe<Scalars['Int']>;
  defaultInterestRate: Scalars['Float'];
  order: Scalars['Int'];
  isAnnualInterestRate: Scalars['Boolean'];
  published: Scalars['Boolean'];
  tenant: TenantType;
  originalPaymentMethod: Array<QuotePaymentMethodType>;
};

export type PdfConfigInput = {
  categoryKey: TenantsPdfConfigCategoryKeyChoices;
  type: TenantsPdfConfigTypeChoices;
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type PdfConfigMutation = {
  __typename?: 'PdfConfigMutation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type PdfConfigType = {
  __typename?: 'PdfConfigType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  tenant: TenantType;
  key: Scalars['String'];
  categoryKey: TenantsPdfConfigCategoryKeyChoices;
  value: Scalars['Boolean'];
  type: TenantsPdfConfigTypeChoices;
};

export type PermissionListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type PermissionListType = {
  __typename?: 'PermissionListType';
  items: Array<PermissionType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type PermissionType = {
  __typename?: 'PermissionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  codename: Scalars['String'];
  userSet: Array<UserType>;
  roleSet: Array<RoleType>;
  displayName: Scalars['String'];
};

export type ProductCategoryListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  published?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProductCategoryListType = {
  __typename?: 'ProductCategoryListType';
  items: Array<ProductCategoryType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ProductCategoryType = {
  __typename?: 'ProductCategoryType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  published: Scalars['Boolean'];
  order: Scalars['Int'];
  tenant: TenantType;
  productSuppliesCompanies: Array<ProductSuppliesCompanyType>;
  productvariationSet: Array<ProductVariationType>;
  productpricemodifierSet: Array<ProductPriceModifierType>;
};

export type ProductListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  suppliesCompany?: Maybe<Array<Maybe<Scalars['ID']>>>;
  suppliesCompanyCategory?: Maybe<Array<Maybe<Scalars['ID']>>>;
  suppliesCompanyName?: Maybe<Array<Maybe<Scalars['ID']>>>;
  published?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProductListType = {
  __typename?: 'ProductListType';
  items: Array<ProductType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ProductPriceModifierListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  category?: Maybe<Array<Maybe<Scalars['ID']>>>;
  published?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProductPriceModifierListType = {
  __typename?: 'ProductPriceModifierListType';
  items: Array<ProductPriceModifierType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ProductPriceModifierType = {
  __typename?: 'ProductPriceModifierType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  minDiscount: Scalars['Float'];
  maxDiscount: Scalars['Float'];
  maxWorkflowDiscount?: Maybe<Scalars['Float']>;
  discountHasWorkflowRange: Scalars['Boolean'];
  minSurcharge: Scalars['Float'];
  maxSurcharge: Scalars['Float'];
  maxWorkflowSurcharge?: Maybe<Scalars['Float']>;
  surchargeHasWorkflowRange: Scalars['Boolean'];
  expirationDate: Scalars['Date'];
  order: Scalars['Int'];
  categories: Array<ProductCategoryType>;
  published: Scalars['Boolean'];
  tenant: TenantType;
};

export type ProductRankingDatumType = {
  __typename?: 'ProductRankingDatumType';
  product: QuoteProductType;
  count: Scalars['Int'];
  amount: Scalars['Int'];
  ranking: Scalars['Int'];
};

export type ProductSuppliesCompanyListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  category?: Maybe<Array<Maybe<Scalars['ID']>>>;
  published?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProductSuppliesCompanyListType = {
  __typename?: 'ProductSuppliesCompanyListType';
  items: Array<ProductSuppliesCompanyType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ProductSuppliesCompanyType = {
  __typename?: 'ProductSuppliesCompanyType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  category: ProductCategoryType;
  published: Scalars['Boolean'];
  order: Scalars['Int'];
  tenant: TenantType;
  productSet: Array<ProductType>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Float'];
  unit: UnitType;
  published: Scalars['Boolean'];
  tenant: TenantType;
  suppliesCompany: ProductSuppliesCompanyType;
  externalId?: Maybe<Scalars['String']>;
  priceUpdated: Scalars['DateTime'];
  originalProduct: Array<QuoteProductType>;
};

export type ProductVariationListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  published?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProductVariationListType = {
  __typename?: 'ProductVariationListType';
  items: Array<ProductVariationType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type ProductVariationType = {
  __typename?: 'ProductVariationType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  multiplier: Scalars['Float'];
  categories: Array<ProductCategoryType>;
  published: Scalars['Boolean'];
  order: Scalars['Int'];
  tenant: TenantType;
};

export type Query = {
  __typename?: 'Query';
  notifications: NotificationListType;
  runtimeSettings: RuntimeSettingsType;
  featureTutorial?: Maybe<FeatureTutorialType>;
  job?: Maybe<JobType>;
  exportFileJob?: Maybe<ExportJobType>;
  workflow: WorkflowType;
  workflowStates: Array<Maybe<Scalars['String']>>;
  activeTenant?: Maybe<TenantType>;
  pdfConfigs?: Maybe<Array<Maybe<PdfConfigType>>>;
  authUser?: Maybe<UserType>;
  tree: TreeType;
  userNodes: Array<UserNodeType>;
  user: UserType;
  users: UserListType;
  /** @deprecated Deprecated SAP-614 */
  childUsers: UserListType;
  organization: OrganizationType;
  organizations: OrganizationListType;
  childOrganizations: OrganizationListType;
  role: RoleType;
  roles: RoleListType;
  customPermissions: PermissionListType;
  currentSeason?: Maybe<SeasonType>;
  season: SeasonType;
  seasons: SeasonListType;
  salesTarget: SalesTargetType;
  salesTargetsByUser: Array<Maybe<SalesTargetType>>;
  salesTargets: SalesTargetListType;
  currentUsersSalesTargets?: Maybe<CurrentUsersSalesTargetsListType>;
  aggregatedSalesTargets: Array<Maybe<AggregatedSalesTargetType>>;
  sale: SaleType;
  sales: SaleListType;
  quote: QuoteType;
  quotes: QuoteListType;
  quoteSuccessChance: QuoteSuccessChanceType;
  quoteSuccessChances: QuoteSuccessChanceListType;
  availableTransitions: Array<Maybe<Scalars['String']>>;
  billingType: BillingTypeType;
  billingTypes: BillingTypeListType;
  lostReason: LostReasonType;
  lostReasons: LostReasonListType;
  updatedQuoteInfo?: Maybe<UpdatedQuoteInfoType>;
  quotePdfHtml: Scalars['String'];
  product: ProductType;
  products: ProductListType;
  productCategory: ProductCategoryType;
  productCategories: ProductCategoryListType;
  productSuppliesCompany: ProductSuppliesCompanyType;
  productSuppliesCompanies: ProductSuppliesCompanyListType;
  productSuppliesCompaniesByExactName: Array<ProductSuppliesCompanyType>;
  unit: UnitType;
  units: UnitListType;
  productVariation: ProductVariationType;
  productVariations: ProductVariationListType;
  productPriceModifier: ProductPriceModifierType;
  productPriceModifiers: ProductPriceModifierListType;
  defaultProductCategories: Array<DefaultProductCategoryType>;
  defaultProductSuppliesCompany: Array<DefaultProductSuppliesCompanyType>;
  paymentMethod: PaymentMethodType;
  paymentMethods: PaymentMethodListType;
  contact: ContactType;
  contacts: ContactListType;
  contactType: ContactTypeType;
  contactTypes: ContactTypeListType;
  searchContact: ContactThoughAccountType;
  searchContactAccount: ContactThoughAccountType;
  searchContacts: Array<ContactThoughAccountType>;
  activitiesAnalytics?: Maybe<ActivitiesAnalyticsType>;
  quotesAnalytics?: Maybe<QuotesAnalyticsType>;
  salesAmountAnalytics?: Maybe<SalesAmountAnalyticsType>;
  salesByTypeAnalytics?: Maybe<SalesByTypeAnalyticsType>;
  activity: ActivityType;
  activities: ActivityListType;
  activityChannel: ActivityChannelType;
  activityChannels: ActivityChannelListType;
  activityReason: ActivityReasonType;
  activityReasons: ActivityReasonListType;
  account: AccountType;
  accounts: AccountListType;
  accountType: AccountTypeType;
  accountTypes: AccountTypeListType;
  accountRegion: AccountRegionType;
  accountRegions: AccountRegionListType;
};


export type QueryNotificationsArgs = {
  options?: Maybe<NotificationListOptionsInputType>;
};


export type QueryFeatureTutorialArgs = {
  codename: Scalars['String'];
};


export type QueryJobArgs = {
  id: Scalars['String'];
};


export type QueryExportFileJobArgs = {
  id: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['UUID'];
};


export type QueryUsersArgs = {
  options?: Maybe<UserListOptionsInputType>;
  includeStaff?: Maybe<Scalars['Boolean']>;
};


export type QueryChildUsersArgs = {
  options?: Maybe<UserListOptionsInputType>;
};


export type QueryOrganizationArgs = {
  id: Scalars['UUID'];
};


export type QueryOrganizationsArgs = {
  options?: Maybe<OrganizationListOptionsInputType>;
};


export type QueryChildOrganizationsArgs = {
  options?: Maybe<OrganizationListOptionsInputType>;
};


export type QueryRoleArgs = {
  id: Scalars['UUID'];
};


export type QueryRolesArgs = {
  options?: Maybe<RoleListOptionsInputType>;
};


export type QueryCustomPermissionsArgs = {
  options?: Maybe<PermissionListOptionsInputType>;
};


export type QuerySeasonArgs = {
  id: Scalars['UUID'];
};


export type QuerySeasonsArgs = {
  options?: Maybe<SeasonListOptionsInputType>;
};


export type QuerySalesTargetArgs = {
  id: Scalars['UUID'];
};


export type QuerySalesTargetsByUserArgs = {
  id: Scalars['UUID'];
};


export type QuerySalesTargetsArgs = {
  options?: Maybe<SalesTargetListOptionsInputType>;
};


export type QueryCurrentUsersSalesTargetsArgs = {
  options?: Maybe<CurrentUsersSalesTargetsListOptionsInputType>;
};


export type QuerySaleArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QuerySalesArgs = {
  options?: Maybe<SaleListOptionsInputType>;
};


export type QueryQuoteArgs = {
  id: Scalars['UUID'];
};


export type QueryQuotesArgs = {
  options?: Maybe<QuoteListOptionsInputType>;
};


export type QueryQuoteSuccessChanceArgs = {
  id: Scalars['UUID'];
};


export type QueryQuoteSuccessChancesArgs = {
  options?: Maybe<QuoteSuccessChanceListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryAvailableTransitionsArgs = {
  id: Scalars['UUID'];
};


export type QueryBillingTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryBillingTypesArgs = {
  options?: Maybe<BillingTypeListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryLostReasonArgs = {
  id: Scalars['UUID'];
};


export type QueryLostReasonsArgs = {
  options?: Maybe<LostReasonListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryUpdatedQuoteInfoArgs = {
  id: Scalars['UUID'];
};


export type QueryQuotePdfHtmlArgs = {
  pdfType: Scalars['String'];
  configs: Array<Maybe<PdfConfigInput>>;
};


export type QueryProductArgs = {
  id: Scalars['UUID'];
};


export type QueryProductsArgs = {
  options?: Maybe<ProductListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryProductCategoryArgs = {
  id: Scalars['UUID'];
};


export type QueryProductCategoriesArgs = {
  options?: Maybe<ProductCategoryListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryProductSuppliesCompanyArgs = {
  id: Scalars['UUID'];
};


export type QueryProductSuppliesCompaniesArgs = {
  options?: Maybe<ProductSuppliesCompanyListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryProductSuppliesCompaniesByExactNameArgs = {
  name: Scalars['String'];
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryUnitArgs = {
  id: Scalars['UUID'];
};


export type QueryUnitsArgs = {
  options?: Maybe<UnitListOptionsInputType>;
};


export type QueryProductVariationArgs = {
  id: Scalars['UUID'];
};


export type QueryProductVariationsArgs = {
  options?: Maybe<ProductVariationListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryProductPriceModifierArgs = {
  id: Scalars['UUID'];
};


export type QueryProductPriceModifiersArgs = {
  options?: Maybe<ProductPriceModifierListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryPaymentMethodArgs = {
  id: Scalars['UUID'];
};


export type QueryPaymentMethodsArgs = {
  options?: Maybe<PaymentMethodListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryContactArgs = {
  id: Scalars['UUID'];
};


export type QueryContactsArgs = {
  options?: Maybe<ContactListOptionsInputType>;
  filterByUser?: Maybe<Scalars['UUID']>;
};


export type QueryContactTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryContactTypesArgs = {
  options?: Maybe<ContactTypeListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QuerySearchContactArgs = {
  id: Scalars['ID'];
};


export type QuerySearchContactAccountArgs = {
  accountId: Scalars['ID'];
  contactId?: Maybe<Scalars['ID']>;
};


export type QuerySearchContactsArgs = {
  q?: Maybe<Scalars['String']>;
};


export type QueryActivitiesAnalyticsArgs = {
  filters: ActivitiesAnalyticsFiltersInput;
};


export type QueryQuotesAnalyticsArgs = {
  filters: QuotesAnalyticsFiltersInput;
};


export type QuerySalesAmountAnalyticsArgs = {
  filters: SalesAnalyticsFiltersInput;
};


export type QuerySalesByTypeAnalyticsArgs = {
  filters: SalesAnalyticsFiltersInput;
};


export type QueryActivityArgs = {
  id: Scalars['UUID'];
};


export type QueryActivitiesArgs = {
  options?: Maybe<ActivityListOptionsInputType>;
};


export type QueryActivityChannelArgs = {
  id: Scalars['UUID'];
};


export type QueryActivityChannelsArgs = {
  options?: Maybe<ActivityChannelListOptionsInputType>;
};


export type QueryActivityReasonArgs = {
  id: Scalars['UUID'];
};


export type QueryActivityReasonsArgs = {
  options?: Maybe<ActivityReasonListOptionsInputType>;
};


export type QueryAccountArgs = {
  id: Scalars['UUID'];
};


export type QueryAccountsArgs = {
  options?: Maybe<AccountListOptionsInputType>;
  filterByUser?: Maybe<Scalars['UUID']>;
};


export type QueryAccountTypeArgs = {
  id: Scalars['UUID'];
};


export type QueryAccountTypesArgs = {
  options?: Maybe<AccountTypeListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryAccountRegionArgs = {
  id: Scalars['UUID'];
};


export type QueryAccountRegionsArgs = {
  options?: Maybe<AccountRegionListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};

export type QuoteCountDatumType = {
  __typename?: 'QuoteCountDatumType';
  label: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Float'];
  amount: Scalars['Float'];
};

export type QuoteDateSeriesDatumType = {
  __typename?: 'QuoteDateSeriesDatumType';
  date: Scalars['Date'];
  count: Scalars['Int'];
};

export type QuoteListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  billingType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contact?: Maybe<Array<Maybe<Scalars['ID']>>>;
  account?: Maybe<Array<Maybe<Scalars['ID']>>>;
  accountOwner?: Maybe<Array<Maybe<Scalars['ID']>>>;
  user?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<Array<Maybe<Scalars['ID']>>>;
  successChance?: Maybe<Array<Maybe<Scalars['ID']>>>;
  conditionsOutdated?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
  season?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productsNameUnaccentIcontains?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productsSupplyCompanyUnaccentIcontains?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productsCategoryUnaccentIcontains?: Maybe<Array<Maybe<Scalars['ID']>>>;
  paymentMethodsOriginalPaymentMethod?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QuoteListType = {
  __typename?: 'QuoteListType';
  items: Array<QuoteType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuotePdfRecipientFormInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type QuotePdfRecipientType = {
  __typename?: 'QuotePDFRecipientType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  quotePdf: QuotePdfType;
  email: Scalars['String'];
  statusDisplay: Scalars['String'];
};

export type QuotePdfType = {
  __typename?: 'QuotePDFType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  file?: Maybe<Scalars['String']>;
  type: QuotesQuotePdfTypeChoices;
  quote: QuoteType;
  slug: Scalars['String'];
  mode: QuotesQuotePdfModeChoices;
  quoteStatus?: Maybe<Scalars['String']>;
  recipients: Array<QuotePdfRecipientType>;
  typeDisplay: Scalars['String'];
  modeDisplay: Scalars['String'];
};

export type QuotePaymentMethodFormInput = {
  name: Scalars['String'];
  minMultiplier: Scalars['Float'];
  maxMultiplier: Scalars['Float'];
  allowsFinancing?: Maybe<Scalars['Boolean']>;
  defaultFinancingMonths?: Maybe<Scalars['Int']>;
  defaultInterestRate: Scalars['Float'];
  order: Scalars['Int'];
  isAnnualInterestRate?: Maybe<Scalars['Boolean']>;
  financingMonths: Scalars['Int'];
  multiplier: Scalars['Float'];
  interestRate: Scalars['Float'];
  originalPaymentMethod?: Maybe<Scalars['ID']>;
  total: Scalars['Float'];
  financingMultiplier: Scalars['Float'];
  uiTrackId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['ID']>;
};

export type QuotePaymentMethodType = {
  __typename?: 'QuotePaymentMethodType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  minMultiplier: Scalars['Float'];
  maxMultiplier: Scalars['Float'];
  allowsFinancing: Scalars['Boolean'];
  defaultFinancingMonths?: Maybe<Scalars['Int']>;
  defaultInterestRate: Scalars['Float'];
  order: Scalars['Int'];
  isAnnualInterestRate: Scalars['Boolean'];
  financingMonths: Scalars['Int'];
  multiplier: Scalars['Float'];
  interestRate: Scalars['Float'];
  quote: QuoteType;
  originalPaymentMethod?: Maybe<PaymentMethodType>;
  total: Scalars['Float'];
  financingMultiplier: Scalars['Float'];
  uiTrackId?: Maybe<Scalars['UUID']>;
  product: Array<QuoteProductType>;
  salePaymentMethod?: Maybe<SalePaymentMethodType>;
};

export type QuoteProductFormInput = {
  name: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  variation?: Maybe<Scalars['ID']>;
  variationMultiplier: Scalars['Float'];
  quantity: Scalars['Float'];
  originalProduct?: Maybe<Scalars['ID']>;
  supplyCompany: Scalars['String'];
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  totalMultiplier: Scalars['Float'];
  unitName: Scalars['String'];
  unitSymbol: Scalars['String'];
  paymentMethod?: Maybe<Scalars['ID']>;
  priceModifiers: Array<QuoteProductPriceModifierFormInput>;
  uiTrackId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['ID']>;
};

export type QuoteProductPriceModifierFormInput = {
  name: Scalars['String'];
  minDiscount: Scalars['Float'];
  maxDiscount: Scalars['Float'];
  maxWorkflowDiscount?: Maybe<Scalars['Float']>;
  discountHasWorkflowRange?: Maybe<Scalars['Boolean']>;
  minSurcharge: Scalars['Float'];
  maxSurcharge: Scalars['Float'];
  maxWorkflowSurcharge?: Maybe<Scalars['Float']>;
  surchargeHasWorkflowRange?: Maybe<Scalars['Boolean']>;
  expirationDate: Scalars['Date'];
  order: Scalars['Int'];
  multiplier: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
};

export type QuoteProductPriceModifierType = {
  __typename?: 'QuoteProductPriceModifierType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  minDiscount: Scalars['Float'];
  maxDiscount: Scalars['Float'];
  maxWorkflowDiscount?: Maybe<Scalars['Float']>;
  discountHasWorkflowRange: Scalars['Boolean'];
  minSurcharge: Scalars['Float'];
  maxSurcharge: Scalars['Float'];
  maxWorkflowSurcharge?: Maybe<Scalars['Float']>;
  surchargeHasWorkflowRange: Scalars['Boolean'];
  expirationDate: Scalars['Date'];
  order: Scalars['Int'];
  multiplier: Scalars['Float'];
  product: QuoteProductType;
};

export type QuoteProductType = {
  __typename?: 'QuoteProductType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  externalId: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Float'];
  variation?: Maybe<ProductVariationType>;
  variationMultiplier: Scalars['Float'];
  quantity: Scalars['Float'];
  quote: QuoteType;
  originalProduct?: Maybe<ProductType>;
  supplyCompany: Scalars['String'];
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  totalMultiplier: Scalars['Float'];
  unitName: Scalars['String'];
  unitSymbol: Scalars['String'];
  paymentMethod?: Maybe<QuotePaymentMethodType>;
  priceModifiers: Array<QuoteProductPriceModifierType>;
};

export type QuoteSuccessChanceListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type QuoteSuccessChanceListType = {
  __typename?: 'QuoteSuccessChanceListType';
  items: Array<QuoteSuccessChanceType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type QuoteSuccessChanceType = {
  __typename?: 'QuoteSuccessChanceType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  published: Scalars['Boolean'];
  tenant: TenantType;
};

export type QuoteTotalType = {
  __typename?: 'QuoteTotalType';
  count: Scalars['Int'];
  amount: Scalars['Float'];
};

export type QuoteTransitionMutationInput = {
  trigger: Scalars['String'];
  status: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuoteTransitionMutationPayload = {
  __typename?: 'QuoteTransitionMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuoteTransitionType = {
  __typename?: 'QuoteTransitionType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  quote: QuoteType;
  trigger: Scalars['String'];
  sourceState: Scalars['String'];
  destState: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<QuotesQuoteTransitionStatusChoices>;
  statusDisplay: Scalars['String'];
};

export type QuoteType = {
  __typename?: 'QuoteType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  status: Scalars['String'];
  reviewStart?: Maybe<Scalars['DateTime']>;
  successChance?: Maybe<QuoteSuccessChanceType>;
  billingType?: Maybe<BillingTypeType>;
  internalNotes: Scalars['String'];
  clientNotes: Scalars['String'];
  contact?: Maybe<ContactType>;
  account: AccountType;
  expirationDate: Scalars['Date'];
  displayId: Scalars['Int'];
  lostReason?: Maybe<LostReasonType>;
  subTotal: Scalars['Float'];
  transitions: Array<QuoteTransitionType>;
  products: Array<QuoteProductType>;
  paymentMethods: Array<QuotePaymentMethodType>;
  pdfs: Array<QuotePdfType>;
  sale?: Maybe<SaleType>;
  statusDisplay: Scalars['String'];
  canConvertToSale: Scalars['Boolean'];
  canReviewStart: Scalars['Boolean'];
  conditionsOutdated: Scalars['Boolean'];
};

export type QuotesAnalyticsFiltersInput = {
  season?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
};

export type QuotesAnalyticsType = {
  __typename?: 'QuotesAnalyticsType';
  season: SeasonType;
  total: QuoteTotalType;
  byStatus: Array<QuoteCountDatumType>;
  bySuccessChance: Array<QuoteCountDatumType>;
  byPaymentMethod: Array<QuoteCountDatumType>;
  byAccountType: Array<QuoteCountDatumType>;
  byDate: Array<QuoteDateSeriesDatumType>;
  byLostReason: Array<QuoteCountDatumType>;
};

export enum QuotesQuotePdfModeChoices {
  Whatsapp = 'WHATSAPP',
  Email = 'EMAIL',
  Download = 'DOWNLOAD'
}

export enum QuotesQuotePdfTypeChoices {
  Internal = 'INTERNAL',
  Client = 'CLIENT'
}

export enum QuotesQuoteTransitionStatusChoices {
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type ResetPasswordMutationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordMutationPayload = {
  __typename?: 'ResetPasswordMutationPayload';
  email: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RoleListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type RoleListType = {
  __typename?: 'RoleListType';
  items: Array<RoleType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type RoleType = {
  __typename?: 'RoleType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  permissions: Array<PermissionType>;
  tenant: TenantType;
  userSet: Array<UserType>;
};

export type RuntimeSettingsType = {
  __typename?: 'RuntimeSettingsType';
  id: Scalars['ID'];
};

export type SaleCountDatumType = {
  __typename?: 'SaleCountDatumType';
  label: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Float'];
  amount: Scalars['Float'];
};

export type SaleListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  quoteContact?: Maybe<Array<Maybe<Scalars['ID']>>>;
  quoteAccount?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdBy?: Maybe<Array<Maybe<Scalars['ID']>>>;
  season?: Maybe<Array<Maybe<Scalars['ID']>>>;
  annuledBool?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SaleListType = {
  __typename?: 'SaleListType';
  items: Array<SaleType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SalePdfRecipientFormInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type SalePdfRecipientType = {
  __typename?: 'SalePDFRecipientType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  salePdf: SalePdfType;
  email: Scalars['String'];
  statusDisplay: Scalars['String'];
};

export type SalePdfType = {
  __typename?: 'SalePDFType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  file?: Maybe<Scalars['String']>;
  sale: SaleType;
  slug: Scalars['String'];
  type: SalesSalePdfTypeChoices;
  recipients: Array<SalePdfRecipientType>;
};

export type SalePaymentMethodFormInput = {
  percentage: Scalars['Float'];
  paymentMethod: Scalars['ID'];
  total: Scalars['Float'];
  subTotal: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
};

export type SalePaymentMethodType = {
  __typename?: 'SalePaymentMethodType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  percentage: Scalars['Float'];
  sale: SaleType;
  paymentMethod: QuotePaymentMethodType;
  total: Scalars['Float'];
  subTotal: Scalars['Float'];
};

export type SaleType = {
  __typename?: 'SaleType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  quote: QuoteType;
  date: Scalars['Date'];
  displayId: Scalars['Int'];
  internalNotes: Scalars['String'];
  clientNotes: Scalars['String'];
  annuledReason?: Maybe<Scalars['String']>;
  annuled: Scalars['Boolean'];
  paymentMethods: Array<SalePaymentMethodType>;
  pdfs: Array<SalePdfType>;
  displayName: Scalars['String'];
};

export type SalesAmountAnalyticsType = {
  __typename?: 'SalesAmountAnalyticsType';
  season: SeasonType;
  salesTarget: Scalars['Int'];
  cumulativeSales: Array<CumulativeSalesDatumType>;
};

export type SalesAnalyticsFiltersInput = {
  season?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
};

export type SalesByTypeAnalyticsType = {
  __typename?: 'SalesByTypeAnalyticsType';
  cumulativeSales: Array<CumulativeSalesDatumType>;
  bySuccessChance: Array<SaleCountDatumType>;
  countByPaymentMethod: Array<SaleCountDatumType>;
  amountByPaymentMethod: Array<SaleCountDatumType>;
  byAccountType: Array<SaleCountDatumType>;
  bySuppliesCompany: Array<SaleCountDatumType>;
  productRanking: Array<ProductRankingDatumType>;
  byBillingType: Array<SaleCountDatumType>;
  byAccount: Array<DatumType>;
  byCategory: Array<DatumType>;
};

export enum SalesSalePdfTypeChoices {
  Internal = 'INTERNAL',
  Client = 'CLIENT'
}

export type SalesTargetFormInput = {
  season: Scalars['ID'];
  user: Scalars['ID'];
  amount: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
};

export type SalesTargetListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  season?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SalesTargetListType = {
  __typename?: 'SalesTargetListType';
  items: Array<SalesTargetType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SalesTargetType = {
  __typename?: 'SalesTargetType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  season: SeasonType;
  user: UserType;
  amount: Scalars['Float'];
  subtotal: Scalars['Float'];
};

export type SeasonListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type SeasonListType = {
  __typename?: 'SeasonListType';
  items: Array<SeasonType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type SeasonType = {
  __typename?: 'SeasonType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  tenant: TenantType;
  salesTargets: Array<SalesTargetType>;
};

export type SendResetPasswordEmailMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendResetPasswordEmailMutationPayload = {
  __typename?: 'SendResetPasswordEmailMutationPayload';
  user?: Maybe<UserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendUserActivationEmailMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendUserActivationEmailMutationPayload = {
  __typename?: 'SendUserActivationEmailMutationPayload';
  user?: Maybe<UserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetPasswordMutationInput = {
  user: Scalars['UUID'];
  token: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetPasswordMutationPayload = {
  __typename?: 'SetPasswordMutationPayload';
  user: Scalars['UUID'];
  token: Scalars['String'];
  password: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StartQuoteReviewMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StartQuoteReviewMutationPayload = {
  __typename?: 'StartQuoteReviewMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum SystemFeatureTutorialCodenameChoices {
  AccountsImport = 'ACCOUNTS_IMPORT',
  ContactsImport = 'CONTACTS_IMPORT',
  ProductsImport = 'PRODUCTS_IMPORT',
  SalesTargetsImport = 'SALES_TARGETS_IMPORT',
  UsersImport = 'USERS_IMPORT',
  AccountsList = 'ACCOUNTS_LIST',
  ContactsList = 'CONTACTS_LIST',
  SalesList = 'SALES_LIST',
  ActivitiesList = 'ACTIVITIES_LIST',
  StatisticsList = 'STATISTICS_LIST',
  QuotesList = 'QUOTES_LIST',
  NewQuote = 'NEW_QUOTE',
  NewSale = 'NEW_SALE',
  NewContact = 'NEW_CONTACT',
  NewAccount = 'NEW_ACCOUNT',
  NewActivity = 'NEW_ACTIVITY',
  NewProductAdmin = 'NEW_PRODUCT_ADMIN',
  NewCategoryAdmin = 'NEW_CATEGORY_ADMIN',
  NewSupplyCompanyAdmin = 'NEW_SUPPLY_COMPANY_ADMIN',
  NewUnitAdmin = 'NEW_UNIT_ADMIN',
  NewVariationAdmin = 'NEW_VARIATION_ADMIN',
  NewPriceModifierAdmin = 'NEW_PRICE_MODIFIER_ADMIN',
  NewPaymentMethodAdmin = 'NEW_PAYMENT_METHOD_ADMIN',
  NewBillingTypeAdmin = 'NEW_BILLING_TYPE_ADMIN',
  NewLostReasonAdmin = 'NEW_LOST_REASON_ADMIN',
  EditSalesLegalDisclaimerAdmin = 'EDIT_SALES_LEGAL_DISCLAIMER_ADMIN',
  EditQuoteLegalDisclaimerAdmin = 'EDIT_QUOTE_LEGAL_DISCLAIMER_ADMIN',
  EditQuoteExpiredDaysAdmin = 'EDIT_QUOTE_EXPIRED_DAYS_ADMIN',
  NewSeasonAdmin = 'NEW_SEASON_ADMIN',
  NewSaleTargetAdmin = 'NEW_SALE_TARGET_ADMIN',
  NewActivityChannelAdmin = 'NEW_ACTIVITY_CHANNEL_ADMIN',
  NewActivityReasonAdmin = 'NEW_ACTIVITY_REASON_ADMIN',
  NewAccountRegionAdmin = 'NEW_ACCOUNT_REGION_ADMIN',
  NewAccountTypeAdmin = 'NEW_ACCOUNT_TYPE_ADMIN',
  NewContactTypeAdmin = 'NEW_CONTACT_TYPE_ADMIN',
  NewUserAdmin = 'NEW_USER_ADMIN',
  NewRoleAdmin = 'NEW_ROLE_ADMIN',
  EditCurrencyAdmin = 'EDIT_CURRENCY_ADMIN',
  EditWorkflowAdmin = 'EDIT_WORKFLOW_ADMIN'
}

export enum SystemFeatureTutorialLanguageChoices {
  EnUs = 'EN_US',
  EsAr = 'ES_AR',
  PtBr = 'PT_BR'
}

export type TenantType = {
  __typename?: 'TenantType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  subdomain: Scalars['String'];
  logo: Scalars['String'];
  pdfLogo: Scalars['String'];
  quotePdfDisclaimer: Scalars['String'];
  salePdfDisclaimer: Scalars['String'];
  enabled: Scalars['Boolean'];
  workflowPermissions: Array<WorkflowPermissionType>;
  taxIdFormat: TenantsTenantTaxIdFormatChoices;
  quoteExpirationDays: Scalars['Int'];
  maxAllowedDaysConvertSale: Scalars['Int'];
  currencyLabel: TenantsTenantCurrencyLabelChoices;
  isCurrencyShort: Scalars['Boolean'];
  pdfConfigs: Array<PdfConfigType>;
  users: Array<UserType>;
  roles: Array<RoleType>;
  organizations: Array<OrganizationType>;
  accounts: Array<AccountType>;
  accountTypes: Array<AccountTypeType>;
  accountRegions: Array<AccountRegionType>;
  contacts: Array<ContactType>;
  contactTypes: Array<ContactTypeType>;
  activityChannels: Array<ActivityChannelType>;
  activityReasons: Array<ActivityReasonType>;
  paymentMethods: Array<PaymentMethodType>;
  products: Array<ProductType>;
  productSuppliesCompanies: Array<ProductSuppliesCompanyType>;
  productCategories: Array<ProductCategoryType>;
  units: Array<UnitType>;
  productVariations: Array<ProductVariationType>;
  productPriceModifiers: Array<ProductPriceModifierType>;
  quoteSuccessChances: Array<QuoteSuccessChanceType>;
  billingTypes: Array<BillingTypeType>;
  lostReasons: Array<LostReasonType>;
  seasons: Array<SeasonType>;
  workflow?: Maybe<WorkflowType>;
  currency: Scalars['String'];
};

export enum TenantsPdfConfigCategoryKeyChoices {
  SentBy = 'SENT_BY',
  PaymentMethods = 'PAYMENT_METHODS',
  Products = 'PRODUCTS',
  Notes = 'NOTES'
}

export enum TenantsPdfConfigTypeChoices {
  Internal = 'INTERNAL',
  Client = 'CLIENT'
}

export enum TenantsTenantCurrencyLabelChoices {
  Ars = 'ARS',
  Usd = 'USD',
  Cls = 'CLS',
  Brl = 'BRL',
  Uy = '_UY',
  Gu = '_GU'
}

export enum TenantsTenantTaxIdFormatChoices {
  ArCuit = 'AR_CUIT',
  ChRut = 'CH_RUT',
  UyRut = 'UY_RUT',
  BrCnpj = 'BR_CNPJ',
  UsaEin = 'USA_EIN',
  Other = 'OTHER'
}

export type ToggleEmailNotifications = {
  __typename?: 'ToggleEmailNotifications';
  ok?: Maybe<Scalars['Boolean']>;
};

export type TransitionQuoteWithExceptionMutationInput = {
  approved: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TransitionQuoteWithExceptionMutationPayload = {
  __typename?: 'TransitionQuoteWithExceptionMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TreeType = {
  __typename?: 'TreeType';
  parents: Array<UserType>;
  childs: Array<UserType>;
  equal: Array<UserType>;
};

export type TutorialStepType = {
  __typename?: 'TutorialStepType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  featureTutorial: FeatureTutorialType;
  content: Scalars['String'];
  order: Scalars['Int'];
};


export type UnitListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};

export type UnitListType = {
  __typename?: 'UnitListType';
  items: Array<UnitType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type UnitType = {
  __typename?: 'UnitType';
  id: Scalars['UUID'];
  created: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  updated: Scalars['DateTime'];
  updatedBy?: Maybe<UserType>;
  name: Scalars['String'];
  symbol: Scalars['String'];
  tenant: TenantType;
  productSet: Array<ProductType>;
};

export type UpdateAccountMutationInput = {
  name: Scalars['String'];
  legalName: Scalars['String'];
  taxId: Scalars['String'];
  taxIdFormat?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
  region?: Maybe<Scalars['ID']>;
  externalId?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountMutationPayload = {
  __typename?: 'UpdateAccountMutationPayload';
  account?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountRegionMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountRegionMutationPayload = {
  __typename?: 'UpdateAccountRegionMutationPayload';
  accountRegion?: Maybe<AccountRegionType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountTypeMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccountTypeMutationPayload = {
  __typename?: 'UpdateAccountTypeMutationPayload';
  accountType?: Maybe<AccountTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateActivityChannelMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateActivityChannelMutationPayload = {
  __typename?: 'UpdateActivityChannelMutationPayload';
  activityChannel?: Maybe<ActivityChannelType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateActivityMutationInput = {
  date: Scalars['Date'];
  channel: Scalars['String'];
  reason: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  contact: Scalars['ID'];
  account: Scalars['ID'];
  assignedTo: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateActivityMutationPayload = {
  __typename?: 'UpdateActivityMutationPayload';
  activity?: Maybe<ActivityType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateActivityReasonMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateActivityReasonMutationPayload = {
  __typename?: 'UpdateActivityReasonMutationPayload';
  activityReason?: Maybe<ActivityReasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBillingTypeMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBillingTypeMutationPayload = {
  __typename?: 'UpdateBillingTypeMutationPayload';
  billingType?: Maybe<BillingTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneCountryCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobileCountryCode?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
  accounts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  notes?: Maybe<Scalars['String']>;
  tenant: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationPayload = {
  __typename?: 'UpdateContactMutationPayload';
  contact?: Maybe<ContactType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactTypeMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateContactTypeMutationPayload = {
  __typename?: 'UpdateContactTypeMutationPayload';
  contactType?: Maybe<ContactTypeType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCurrencyMutationInput = {
  currencyLabel: Scalars['String'];
  isCurrencyShort?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCurrencyMutationPayload = {
  __typename?: 'UpdateCurrencyMutationPayload';
  tenant?: Maybe<TenantType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLostReasonMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLostReasonMutationPayload = {
  __typename?: 'UpdateLostReasonMutationPayload';
  lostReason?: Maybe<LostReasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationMutationInput = {
  name: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationMutationPayload = {
  __typename?: 'UpdateOrganizationMutationPayload';
  organization?: Maybe<OrganizationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePasswordMutationInput = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePasswordMutationPayload = {
  __typename?: 'UpdatePasswordMutationPayload';
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePaymentMethodMutationInput = {
  name: Scalars['String'];
  minMultiplier: Scalars['Float'];
  maxMultiplier: Scalars['Float'];
  allowsFinancing?: Maybe<Scalars['Boolean']>;
  defaultFinancingMonths?: Maybe<Scalars['Int']>;
  defaultInterestRate: Scalars['Float'];
  order: Scalars['Int'];
  isAnnualInterestRate?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePaymentMethodMutationPayload = {
  __typename?: 'UpdatePaymentMethodMutationPayload';
  paymentMethod?: Maybe<PaymentMethodType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductCategoryMutationInput = {
  name: Scalars['String'];
  published?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductCategoryMutationPayload = {
  __typename?: 'UpdateProductCategoryMutationPayload';
  productCategory?: Maybe<ProductCategoryType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductMutationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  unit: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  suppliesCompany: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  priceUpdated?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductMutationPayload = {
  __typename?: 'UpdateProductMutationPayload';
  product?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductPriceModifierMutationInput = {
  name: Scalars['String'];
  minDiscount: Scalars['Float'];
  maxDiscount: Scalars['Float'];
  maxWorkflowDiscount?: Maybe<Scalars['Float']>;
  discountHasWorkflowRange?: Maybe<Scalars['Boolean']>;
  minSurcharge: Scalars['Float'];
  maxSurcharge: Scalars['Float'];
  maxWorkflowSurcharge?: Maybe<Scalars['Float']>;
  surchargeHasWorkflowRange?: Maybe<Scalars['Boolean']>;
  expirationDate: Scalars['Date'];
  order: Scalars['Int'];
  categories: Array<Maybe<Scalars['ID']>>;
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductPriceModifierMutationPayload = {
  __typename?: 'UpdateProductPriceModifierMutationPayload';
  productPriceModifier?: Maybe<ProductPriceModifierType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductSuppliesCompanyMutationInput = {
  name: Scalars['String'];
  category: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductSuppliesCompanyMutationPayload = {
  __typename?: 'UpdateProductSuppliesCompanyMutationPayload';
  productSuppliesCompany?: Maybe<ProductSuppliesCompanyType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductVariationMutationInput = {
  name: Scalars['String'];
  multiplier: Scalars['Float'];
  categories: Array<Maybe<Scalars['ID']>>;
  published?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProductVariationMutationPayload = {
  __typename?: 'UpdateProductVariationMutationPayload';
  productVariation?: Maybe<ProductVariationType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteDisclaimerMutationInput = {
  quotePdfDisclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteDisclaimerMutationPayload = {
  __typename?: 'UpdateQuoteDisclaimerMutationPayload';
  tenant?: Maybe<TenantType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteExpirationDaysFormMutationInput = {
  quoteExpirationDays: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteExpirationDaysFormMutationPayload = {
  __typename?: 'UpdateQuoteExpirationDaysFormMutationPayload';
  tenant?: Maybe<TenantType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteMutationInput = {
  contact?: Maybe<Scalars['ID']>;
  account: Scalars['ID'];
  successChance?: Maybe<Scalars['ID']>;
  billingType?: Maybe<Scalars['ID']>;
  internalNotes?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  subTotal: Scalars['Float'];
  paymentMethods: Array<QuotePaymentMethodFormInput>;
  products: Array<QuoteProductFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteMutationPayload = {
  __typename?: 'UpdateQuoteMutationPayload';
  quote?: Maybe<QuoteType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuotePricesMutationInput = {
  quote: Scalars['UUID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuotePricesMutationPayload = {
  __typename?: 'UpdateQuotePricesMutationPayload';
  quote: Scalars['UUID'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteSuccessChanceMutationInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  published?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateQuoteSuccessChanceMutationPayload = {
  __typename?: 'UpdateQuoteSuccessChanceMutationPayload';
  quoteSuccessChance?: Maybe<QuoteSuccessChanceType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRoleMutationInput = {
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRoleMutationPayload = {
  __typename?: 'UpdateRoleMutationPayload';
  role?: Maybe<RoleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSaleDisclaimerMutationInput = {
  salePdfDisclaimer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSaleDisclaimerMutationPayload = {
  __typename?: 'UpdateSaleDisclaimerMutationPayload';
  tenant?: Maybe<TenantType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSaleMutationInput = {
  quote: Scalars['ID'];
  date: Scalars['Date'];
  internalNotes?: Maybe<Scalars['String']>;
  clientNotes?: Maybe<Scalars['String']>;
  annuledReason?: Maybe<Scalars['String']>;
  annuled?: Maybe<Scalars['Boolean']>;
  paymentMethods: Array<SalePaymentMethodFormInput>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSaleMutationPayload = {
  __typename?: 'UpdateSaleMutationPayload';
  sale?: Maybe<SaleType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSalesTargetMutationInput = {
  season: Scalars['ID'];
  user: Scalars['ID'];
  amount: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSalesTargetMutationPayload = {
  __typename?: 'UpdateSalesTargetMutationPayload';
  salesTarget?: Maybe<SalesTargetType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSeasonMutationInput = {
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSeasonMutationPayload = {
  __typename?: 'UpdateSeasonMutationPayload';
  season?: Maybe<SeasonType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUnitMutationInput = {
  name: Scalars['String'];
  symbol: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUnitMutationPayload = {
  __typename?: 'UpdateUnitMutationPayload';
  unit?: Maybe<UnitType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserMutationInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isTenantStaff?: Maybe<Scalars['Boolean']>;
  parentUser?: Maybe<Scalars['ID']>;
  teamNode?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserMutationPayload = {
  __typename?: 'UpdateUserMutationPayload';
  user?: Maybe<UserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileMutationInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileMutationPayload = {
  __typename?: 'UpdateUserProfileMutationPayload';
  user?: Maybe<UserType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWorkflowMutationInput = {
  schema: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWorkflowMutationPayload = {
  __typename?: 'UpdateWorkflowMutationPayload';
  workflow?: Maybe<WorkflowType>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatedProductDatum = {
  __typename?: 'UpdatedProductDatum';
  id: Scalars['UUID'];
  price: Scalars['Float'];
  outdatedQuoteProduct: QuoteProductType;
};

export type UpdatedQuoteInfoType = {
  __typename?: 'UpdatedQuoteInfoType';
  id: Scalars['UUID'];
  updatedProducts: Array<Maybe<UpdatedProductDatum>>;
};


export type UserListOptionsInputType = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  hasSalesTarget?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UserListType = {
  __typename?: 'UserListType';
  items: Array<UserType>;
  count: Scalars['Int'];
  page: Scalars['Int'];
};

export type UserNodeType = {
  __typename?: 'UserNodeType';
  id: Scalars['UUID'];
  parent?: Maybe<UserNodeType>;
  users: Array<UserType>;
  organizations: Array<OrganizationType>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children: Array<UserNodeType>;
  displayName: Scalars['String'];
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  assignAccountOwner: Scalars['Boolean'];
  requestQuoteReview: Scalars['Boolean'];
  convertQuoteToSale: Scalars['Boolean'];
  approveException: Scalars['Boolean'];
  viewAnalytics: Scalars['Boolean'];
  notificateQuoteLost: Scalars['Boolean'];
  notificateQuoteInSale: Scalars['Boolean'];
  notificateSaleAnnuled: Scalars['Boolean'];
  assignActivity: Scalars['Boolean'];
  createQuotes: Scalars['Boolean'];
  viewQuotes: Scalars['Boolean'];
  viewSales: Scalars['Boolean'];
  annulSales: Scalars['Boolean'];
  viewAccounts: Scalars['Boolean'];
  viewContacts: Scalars['Boolean'];
  viewActivities: Scalars['Boolean'];
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['UUID'];
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  isSuperuser: Scalars['Boolean'];
  userPermissions: Array<PermissionType>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isStaff: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  role?: Maybe<RoleType>;
  language?: Maybe<Scalars['String']>;
  tenant: TenantType;
  isTenantStaff: Scalars['Boolean'];
  emailNotifications: Scalars['Boolean'];
  phone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  notifications: Array<NotificationType>;
  usernodeSet: Array<UserNodeType>;
  accountSet: Array<AccountType>;
  activities: Array<ActivityType>;
  salesTargets: Array<SalesTargetType>;
  displayName: Scalars['String'];
  permissions: UserPermissions;
  parentsUsers: Array<UserType>;
  childUsers: Array<UserType>;
  childNodes: Array<UserNodeType>;
  organization?: Maybe<OrganizationType>;
  hasUsablePassword: Scalars['Boolean'];
  salesTargetsAmount?: Maybe<Scalars['Float']>;
  salesTargetsChildsAmount?: Maybe<Scalars['Float']>;
};


export type UserTypeChildUsersArgs = {
  includeSelf?: Maybe<Scalars['Boolean']>;
};

export type WorkflowPermissionType = {
  __typename?: 'WorkflowPermissionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  codename: Scalars['String'];
  tenants: Array<TenantType>;
};

export type WorkflowType = {
  __typename?: 'WorkflowType';
  id: Scalars['UUID'];
  name: Scalars['String'];
  schema: Scalars['JSONString'];
  tenant: TenantType;
  exceptionState?: Maybe<Scalars['String']>;
  initial?: Maybe<Scalars['String']>;
  lostState?: Maybe<Scalars['String']>;
  saleState?: Maybe<Scalars['String']>;
  approveState?: Maybe<Scalars['String']>;
  initialReview?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
  expiredState?: Maybe<Scalars['String']>;
  saleCanceled?: Maybe<Scalars['String']>;
};

export type AccountFragment = (
  { __typename?: 'AccountType', created: string, updated: string, contacts: Array<(
    { __typename?: 'ContactType' }
    & MinimalContactFragment
  )>, activities: Array<(
    { __typename?: 'ActivityType' }
    & MinimalActivityFragment
  )>, quotes: Array<(
    { __typename?: 'QuoteType' }
    & MinimalQuoteFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & MinimalAccountFragment
);

export type MinimalAccountFragment = { __typename?: 'AccountType', id: string, name: string, legalName: string, taxId?: Maybe<string>, taxIdFormat?: Maybe<AccountsAccountTaxIdFormatChoices>, externalId: string, notes: string, active: boolean, type?: Maybe<(
    { __typename?: 'AccountTypeType' }
    & AccountTypeFragment
  )>, region?: Maybe<(
    { __typename?: 'AccountRegionType' }
    & AccountRegionFragment
  )>, owner?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> };

export type AccountOptionFragment = { __typename?: 'AccountType', id: string, name: string, legalName: string, active: boolean };

export type AccountTypeFragment = { __typename?: 'AccountTypeType', id: string, name: string, published: boolean, order: number };

export type AccountRegionFragment = { __typename?: 'AccountRegionType', id: string, name: string, published: boolean, order: number };

export type GetAccountsQueryVariables = Exact<{
  options?: Maybe<AccountListOptionsInputType>;
}>;


export type GetAccountsQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountListType', count: number, items: Array<(
      { __typename?: 'AccountType' }
      & MinimalAccountFragment
    )> } };

export type GetAccountOptionsQueryVariables = Exact<{
  options?: Maybe<AccountListOptionsInputType>;
  filterByUser?: Maybe<Scalars['UUID']>;
}>;


export type GetAccountOptionsQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountListType', items: Array<(
      { __typename?: 'AccountType' }
      & AccountOptionFragment
    )> } };

export type GetAccountQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetAccountQuery = { __typename?: 'Query', account: (
    { __typename?: 'AccountType' }
    & AccountFragment
  ) };

export type GetAccountTypeQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetAccountTypeQuery = { __typename?: 'Query', accountType: (
    { __typename?: 'AccountTypeType' }
    & AccountTypeFragment
  ) };

export type GetAccountTypesQueryVariables = Exact<{
  options?: Maybe<AccountTypeListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetAccountTypesQuery = { __typename?: 'Query', accountTypes: { __typename?: 'AccountTypeListType', count: number, items: Array<(
      { __typename?: 'AccountTypeType' }
      & AccountTypeFragment
    )> } };

export type GetAccountRegionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetAccountRegionQuery = { __typename?: 'Query', accountRegion: (
    { __typename?: 'AccountRegionType' }
    & AccountRegionFragment
  ) };

export type GetAccountRegionsQueryVariables = Exact<{
  options?: Maybe<AccountRegionListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetAccountRegionsQuery = { __typename?: 'Query', accountRegions: { __typename?: 'AccountRegionListType', count: number, items: Array<(
      { __typename?: 'AccountRegionType' }
      & AccountRegionFragment
    )> } };

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountMutationInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount?: Maybe<{ __typename?: 'CreateAccountMutationPayload', account?: Maybe<(
      { __typename?: 'AccountType' }
      & AccountFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateAccountMutationVariables = Exact<{
  input: UpdateAccountMutationInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount?: Maybe<{ __typename?: 'UpdateAccountMutationPayload', account?: Maybe<(
      { __typename?: 'AccountType' }
      & AccountFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ActiveTogglerAccountMutationVariables = Exact<{
  input: ActiveTogglerAccountMutationInput;
}>;


export type ActiveTogglerAccountMutation = { __typename?: 'Mutation', activeTogglerAccount?: Maybe<{ __typename?: 'ActiveTogglerAccountMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteAccountMutationVariables = Exact<{
  input: DeleteAccountMutationInput;
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount?: Maybe<{ __typename?: 'DeleteAccountMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateAccountTypeMutationVariables = Exact<{
  input: CreateAccountTypeMutationInput;
}>;


export type CreateAccountTypeMutation = { __typename?: 'Mutation', createAccountType?: Maybe<{ __typename?: 'CreateAccountTypeMutationPayload', accountType?: Maybe<(
      { __typename?: 'AccountTypeType' }
      & AccountTypeFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateAccountTypeMutationVariables = Exact<{
  input: UpdateAccountTypeMutationInput;
}>;


export type UpdateAccountTypeMutation = { __typename?: 'Mutation', updateAccountType?: Maybe<{ __typename?: 'UpdateAccountTypeMutationPayload', accountType?: Maybe<(
      { __typename?: 'AccountTypeType' }
      & AccountTypeFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteAccountTypeMutationVariables = Exact<{
  input: DeleteAccountTypeMutationInput;
}>;


export type DeleteAccountTypeMutation = { __typename?: 'Mutation', deleteAccountType?: Maybe<{ __typename?: 'DeleteAccountTypeMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateAccountRegionMutationVariables = Exact<{
  input: CreateAccountRegionMutationInput;
}>;


export type CreateAccountRegionMutation = { __typename?: 'Mutation', createAccountRegion?: Maybe<{ __typename?: 'CreateAccountRegionMutationPayload', accountRegion?: Maybe<(
      { __typename?: 'AccountRegionType' }
      & AccountRegionFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateAccountRegionMutationVariables = Exact<{
  input: UpdateAccountRegionMutationInput;
}>;


export type UpdateAccountRegionMutation = { __typename?: 'Mutation', updateAccountRegion?: Maybe<{ __typename?: 'UpdateAccountRegionMutationPayload', accountRegion?: Maybe<(
      { __typename?: 'AccountRegionType' }
      & AccountRegionFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteAccountRegionMutationVariables = Exact<{
  input: DeleteAccountRegionMutationInput;
}>;


export type DeleteAccountRegionMutation = { __typename?: 'Mutation', deleteAccountRegion?: Maybe<{ __typename?: 'DeleteAccountRegionMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ImportAccountsMutationVariables = Exact<{
  input: ImportAccountsMutationInput;
}>;


export type ImportAccountsMutation = { __typename?: 'Mutation', importAccounts?: Maybe<{ __typename?: 'ImportAccountsMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ActivityFragment = (
  { __typename?: 'ActivityType', created: string, updated: string, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & MinimalActivityFragment
);

export type MinimalActivityFragment = { __typename?: 'ActivityType', id: string, date: string, channel: string, reason: string, notes: string, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, contact: { __typename?: 'ContactType', id: string, displayName: string, accounts: Array<{ __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string> }> }, account: { __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string> }, assignedTo?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> };

export type ActivityChannelFragment = { __typename?: 'ActivityChannelType', id: string, name: string, order: number };

export type ActivityReasonFragment = { __typename?: 'ActivityReasonType', id: string, name: string, order: number };

export type GetActivitiesQueryVariables = Exact<{
  options?: Maybe<ActivityListOptionsInputType>;
}>;


export type GetActivitiesQuery = { __typename?: 'Query', activities: { __typename?: 'ActivityListType', count: number, items: Array<(
      { __typename?: 'ActivityType' }
      & MinimalActivityFragment
    )> } };

export type GetActivityQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetActivityQuery = { __typename?: 'Query', activity: (
    { __typename?: 'ActivityType' }
    & ActivityFragment
  ) };

export type GetActivityChannelQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetActivityChannelQuery = { __typename?: 'Query', activityChannel: (
    { __typename?: 'ActivityChannelType' }
    & ActivityChannelFragment
  ) };

export type GetActivityChannelsQueryVariables = Exact<{
  options?: Maybe<ActivityChannelListOptionsInputType>;
}>;


export type GetActivityChannelsQuery = { __typename?: 'Query', activityChannels: { __typename?: 'ActivityChannelListType', count: number, items: Array<(
      { __typename?: 'ActivityChannelType' }
      & ActivityChannelFragment
    )> } };

export type GetActivityReasonQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetActivityReasonQuery = { __typename?: 'Query', activityReason: (
    { __typename?: 'ActivityReasonType' }
    & ActivityReasonFragment
  ) };

export type GetActivityReasonsQueryVariables = Exact<{
  options?: Maybe<ActivityReasonListOptionsInputType>;
}>;


export type GetActivityReasonsQuery = { __typename?: 'Query', activityReasons: { __typename?: 'ActivityReasonListType', count: number, items: Array<(
      { __typename?: 'ActivityReasonType' }
      & ActivityReasonFragment
    )> } };

export type CreateActivityReasonMutationVariables = Exact<{
  input: CreateActivityReasonMutationInput;
}>;


export type CreateActivityReasonMutation = { __typename?: 'Mutation', createActivityReason?: Maybe<{ __typename?: 'CreateActivityReasonMutationPayload', activityReason?: Maybe<(
      { __typename?: 'ActivityReasonType' }
      & ActivityReasonFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateActivityReasonMutationVariables = Exact<{
  input: UpdateActivityReasonMutationInput;
}>;


export type UpdateActivityReasonMutation = { __typename?: 'Mutation', updateActivityReason?: Maybe<{ __typename?: 'UpdateActivityReasonMutationPayload', activityReason?: Maybe<(
      { __typename?: 'ActivityReasonType' }
      & ActivityReasonFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteActivityReasonMutationVariables = Exact<{
  input: DeleteActivityReasonMutationInput;
}>;


export type DeleteActivityReasonMutation = { __typename?: 'Mutation', deleteActivityReason?: Maybe<{ __typename?: 'DeleteActivityReasonMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateActivityMutationVariables = Exact<{
  input: CreateActivityMutationInput;
}>;


export type CreateActivityMutation = { __typename?: 'Mutation', createActivity?: Maybe<{ __typename?: 'CreateActivityMutationPayload', activity?: Maybe<(
      { __typename?: 'ActivityType' }
      & ActivityFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateActivityMutationVariables = Exact<{
  input: UpdateActivityMutationInput;
}>;


export type UpdateActivityMutation = { __typename?: 'Mutation', updateActivity?: Maybe<{ __typename?: 'UpdateActivityMutationPayload', activity?: Maybe<(
      { __typename?: 'ActivityType' }
      & ActivityFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteActivityMutationVariables = Exact<{
  input: DeleteActivityMutationInput;
}>;


export type DeleteActivityMutation = { __typename?: 'Mutation', deleteActivity?: Maybe<{ __typename?: 'DeleteActivityMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateActivityChannelMutationVariables = Exact<{
  input: CreateActivityChannelMutationInput;
}>;


export type CreateActivityChannelMutation = { __typename?: 'Mutation', createActivityChannel?: Maybe<{ __typename?: 'CreateActivityChannelMutationPayload', activityChannel?: Maybe<(
      { __typename?: 'ActivityChannelType' }
      & ActivityChannelFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateActivityChannelMutationVariables = Exact<{
  input: UpdateActivityChannelMutationInput;
}>;


export type UpdateActivityChannelMutation = { __typename?: 'Mutation', updateActivityChannel?: Maybe<{ __typename?: 'UpdateActivityChannelMutationPayload', activityChannel?: Maybe<(
      { __typename?: 'ActivityChannelType' }
      & ActivityChannelFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteActivityChannelMutationVariables = Exact<{
  input: DeleteActivityChannelMutationInput;
}>;


export type DeleteActivityChannelMutation = { __typename?: 'Mutation', deleteActivityChannel?: Maybe<{ __typename?: 'DeleteActivityChannelMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ActivitiesAnalyticsFragment = { __typename?: 'ActivitiesAnalyticsType', season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ), total: { __typename?: 'ActivityTotalType', count: number }, byChannel: Array<{ __typename?: 'ActivityCountDatumType', label: string, count: number, percentage: number }>, byReason: Array<{ __typename?: 'ActivityCountDatumType', label: string, count: number, percentage: number }>, byDate: Array<{ __typename?: 'ActivityDateSeriesDatumType', date: string, count: number }> };

export type QuotesAnalyticsFragment = { __typename?: 'QuotesAnalyticsType', season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ), total: { __typename?: 'QuoteTotalType', count: number, amount: number }, byStatus: Array<{ __typename?: 'QuoteCountDatumType', label: string, count: number, percentage: number, amount: number }>, bySuccessChance: Array<{ __typename?: 'QuoteCountDatumType', label: string, count: number, percentage: number, amount: number }>, byPaymentMethod: Array<{ __typename?: 'QuoteCountDatumType', label: string, count: number, percentage: number, amount: number }>, byAccountType: Array<{ __typename?: 'QuoteCountDatumType', label: string, count: number, percentage: number, amount: number }>, byLostReason: Array<{ __typename?: 'QuoteCountDatumType', label: string, count: number, percentage: number, amount: number }>, byDate: Array<{ __typename?: 'QuoteDateSeriesDatumType', date: string, count: number }> };

export type SalesAmountAnalyticsFragment = { __typename?: 'SalesAmountAnalyticsType', salesTarget: number, cumulativeSales: Array<{ __typename?: 'CumulativeSalesDatumType', date: string, amount: number, forecastAmount: number }> };

export type SalesByTypeAnalyticsFragment = { __typename?: 'SalesByTypeAnalyticsType', bySuccessChance: Array<{ __typename?: 'SaleCountDatumType', label: string, count: number, percentage: number, amount: number }>, byAccountType: Array<{ __typename?: 'SaleCountDatumType', label: string, count: number, percentage: number, amount: number }>, amountByPaymentMethod: Array<{ __typename?: 'SaleCountDatumType', label: string, count: number, percentage: number, amount: number }>, bySuppliesCompany: Array<{ __typename?: 'SaleCountDatumType', label: string, count: number, percentage: number, amount: number }>, countByPaymentMethod: Array<{ __typename?: 'SaleCountDatumType', label: string, count: number, percentage: number, amount: number }>, byBillingType: Array<{ __typename?: 'SaleCountDatumType', label: string, count: number, percentage: number, amount: number }>, productRanking: Array<{ __typename?: 'ProductRankingDatumType', count: number, amount: number, ranking: number, product: { __typename?: 'QuoteProductType', name: string, supplyCompany: string } }>, byAccount: Array<{ __typename?: 'DatumType', label: string, count: number, amount: number }>, byCategory: Array<{ __typename?: 'DatumType', label: string, count: number, amount: number }> };

export type GetSalesAmountAnalyticsQueryVariables = Exact<{
  filters: SalesAnalyticsFiltersInput;
}>;


export type GetSalesAmountAnalyticsQuery = { __typename?: 'Query', salesAmountAnalytics?: Maybe<(
    { __typename?: 'SalesAmountAnalyticsType' }
    & SalesAmountAnalyticsFragment
  )> };

export type GetSalesByTypeAnalyticsQueryVariables = Exact<{
  filters: SalesAnalyticsFiltersInput;
}>;


export type GetSalesByTypeAnalyticsQuery = { __typename?: 'Query', salesByTypeAnalytics?: Maybe<(
    { __typename?: 'SalesByTypeAnalyticsType' }
    & SalesByTypeAnalyticsFragment
  )> };

export type GetActivitiesAnalyticsQueryVariables = Exact<{
  filters: ActivitiesAnalyticsFiltersInput;
}>;


export type GetActivitiesAnalyticsQuery = { __typename?: 'Query', activitiesAnalytics?: Maybe<(
    { __typename?: 'ActivitiesAnalyticsType' }
    & ActivitiesAnalyticsFragment
  )> };

export type GetQuotesAnalyticsQueryVariables = Exact<{
  filters: QuotesAnalyticsFiltersInput;
}>;


export type GetQuotesAnalyticsQuery = { __typename?: 'Query', quotesAnalytics?: Maybe<(
    { __typename?: 'QuotesAnalyticsType' }
    & QuotesAnalyticsFragment
  )> };

export type ContactFragment = (
  { __typename?: 'ContactType', created: string, updated: string, activities: Array<(
    { __typename?: 'ActivityType' }
    & MinimalActivityFragment
  )>, quotes: Array<(
    { __typename?: 'QuoteType' }
    & MinimalQuoteFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> }
  & MinimalContactFragment
);

export type MinimalContactFragment = { __typename?: 'ContactType', id: string, email: string, firstName: string, lastName: string, displayName: string, phoneCountryCode: string, phone: string, mobileCountryCode: string, mobilePhone: string, notes: string, active: boolean, type?: Maybe<(
    { __typename?: 'ContactTypeType' }
    & ContactTypeFragment
  )>, accounts: Array<{ __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string>, legalName: string, active: boolean, owner?: Maybe<(
      { __typename?: 'UserType' }
      & MinimalUserFragment
    )> }> };

export type ContactOptionFragment = { __typename?: 'ContactType', id: string, email: string, displayName: string, active: boolean, phoneCountryCode: string, phone: string, mobileCountryCode: string, mobilePhone: string };

export type ContactTypeFragment = { __typename?: 'ContactTypeType', id: string, name: string, published: boolean, order: number };

export type ContactThoughAccountFragment = { __typename?: 'ContactThoughAccountType', contact?: Maybe<(
    { __typename?: 'ContactType' }
    & ContactOptionFragment
  )>, account: (
    { __typename?: 'AccountType', taxId?: Maybe<string>, legalName: string, externalId: string }
    & AccountOptionFragment
  ) };

export type GetContactsQueryVariables = Exact<{
  options?: Maybe<ContactListOptionsInputType>;
  filterByUser?: Maybe<Scalars['UUID']>;
}>;


export type GetContactsQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactListType', count: number, items: Array<(
      { __typename?: 'ContactType' }
      & MinimalContactFragment
    )> } };

export type GetContactOptionsQueryVariables = Exact<{
  options?: Maybe<ContactListOptionsInputType>;
  filterByUser?: Maybe<Scalars['UUID']>;
}>;


export type GetContactOptionsQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactListType', items: Array<(
      { __typename?: 'ContactType' }
      & ContactOptionFragment
    )> } };

export type GetContactAccountsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetContactAccountsQuery = { __typename?: 'Query', contact: { __typename?: 'ContactType', accounts: Array<{ __typename?: 'AccountType', id: string }> } };

export type GetContactQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetContactQuery = { __typename?: 'Query', contact: (
    { __typename?: 'ContactType' }
    & ContactFragment
  ) };

export type GetContactTypeQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetContactTypeQuery = { __typename?: 'Query', contactType: (
    { __typename?: 'ContactTypeType' }
    & ContactTypeFragment
  ) };

export type GetContactTypesQueryVariables = Exact<{
  options?: Maybe<ContactTypeListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetContactTypesQuery = { __typename?: 'Query', contactTypes: { __typename?: 'ContactTypeListType', count: number, items: Array<(
      { __typename?: 'ContactTypeType' }
      & ContactTypeFragment
    )> } };

export type GetSearchContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSearchContactQuery = { __typename?: 'Query', searchContact: (
    { __typename?: 'ContactThoughAccountType' }
    & ContactThoughAccountFragment
  ) };

export type GetSearchContactAccountQueryVariables = Exact<{
  accountId: Scalars['ID'];
  contactId?: Maybe<Scalars['ID']>;
}>;


export type GetSearchContactAccountQuery = { __typename?: 'Query', searchContactAccount: (
    { __typename?: 'ContactThoughAccountType' }
    & ContactThoughAccountFragment
  ) };

export type GetSearchContactsQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type GetSearchContactsQuery = { __typename?: 'Query', searchContacts: Array<(
    { __typename?: 'ContactThoughAccountType' }
    & ContactThoughAccountFragment
  )> };

export type CreateContactTypeMutationVariables = Exact<{
  input: CreateContactTypeMutationInput;
}>;


export type CreateContactTypeMutation = { __typename?: 'Mutation', createContactType?: Maybe<{ __typename?: 'CreateContactTypeMutationPayload', contactType?: Maybe<(
      { __typename?: 'ContactTypeType' }
      & ContactTypeFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateContactTypeMutationVariables = Exact<{
  input: UpdateContactTypeMutationInput;
}>;


export type UpdateContactTypeMutation = { __typename?: 'Mutation', updateContactType?: Maybe<{ __typename?: 'UpdateContactTypeMutationPayload', contactType?: Maybe<(
      { __typename?: 'ContactTypeType' }
      & ContactTypeFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ActiveTogglerContactMutationVariables = Exact<{
  input: ActiveTogglerContactMutationInput;
}>;


export type ActiveTogglerContactMutation = { __typename?: 'Mutation', activeTogglerContact?: Maybe<{ __typename?: 'ActiveTogglerContactMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteContactTypeMutationVariables = Exact<{
  input: DeleteContactTypeMutationInput;
}>;


export type DeleteContactTypeMutation = { __typename?: 'Mutation', deleteContactType?: Maybe<{ __typename?: 'DeleteContactTypeMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactMutationInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact?: Maybe<{ __typename?: 'CreateContactMutationPayload', contact?: Maybe<(
      { __typename?: 'ContactType' }
      & ContactFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactMutationInput;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact?: Maybe<{ __typename?: 'UpdateContactMutationPayload', contact?: Maybe<(
      { __typename?: 'ContactType' }
      & ContactFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteContactMutationVariables = Exact<{
  input: DeleteContactMutationInput;
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact?: Maybe<{ __typename?: 'DeleteContactMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ImportContactsMutationVariables = Exact<{
  input: ImportContactsMutationInput;
}>;


export type ImportContactsMutation = { __typename?: 'Mutation', importContacts?: Maybe<{ __typename?: 'ImportContactsMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type NotificationFragment = { __typename?: 'NotificationType', id: string, content: string, icon?: Maybe<NotificationsNotificationIconChoices>, isRead: boolean, isArchived: boolean, created: string, path: string, actionPath?: Maybe<string>, actionLabel?: Maybe<string> };

export type GetNotificationsQueryVariables = Exact<{
  options?: Maybe<NotificationListOptionsInputType>;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationListType', count: number, notReadCount: number, page: number, items: Array<(
      { __typename?: 'NotificationType' }
      & NotificationFragment
    )> } };

export type GetNotificationsCountQueryVariables = Exact<{
  options?: Maybe<NotificationListOptionsInputType>;
}>;


export type GetNotificationsCountQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationListType', count: number, notReadCount: number } };

export type MarkNotificationAsReadMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type MarkNotificationAsReadMutation = { __typename?: 'Mutation', markNotificationAsRead?: Maybe<{ __typename?: 'MarkNotificationAsRead', ok?: Maybe<boolean> }> };

export type MarkAllNotificationsAsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllNotificationsAsReadMutation = { __typename?: 'Mutation', markAllNotificationsAsRead?: Maybe<{ __typename?: 'MarkAllNotificationsAsRead', ok?: Maybe<boolean> }> };

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteNotificationMutation = { __typename?: 'Mutation', deleteNotification?: Maybe<{ __typename?: 'DeleteNotification', ok?: Maybe<boolean> }> };

export type PaymentMethodFragment = { __typename?: 'PaymentMethodType', id: string, name: string, minMultiplier: number, maxMultiplier: number, allowsFinancing: boolean, defaultFinancingMonths?: Maybe<number>, defaultInterestRate: number, order: number, published: boolean, isAnnualInterestRate: boolean };

export type GetPaymentMethodQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetPaymentMethodQuery = { __typename?: 'Query', paymentMethod: (
    { __typename?: 'PaymentMethodType' }
    & PaymentMethodFragment
  ) };

export type GetPaymentMethodsQueryVariables = Exact<{
  options?: Maybe<PaymentMethodListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetPaymentMethodsQuery = { __typename?: 'Query', paymentMethods: { __typename?: 'PaymentMethodListType', count: number, items: Array<(
      { __typename?: 'PaymentMethodType' }
      & PaymentMethodFragment
    )> } };

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodMutationInput;
}>;


export type CreatePaymentMethodMutation = { __typename?: 'Mutation', createPaymentMethod?: Maybe<{ __typename?: 'CreatePaymentMethodMutationPayload', paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodType' }
      & PaymentMethodFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdatePaymentMethodMutationVariables = Exact<{
  input: UpdatePaymentMethodMutationInput;
}>;


export type UpdatePaymentMethodMutation = { __typename?: 'Mutation', updatePaymentMethod?: Maybe<{ __typename?: 'UpdatePaymentMethodMutationPayload', paymentMethod?: Maybe<(
      { __typename?: 'PaymentMethodType' }
      & PaymentMethodFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodMutationInput;
}>;


export type DeletePaymentMethodMutation = { __typename?: 'Mutation', deletePaymentMethod?: Maybe<{ __typename?: 'DeletePaymentMethodMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ProductFragment = { __typename?: 'ProductType', id: string, name: string, externalId?: Maybe<string>, description: string, created: string, updated: string, priceUpdated: string, price: number, published: boolean, suppliesCompany: (
    { __typename?: 'ProductSuppliesCompanyType' }
    & ProductSuppliesCompanyFragment
  ), unit: (
    { __typename?: 'UnitType' }
    & UnitFragment
  ) };

export type MinimalProductFragment = { __typename?: 'ProductType', id: string, name: string };

export type ProductCategoryFragment = { __typename?: 'ProductCategoryType', id: string, name: string, order: number, published: boolean, productSuppliesCompanies: Array<(
    { __typename?: 'ProductSuppliesCompanyType' }
    & ProductSuppliesCompanyFragment
  )> };

export type ProductCategoryOptionFragment = { __typename?: 'ProductCategoryType', id: string, name: string, order: number, published: boolean };

export type MinimalProductCategoryFragment = { __typename?: 'ProductCategoryType', id: string, name: string };

export type ProductSuppliesCompanyFragment = { __typename?: 'ProductSuppliesCompanyType', id: string, name: string, order: number, published: boolean, category: (
    { __typename?: 'ProductCategoryType' }
    & MinimalProductCategoryFragment
  ) };

export type UnitFragment = { __typename?: 'UnitType', id: string, name: string, symbol: string };

export type ProductVariationFragment = { __typename?: 'ProductVariationType', id: string, name: string, multiplier: number, order: number, published: boolean, categories: Array<(
    { __typename?: 'ProductCategoryType' }
    & ProductCategoryFragment
  )> };

export type ProductPriceModifierFragment = { __typename?: 'ProductPriceModifierType', id: string, name: string, minSurcharge: number, maxSurcharge: number, maxWorkflowSurcharge?: Maybe<number>, minDiscount: number, maxDiscount: number, maxWorkflowDiscount?: Maybe<number>, discountHasWorkflowRange: boolean, surchargeHasWorkflowRange: boolean, expirationDate: string, order: number, published: boolean, categories: Array<(
    { __typename?: 'ProductCategoryType' }
    & ProductCategoryFragment
  )> };

export type DefaultProductCategoryFragment = { __typename?: 'DefaultProductCategoryType', id: string, name: string, order: number };

export type DefaultProductSuppliesCompanyFragment = { __typename?: 'DefaultProductSuppliesCompanyType', id: string, name: string, order: number };

export type GetProductsQueryVariables = Exact<{
  options?: Maybe<ProductListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductListType', count: number, items: Array<(
      { __typename?: 'ProductType' }
      & ProductFragment
    )> } };

export type GetProductOptionsQueryVariables = Exact<{
  options?: Maybe<ProductListOptionsInputType>;
}>;


export type GetProductOptionsQuery = { __typename?: 'Query', products: { __typename?: 'ProductListType', count: number, items: Array<(
      { __typename?: 'ProductType' }
      & MinimalProductFragment
    )> } };

export type GetProductQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetProductQuery = { __typename?: 'Query', product: (
    { __typename?: 'ProductType' }
    & ProductFragment
  ) };

export type GetProductSuppliesCompaniesQueryVariables = Exact<{
  options?: Maybe<ProductSuppliesCompanyListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductSuppliesCompaniesQuery = { __typename?: 'Query', productSuppliesCompanies: { __typename?: 'ProductSuppliesCompanyListType', count: number, items: Array<(
      { __typename?: 'ProductSuppliesCompanyType' }
      & ProductSuppliesCompanyFragment
    )> } };

export type GetProductSuppliesCompanyQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetProductSuppliesCompanyQuery = { __typename?: 'Query', productSuppliesCompany: (
    { __typename?: 'ProductSuppliesCompanyType' }
    & ProductSuppliesCompanyFragment
  ) };

export type GetProductSuppliesCompaniesByExactNameQueryVariables = Exact<{
  name: Scalars['String'];
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductSuppliesCompaniesByExactNameQuery = { __typename?: 'Query', productSuppliesCompaniesByExactName: Array<(
    { __typename?: 'ProductSuppliesCompanyType' }
    & ProductSuppliesCompanyFragment
  )> };

export type GetProductCategoriesQueryVariables = Exact<{
  options?: Maybe<ProductCategoryListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductCategoriesQuery = { __typename?: 'Query', productCategories: { __typename?: 'ProductCategoryListType', count: number, items: Array<(
      { __typename?: 'ProductCategoryType' }
      & ProductCategoryFragment
    )> } };

export type GetProductCategoriesOptionsQueryVariables = Exact<{
  options?: Maybe<ProductCategoryListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductCategoriesOptionsQuery = { __typename?: 'Query', productCategories: { __typename?: 'ProductCategoryListType', count: number, items: Array<(
      { __typename?: 'ProductCategoryType' }
      & ProductCategoryOptionFragment
    )> } };

export type GetProductCategoryQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetProductCategoryQuery = { __typename?: 'Query', productCategory: (
    { __typename?: 'ProductCategoryType' }
    & ProductCategoryFragment
  ) };

export type GetUnitsQueryVariables = Exact<{
  options?: Maybe<UnitListOptionsInputType>;
}>;


export type GetUnitsQuery = { __typename?: 'Query', units: { __typename?: 'UnitListType', count: number, items: Array<(
      { __typename?: 'UnitType' }
      & UnitFragment
    )> } };

export type GetUnitQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetUnitQuery = { __typename?: 'Query', unit: (
    { __typename?: 'UnitType' }
    & UnitFragment
  ) };

export type GetProductVariationsQueryVariables = Exact<{
  options?: Maybe<ProductVariationListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductVariationsQuery = { __typename?: 'Query', productVariations: { __typename?: 'ProductVariationListType', count: number, items: Array<(
      { __typename?: 'ProductVariationType' }
      & ProductVariationFragment
    )> } };

export type GetProductVariationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetProductVariationQuery = { __typename?: 'Query', productVariation: (
    { __typename?: 'ProductVariationType' }
    & ProductVariationFragment
  ) };

export type GetProductPriceModifiersQueryVariables = Exact<{
  options?: Maybe<ProductPriceModifierListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetProductPriceModifiersQuery = { __typename?: 'Query', productPriceModifiers: { __typename?: 'ProductPriceModifierListType', count: number, items: Array<(
      { __typename?: 'ProductPriceModifierType' }
      & ProductPriceModifierFragment
    )> } };

export type GetProductPriceModifierQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetProductPriceModifierQuery = { __typename?: 'Query', productPriceModifier: (
    { __typename?: 'ProductPriceModifierType' }
    & ProductPriceModifierFragment
  ) };

export type GetDefaultProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultProductCategoriesQuery = { __typename?: 'Query', defaultProductCategories: Array<(
    { __typename?: 'DefaultProductCategoryType' }
    & DefaultProductCategoryFragment
  )> };

export type GetDefaultProductSuppliesCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultProductSuppliesCompanyQuery = { __typename?: 'Query', defaultProductSuppliesCompany: Array<(
    { __typename?: 'DefaultProductSuppliesCompanyType' }
    & DefaultProductSuppliesCompanyFragment
  )> };

export type CreateProductMutationVariables = Exact<{
  input: CreateProductMutationInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct?: Maybe<{ __typename?: 'CreateProductMutationPayload', product?: Maybe<(
      { __typename?: 'ProductType' }
      & ProductFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductMutationInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: Maybe<{ __typename?: 'UpdateProductMutationPayload', product?: Maybe<(
      { __typename?: 'ProductType' }
      & ProductFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteProductMutationVariables = Exact<{
  input: DeleteProductMutationInput;
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct?: Maybe<{ __typename?: 'DeleteProductMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateProductSuppliesCompanyMutationVariables = Exact<{
  input: CreateProductSuppliesCompanyMutationInput;
}>;


export type CreateProductSuppliesCompanyMutation = { __typename?: 'Mutation', createProductSuppliesCompany?: Maybe<{ __typename?: 'CreateProductSuppliesCompanyMutationPayload', productSuppliesCompany?: Maybe<(
      { __typename?: 'ProductSuppliesCompanyType' }
      & ProductSuppliesCompanyFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateProductSuppliesCompanyMutationVariables = Exact<{
  input: UpdateProductSuppliesCompanyMutationInput;
}>;


export type UpdateProductSuppliesCompanyMutation = { __typename?: 'Mutation', updateProductSuppliesCompany?: Maybe<{ __typename?: 'UpdateProductSuppliesCompanyMutationPayload', productSuppliesCompany?: Maybe<(
      { __typename?: 'ProductSuppliesCompanyType' }
      & ProductSuppliesCompanyFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteProductSuppliesCompanyMutationVariables = Exact<{
  input: DeleteProductSuppliesCompanyMutationInput;
}>;


export type DeleteProductSuppliesCompanyMutation = { __typename?: 'Mutation', deleteProductSuppliesCompany?: Maybe<{ __typename?: 'DeleteProductSuppliesCompanyMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateProductCategoryMutationVariables = Exact<{
  input: CreateProductCategoryMutationInput;
}>;


export type CreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategory?: Maybe<{ __typename?: 'CreateProductCategoryMutationPayload', productCategory?: Maybe<(
      { __typename?: 'ProductCategoryType' }
      & ProductCategoryFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateProductCategoryMutationVariables = Exact<{
  input: UpdateProductCategoryMutationInput;
}>;


export type UpdateProductCategoryMutation = { __typename?: 'Mutation', updateProductCategory?: Maybe<{ __typename?: 'UpdateProductCategoryMutationPayload', productCategory?: Maybe<(
      { __typename?: 'ProductCategoryType' }
      & ProductCategoryFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteProductCategoryMutationVariables = Exact<{
  input: DeleteProductCategoryMutationInput;
}>;


export type DeleteProductCategoryMutation = { __typename?: 'Mutation', deleteProductCategory?: Maybe<{ __typename?: 'DeleteProductCategoryMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateUnitMutationVariables = Exact<{
  input: CreateUnitMutationInput;
}>;


export type CreateUnitMutation = { __typename?: 'Mutation', createUnit?: Maybe<{ __typename?: 'CreateUnitMutationPayload', unit?: Maybe<(
      { __typename?: 'UnitType' }
      & UnitFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateUnitMutationVariables = Exact<{
  input: UpdateUnitMutationInput;
}>;


export type UpdateUnitMutation = { __typename?: 'Mutation', updateUnit?: Maybe<{ __typename?: 'UpdateUnitMutationPayload', unit?: Maybe<(
      { __typename?: 'UnitType' }
      & UnitFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteUnitMutationVariables = Exact<{
  input: DeleteUnitMutationInput;
}>;


export type DeleteUnitMutation = { __typename?: 'Mutation', deleteUnit?: Maybe<{ __typename?: 'DeleteUnitMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateProductVariationMutationVariables = Exact<{
  input: CreateProductVariationMutationInput;
}>;


export type CreateProductVariationMutation = { __typename?: 'Mutation', createProductVariation?: Maybe<{ __typename?: 'CreateProductVariationMutationPayload', productVariation?: Maybe<(
      { __typename?: 'ProductVariationType' }
      & ProductVariationFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateProductVariationMutationVariables = Exact<{
  input: UpdateProductVariationMutationInput;
}>;


export type UpdateProductVariationMutation = { __typename?: 'Mutation', updateProductVariation?: Maybe<{ __typename?: 'UpdateProductVariationMutationPayload', productVariation?: Maybe<(
      { __typename?: 'ProductVariationType' }
      & ProductVariationFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteProductVariationMutationVariables = Exact<{
  input: DeleteProductVariationMutationInput;
}>;


export type DeleteProductVariationMutation = { __typename?: 'Mutation', deleteProductVariation?: Maybe<{ __typename?: 'DeleteProductVariationMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateProductPriceModifierMutationVariables = Exact<{
  input: CreateProductPriceModifierMutationInput;
}>;


export type CreateProductPriceModifierMutation = { __typename?: 'Mutation', createProductPriceModifier?: Maybe<{ __typename?: 'CreateProductPriceModifierMutationPayload', productPriceModifier?: Maybe<(
      { __typename?: 'ProductPriceModifierType' }
      & ProductPriceModifierFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateProductPriceModifierMutationVariables = Exact<{
  input: UpdateProductPriceModifierMutationInput;
}>;


export type UpdateProductPriceModifierMutation = { __typename?: 'Mutation', updateProductPriceModifier?: Maybe<{ __typename?: 'UpdateProductPriceModifierMutationPayload', productPriceModifier?: Maybe<(
      { __typename?: 'ProductPriceModifierType' }
      & ProductPriceModifierFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteProductPriceModifierMutationVariables = Exact<{
  input: DeleteProductPriceModifierMutationInput;
}>;


export type DeleteProductPriceModifierMutation = { __typename?: 'Mutation', deleteProductPriceModifier?: Maybe<{ __typename?: 'DeleteProductPriceModifierMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ImportProductsMutationVariables = Exact<{
  input: ImportProductsMutationInput;
}>;


export type ImportProductsMutation = { __typename?: 'Mutation', importProducts?: Maybe<{ __typename?: 'ImportProductsMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type QuoteFragment = { __typename?: 'QuoteType', id: string, displayId: number, status: string, expirationDate: string, statusDisplay: string, reviewStart?: Maybe<string>, canConvertToSale: boolean, canReviewStart: boolean, internalNotes: string, clientNotes: string, conditionsOutdated: boolean, created: string, updated: string, subTotal: number, lostReason?: Maybe<(
    { __typename?: 'LostReasonType' }
    & LostReasonFragment
  )>, transitions: Array<(
    { __typename?: 'QuoteTransitionType' }
    & QuoteTransitionFragment
  )>, sale?: Maybe<{ __typename?: 'SaleType', id: string, paymentMethods: Array<{ __typename?: 'SalePaymentMethodType', id: string, percentage: number, paymentMethod: { __typename?: 'QuotePaymentMethodType', id: string, name: string } }> }>, successChance?: Maybe<(
    { __typename?: 'QuoteSuccessChanceType' }
    & QuoteSuccessChanceFragment
  )>, billingType?: Maybe<(
    { __typename?: 'BillingTypeType' }
    & BillingTypeFragment
  )>, contact?: Maybe<(
    { __typename?: 'ContactType' }
    & MinimalContactFragment
  )>, account: { __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string>, owner?: Maybe<(
      { __typename?: 'UserType' }
      & MinimalUserFragment
    )> }, products: Array<(
    { __typename?: 'QuoteProductType' }
    & QuoteProductFragment
  )>, paymentMethods: Array<(
    { __typename?: 'QuotePaymentMethodType' }
    & QuotePaymentMethodFragment
  )>, pdfs: Array<(
    { __typename?: 'QuotePDFType' }
    & QuotePdfFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> };

export type ListQuoteFragment = { __typename?: 'QuoteType', id: string, displayId: number, created: string, expirationDate: string, statusDisplay: string, conditionsOutdated: boolean, subTotal: number, contact?: Maybe<{ __typename?: 'ContactType', id: string, displayName: string, accounts: Array<{ __typename?: 'AccountType', id: string, name: string }> }>, account: { __typename?: 'AccountType', id: string, name: string }, createdBy?: Maybe<{ __typename?: 'UserType', id: string, displayName: string }>, sale?: Maybe<{ __typename?: 'SaleType', id: string }>, paymentMethods: Array<{ __typename?: 'QuotePaymentMethodType', id: string, name: string, financingMonths: number, interestRate: number, allowsFinancing: boolean, multiplier: number, total: number, financingMultiplier: number }> };

export type MinimalQuoteFragment = { __typename?: 'QuoteType', id: string, displayId: number, statusDisplay: string, expirationDate: string, created: string, conditionsOutdated: boolean, subTotal: number, contact?: Maybe<{ __typename?: 'ContactType', id: string, displayName: string, accounts: Array<{ __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string> }> }>, account: { __typename?: 'AccountType', id: string, name: string }, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, products: Array<(
    { __typename?: 'QuoteProductType', id: string }
    & MinimalQuoteProductFragment
  )>, paymentMethods: Array<(
    { __typename?: 'QuotePaymentMethodType' }
    & QuotePaymentMethodFragment
  )>, sale?: Maybe<{ __typename?: 'SaleType', id: string, paymentMethods: Array<{ __typename?: 'SalePaymentMethodType', id: string, percentage: number, paymentMethod: (
        { __typename?: 'QuotePaymentMethodType' }
        & QuotePaymentMethodFragment
      ) }> }> };

export type QuotePrintFragment = { __typename?: 'QuoteType', id: string, displayId: number, internalNotes: string, clientNotes: string, created: string, expirationDate: string, canConvertToSale: boolean, subTotal: number, createdBy?: Maybe<{ __typename?: 'UserType', displayName: string, email: string }>, contact?: Maybe<{ __typename?: 'ContactType', id: string, firstName: string, lastName: string, email: string, displayName: string, accounts: Array<{ __typename?: 'AccountType', name: string, taxId?: Maybe<string> }> }>, account: { __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string>, owner?: Maybe<(
      { __typename?: 'UserType' }
      & MinimalUserFragment
    )> }, paymentMethods: Array<(
    { __typename?: 'QuotePaymentMethodType' }
    & QuotePaymentMethodFragment
  )>, products: Array<(
    { __typename?: 'QuoteProductType' }
    & QuoteProductFragment
  )>, transitions: Array<(
    { __typename?: 'QuoteTransitionType' }
    & QuoteTransitionFragment
  )> };

export type QuoteOptionFragment = { __typename?: 'QuoteType', id: string, status: string };

export type QuoteProductFragment = { __typename?: 'QuoteProductType', id: string, name: string, externalId: string, description: string, supplyCompany: string, category: string, price: number, variationMultiplier: number, quantity: number, unitPrice: number, totalMultiplier: number, unitSymbol: string, unitName: string, originalProduct?: Maybe<{ __typename?: 'ProductType', id: string, published: boolean, price: number, suppliesCompany: { __typename?: 'ProductSuppliesCompanyType', id: string, name: string, category: { __typename?: 'ProductCategoryType', id: string, name: string } } }>, variation?: Maybe<(
    { __typename?: 'ProductVariationType' }
    & ProductVariationFragment
  )>, priceModifiers: Array<(
    { __typename?: 'QuoteProductPriceModifierType' }
    & QuoteProductPriceModifierFragment
  )>, paymentMethod?: Maybe<(
    { __typename?: 'QuotePaymentMethodType' }
    & QuotePaymentMethodFragment
  )> };

export type MinimalQuoteProductFragment = { __typename?: 'QuoteProductType', price: number, variationMultiplier: number, supplyCompany: string, category: string, quantity: number, unitPrice: number, totalMultiplier: number, unitSymbol: string, unitName: string, priceModifiers: Array<{ __typename?: 'QuoteProductPriceModifierType', multiplier: number }> };

export type QuoteProductPriceModifierFragment = { __typename?: 'QuoteProductPriceModifierType', id: string, name: string, minDiscount: number, maxDiscount: number, maxWorkflowDiscount?: Maybe<number>, minSurcharge: number, maxSurcharge: number, maxWorkflowSurcharge?: Maybe<number>, discountHasWorkflowRange: boolean, surchargeHasWorkflowRange: boolean, expirationDate: string, multiplier: number, order: number };

export type QuotePaymentMethodFragment = { __typename?: 'QuotePaymentMethodType', id: string, name: string, multiplier: number, minMultiplier: number, maxMultiplier: number, allowsFinancing: boolean, defaultFinancingMonths?: Maybe<number>, defaultInterestRate: number, order: number, financingMonths: number, interestRate: number, total: number, financingMultiplier: number, isAnnualInterestRate: boolean, uiTrackId?: Maybe<string>, originalPaymentMethod?: Maybe<{ __typename?: 'PaymentMethodType', id: string, isAnnualInterestRate: boolean }> };

export type MinimalQuotePaymentMethodFragment = { __typename?: 'QuotePaymentMethodType', multiplier: number, financingMonths: number, interestRate: number, allowsFinancing: boolean };

export type QuotePdfRecipientFragment = { __typename?: 'QuotePDFRecipientType', id: string, email: string, statusDisplay: string };

export type QuotePdfFragment = { __typename?: 'QuotePDFType', id: string, file?: Maybe<string>, type: QuotesQuotePdfTypeChoices, typeDisplay: string, mode: QuotesQuotePdfModeChoices, modeDisplay: string, created: string, slug: string, quoteStatus?: Maybe<string>, recipients: Array<(
    { __typename?: 'QuotePDFRecipientType' }
    & QuotePdfRecipientFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> };

export type QuoteTransitionFragment = { __typename?: 'QuoteTransitionType', id: string, trigger: string, sourceState: string, destState: string, created: string, statusDisplay: string, status?: Maybe<QuotesQuoteTransitionStatusChoices>, notes?: Maybe<string>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> };

export type QuoteSuccessChanceFragment = { __typename?: 'QuoteSuccessChanceType', id: string, name: string, published: boolean, order: number };

export type BillingTypeFragment = { __typename?: 'BillingTypeType', id: string, name: string, order: number, published: boolean };

export type BillingTypeOptionFragment = { __typename?: 'BillingTypeType', id: string, name: string };

export type LostReasonFragment = { __typename?: 'LostReasonType', id: string, name: string, published: boolean, order: number };

export type UpdatedQuoteInfoFragment = { __typename?: 'UpdatedQuoteInfoType', updatedProducts: Array<Maybe<{ __typename?: 'UpdatedProductDatum', id: string, price: number, outdatedQuoteProduct: { __typename?: 'QuoteProductType', id: string, name: string, supplyCompany: string, category: string, price: number, variationMultiplier: number, quantity: number, originalProduct?: Maybe<{ __typename?: 'ProductType', id: string, suppliesCompany: { __typename?: 'ProductSuppliesCompanyType', id: string, name: string, category: (
            { __typename?: 'ProductCategoryType' }
            & MinimalProductCategoryFragment
          ) } }>, variation?: Maybe<(
        { __typename?: 'ProductVariationType' }
        & ProductVariationFragment
      )>, priceModifiers: Array<(
        { __typename?: 'QuoteProductPriceModifierType' }
        & QuoteProductPriceModifierFragment
      )> } }>> };

export type GetQuotesQueryVariables = Exact<{
  options?: Maybe<QuoteListOptionsInputType>;
}>;


export type GetQuotesQuery = { __typename?: 'Query', quotes: { __typename?: 'QuoteListType', count: number, items: Array<(
      { __typename?: 'QuoteType' }
      & ListQuoteFragment
    )> } };

export type GetCountQuotesQueryVariables = Exact<{
  options?: Maybe<QuoteListOptionsInputType>;
}>;


export type GetCountQuotesQuery = { __typename?: 'Query', quotes: { __typename?: 'QuoteListType', count: number } };

export type GetQuoteOptionsQueryVariables = Exact<{
  options?: Maybe<QuoteListOptionsInputType>;
}>;


export type GetQuoteOptionsQuery = { __typename?: 'Query', quotes: { __typename?: 'QuoteListType', items: Array<(
      { __typename?: 'QuoteType' }
      & QuoteOptionFragment
    )> } };

export type GetQuoteQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetQuoteQuery = { __typename?: 'Query', quote: (
    { __typename?: 'QuoteType' }
    & QuoteFragment
  ) };

export type GetQuotePrintQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetQuotePrintQuery = { __typename?: 'Query', quote: (
    { __typename?: 'QuoteType' }
    & QuotePrintFragment
  ) };

export type GetAvailableTransitionsQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetAvailableTransitionsQuery = { __typename?: 'Query', availableTransitions: Array<Maybe<string>> };

export type GetUpdatedQuoteInfoQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetUpdatedQuoteInfoQuery = { __typename?: 'Query', updatedQuoteInfo?: Maybe<(
    { __typename?: 'UpdatedQuoteInfoType' }
    & UpdatedQuoteInfoFragment
  )> };

export type GetQuoteSuccessChanceQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetQuoteSuccessChanceQuery = { __typename?: 'Query', quoteSuccessChance: (
    { __typename?: 'QuoteSuccessChanceType' }
    & QuoteSuccessChanceFragment
  ) };

export type GetQuoteSuccessChancesQueryVariables = Exact<{
  options?: Maybe<QuoteSuccessChanceListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetQuoteSuccessChancesQuery = { __typename?: 'Query', quoteSuccessChances: { __typename?: 'QuoteSuccessChanceListType', count: number, items: Array<(
      { __typename?: 'QuoteSuccessChanceType' }
      & QuoteSuccessChanceFragment
    )> } };

export type GetBillingTypeQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetBillingTypeQuery = { __typename?: 'Query', billingType: (
    { __typename?: 'BillingTypeType' }
    & BillingTypeFragment
  ) };

export type GetBillingTypesQueryVariables = Exact<{
  options?: Maybe<BillingTypeListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetBillingTypesQuery = { __typename?: 'Query', billingTypes: { __typename?: 'BillingTypeListType', count: number, items: Array<(
      { __typename?: 'BillingTypeType' }
      & BillingTypeFragment
    )> } };

export type GetLostReasonQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetLostReasonQuery = { __typename?: 'Query', lostReason: (
    { __typename?: 'LostReasonType' }
    & LostReasonFragment
  ) };

export type GetLostReasonsQueryVariables = Exact<{
  options?: Maybe<LostReasonListOptionsInputType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetLostReasonsQuery = { __typename?: 'Query', lostReasons: { __typename?: 'LostReasonListType', count: number, items: Array<(
      { __typename?: 'LostReasonType' }
      & LostReasonFragment
    )> } };

export type CreateQuoteSuccessChanceMutationVariables = Exact<{
  input: CreateQuoteSuccessChanceMutationInput;
}>;


export type CreateQuoteSuccessChanceMutation = { __typename?: 'Mutation', createQuoteSuccessChance?: Maybe<{ __typename?: 'CreateQuoteSuccessChanceMutationPayload', quoteSuccessChance?: Maybe<(
      { __typename?: 'QuoteSuccessChanceType' }
      & QuoteSuccessChanceFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateQuoteSuccessChanceMutationVariables = Exact<{
  input: UpdateQuoteSuccessChanceMutationInput;
}>;


export type UpdateQuoteSuccessChanceMutation = { __typename?: 'Mutation', updateQuoteSuccessChance?: Maybe<{ __typename?: 'UpdateQuoteSuccessChanceMutationPayload', quoteSuccessChance?: Maybe<(
      { __typename?: 'QuoteSuccessChanceType' }
      & QuoteSuccessChanceFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteQuoteSuccessChanceMutationVariables = Exact<{
  input: DeleteQuoteSuccessChanceMutationInput;
}>;


export type DeleteQuoteSuccessChanceMutation = { __typename?: 'Mutation', deleteQuoteSuccessChance?: Maybe<{ __typename?: 'DeleteQuoteSuccessChanceMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateBillingTypeMutationVariables = Exact<{
  input: CreateBillingTypeMutationInput;
}>;


export type CreateBillingTypeMutation = { __typename?: 'Mutation', createBillingType?: Maybe<{ __typename?: 'CreateBillingTypeMutationPayload', billingType?: Maybe<(
      { __typename?: 'BillingTypeType' }
      & BillingTypeFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateBillingTypeMutationVariables = Exact<{
  input: UpdateBillingTypeMutationInput;
}>;


export type UpdateBillingTypeMutation = { __typename?: 'Mutation', updateBillingType?: Maybe<{ __typename?: 'UpdateBillingTypeMutationPayload', billingType?: Maybe<(
      { __typename?: 'BillingTypeType' }
      & BillingTypeFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteBillingTypeMutationVariables = Exact<{
  input: DeleteBillingTypeMutationInput;
}>;


export type DeleteBillingTypeMutation = { __typename?: 'Mutation', deleteBillingType?: Maybe<{ __typename?: 'DeleteBillingTypeMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateLostReasonMutationVariables = Exact<{
  input: CreateLostReasonMutationInput;
}>;


export type CreateLostReasonMutation = { __typename?: 'Mutation', createLostReason?: Maybe<{ __typename?: 'CreateLostReasonMutationPayload', lostReason?: Maybe<(
      { __typename?: 'LostReasonType' }
      & LostReasonFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateLostReasonMutationVariables = Exact<{
  input: UpdateLostReasonMutationInput;
}>;


export type UpdateLostReasonMutation = { __typename?: 'Mutation', updateLostReason?: Maybe<{ __typename?: 'UpdateLostReasonMutationPayload', lostReason?: Maybe<(
      { __typename?: 'LostReasonType' }
      & LostReasonFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteLostReasonMutationVariables = Exact<{
  input: DeleteLostReasonMutationInput;
}>;


export type DeleteLostReasonMutation = { __typename?: 'Mutation', deleteLostReason?: Maybe<{ __typename?: 'DeleteLostReasonMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateQuoteMutationVariables = Exact<{
  input: CreateQuoteMutationInput;
}>;


export type CreateQuoteMutation = { __typename?: 'Mutation', createQuote?: Maybe<{ __typename?: 'CreateQuoteMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateQuoteMutationVariables = Exact<{
  input: UpdateQuoteMutationInput;
}>;


export type UpdateQuoteMutation = { __typename?: 'Mutation', updateQuote?: Maybe<{ __typename?: 'UpdateQuoteMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteQuoteMutationVariables = Exact<{
  input: DeleteQuoteMutationInput;
}>;


export type DeleteQuoteMutation = { __typename?: 'Mutation', deleteQuote?: Maybe<{ __typename?: 'DeleteQuoteMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateQuotePdfMutationVariables = Exact<{
  input: CreateQuotePdfMutationInput;
}>;


export type CreateQuotePdfMutation = { __typename?: 'Mutation', createQuotePDF?: Maybe<{ __typename?: 'CreateQuotePDFMutationPayload', quotePDF?: Maybe<{ __typename?: 'QuotePDFType', file?: Maybe<string>, slug: string, quote: { __typename?: 'QuoteType', id: string } }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type QuoteTransitionMutationVariables = Exact<{
  input: QuoteTransitionMutationInput;
}>;


export type QuoteTransitionMutation = { __typename?: 'Mutation', quoteTransition?: Maybe<{ __typename?: 'QuoteTransitionMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type StartQuoteReviewMutationVariables = Exact<{
  input: StartQuoteReviewMutationInput;
}>;


export type StartQuoteReviewMutation = { __typename?: 'Mutation', startQuoteReview?: Maybe<{ __typename?: 'StartQuoteReviewMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type MarkQuoteAsLostMutationVariables = Exact<{
  input: MarkQuoteAsLostMutationInput;
}>;


export type MarkQuoteAsLostMutation = { __typename?: 'Mutation', markQuoteAsLost?: Maybe<{ __typename?: 'MarkQuoteAsLostMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type MarkQuoteAsRequestedExceptionMutationVariables = Exact<{
  input: MarkQuoteAsRequestedExceptionMutationInput;
}>;


export type MarkQuoteAsRequestedExceptionMutation = { __typename?: 'Mutation', markQuoteAsRequestedException?: Maybe<{ __typename?: 'MarkQuoteAsRequestedExceptionMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type TransitionQuoteWithExceptionMutationVariables = Exact<{
  input: TransitionQuoteWithExceptionMutationInput;
}>;


export type TransitionQuoteWithExceptionMutation = { __typename?: 'Mutation', transitionQuoteWithException?: Maybe<{ __typename?: 'TransitionQuoteWithExceptionMutationPayload', quote?: Maybe<(
      { __typename?: 'QuoteType' }
      & QuoteFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateQuotePricesMutationVariables = Exact<{
  input: UpdateQuotePricesMutationInput;
}>;


export type UpdateQuotePricesMutation = { __typename?: 'Mutation', updateQuotePrices?: Maybe<{ __typename?: 'UpdateQuotePricesMutationPayload', quote: string, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type QuotePdfHtmlQueryVariables = Exact<{
  pdfType: Scalars['String'];
  configs: Array<Maybe<PdfConfigInput>>;
}>;


export type QuotePdfHtmlQuery = { __typename?: 'Query', quotePdfHtml: string };

export type CreateQuoteExportMutationVariables = Exact<{
  options?: Maybe<QuoteListOptionsInputType>;
}>;


export type CreateQuoteExportMutation = { __typename?: 'Mutation', createQuoteExport?: Maybe<{ __typename?: 'CreateQuoteExport', job: (
      { __typename?: 'ExportJobType' }
      & ExportJobFragment
    ) }> };

export type SeasonFragment = { __typename?: 'SeasonType', id: string, name: string, startDate: string, endDate: string };

export type SalesTargetFragment = { __typename?: 'SalesTargetType', id: string, subtotal: number, amount: number, season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ), user: (
    { __typename?: 'UserType', role?: Maybe<{ __typename?: 'RoleType', name: string }>, salesTargets: Array<{ __typename?: 'SalesTargetType', amount: number, season: (
        { __typename?: 'SeasonType' }
        & SeasonFragment
      ) }> }
    & MinimalUserFragment
  ) };

export type SalesTargetsByUserFragment = { __typename?: 'SalesTargetType', id: string, amount: number, user: (
    { __typename?: 'UserType' }
    & MinimalUserFragment
  ), season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ) };

export type CurrentUsersSalesTargetsFragment = { __typename?: 'UserType', id: string, email: string, firstName: string, lastName: string, displayName: string, salesTargetsAmount?: Maybe<number>, salesTargetsChildsAmount?: Maybe<number>, childUsers: Array<{ __typename?: 'UserType', id: string, email: string }>, role?: Maybe<{ __typename?: 'RoleType', id: string, name: string }>, parentsUsers: Array<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, salesTargets: Array<{ __typename?: 'SalesTargetType', id: string, season: (
      { __typename?: 'SeasonType' }
      & SeasonFragment
    ) }> };

export type AggregatedSalesTargetFragment = { __typename?: 'AggregatedSalesTargetType', amount: number, season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ) };

export type SalePaymentMethodFragment = { __typename?: 'SalePaymentMethodType', id: string, percentage: number, total: number, subTotal: number, sale: (
    { __typename?: 'SaleType' }
    & SaleFragment
  ), paymentMethod: (
    { __typename?: 'QuotePaymentMethodType' }
    & QuotePaymentMethodFragment
  ) };

export type MinimalSalePaymentMethodFragment = { __typename?: 'SalePaymentMethodType', id: string, percentage: number, total: number, subTotal: number, sale: { __typename?: 'SaleType', id: string }, paymentMethod: (
    { __typename?: 'QuotePaymentMethodType' }
    & QuotePaymentMethodFragment
  ) };

export type SaleFragment = { __typename?: 'SaleType', id: string, displayId: number, displayName: string, annuledReason?: Maybe<string>, annuled: boolean, date: string, internalNotes: string, clientNotes: string, created: string, paymentMethods: Array<(
    { __typename?: 'SalePaymentMethodType' }
    & MinimalSalePaymentMethodFragment
  )>, quote: { __typename?: 'QuoteType', status: string, sale?: Maybe<{ __typename?: 'SaleType', id: string }>, products: Array<(
      { __typename?: 'QuoteProductType' }
      & QuoteProductFragment
    )>, account: { __typename?: 'AccountType', id: string }, contact?: Maybe<(
      { __typename?: 'ContactType', accounts: Array<(
        { __typename?: 'AccountType' }
        & MinimalAccountFragment
      )> }
      & MinimalContactFragment
    )> }, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, pdfs: Array<(
    { __typename?: 'SalePDFType' }
    & SalePdfFragment
  )> };

export type MinimalSaleFragment = { __typename?: 'SaleType', id: string, displayId: number, annuledReason?: Maybe<string>, annuled: boolean, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, paymentMethods: Array<(
    { __typename?: 'SalePaymentMethodType' }
    & MinimalSalePaymentMethodFragment
  )>, quote: { __typename?: 'QuoteType', id: string, products: Array<{ __typename?: 'QuoteProductType', id: string, price: number, quantity: number }> } };

export type SalePrintFragment = { __typename?: 'SaleType', id: string, displayId: number, displayName: string, date: string, internalNotes: string, clientNotes: string, created: string, paymentMethods: Array<(
    { __typename?: 'SalePaymentMethodType' }
    & MinimalSalePaymentMethodFragment
  )>, quote: { __typename?: 'QuoteType', account: { __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string> }, products: Array<(
      { __typename?: 'QuoteProductType' }
      & QuoteProductFragment
    )>, contact?: Maybe<(
      { __typename?: 'ContactType', accounts: Array<(
        { __typename?: 'AccountType' }
        & MinimalAccountFragment
      )> }
      & MinimalContactFragment
    )> }, createdBy?: Maybe<{ __typename?: 'UserType', displayName: string, email: string }> };

export type SaleDetailFragment = (
  { __typename?: 'SaleType', updated: string, updatedBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, paymentMethods: Array<(
    { __typename?: 'SalePaymentMethodType' }
    & MinimalSalePaymentMethodFragment
  )>, quote: (
    { __typename?: 'QuoteType', products: Array<(
      { __typename?: 'QuoteProductType' }
      & QuoteProductFragment
    )> }
    & QuoteFragment
  ) }
  & SaleFragment
);

export type SaleDetailListFragment = { __typename?: 'SaleType', id: string, displayId: number, date: string, annuled: boolean, annuledReason?: Maybe<string>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )>, paymentMethods: Array<{ __typename?: 'SalePaymentMethodType', id: string, percentage: number, total: number, subTotal: number }>, quote: { __typename?: 'QuoteType', contact?: Maybe<(
      { __typename?: 'ContactType' }
      & MinimalContactFragment
    )>, account: { __typename?: 'AccountType', id: string, name: string, taxId?: Maybe<string>, owner?: Maybe<(
        { __typename?: 'UserType' }
        & MinimalUserFragment
      )> } } };

export type SalePdfRecipientFragment = { __typename?: 'SalePDFRecipientType', id: string, email: string, statusDisplay: string };

export type SalePdfFragment = { __typename?: 'SalePDFType', id: string, file?: Maybe<string>, created: string, slug: string, recipients: Array<(
    { __typename?: 'SalePDFRecipientType' }
    & SalePdfRecipientFragment
  )>, createdBy?: Maybe<(
    { __typename?: 'UserType' }
    & MinimalUserFragment
  )> };

export type GetSalesQueryVariables = Exact<{
  options?: Maybe<SaleListOptionsInputType>;
}>;


export type GetSalesQuery = { __typename?: 'Query', sales: { __typename?: 'SaleListType', count: number, items: Array<(
      { __typename?: 'SaleType' }
      & SaleFragment
    )> } };

export type GetSaleQueryVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
}>;


export type GetSaleQuery = { __typename?: 'Query', sale: (
    { __typename?: 'SaleType' }
    & SaleDetailFragment
  ) };

export type GetSalePrintQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetSalePrintQuery = { __typename?: 'Query', sale: (
    { __typename?: 'SaleType' }
    & SalePrintFragment
  ) };

export type GetSalesListQueryVariables = Exact<{
  options?: Maybe<SaleListOptionsInputType>;
}>;


export type GetSalesListQuery = { __typename?: 'Query', sales: { __typename?: 'SaleListType', count: number, items: Array<(
      { __typename?: 'SaleType' }
      & SaleDetailListFragment
    )> } };

export type CreateSaleMutationVariables = Exact<{
  input: CreateSaleMutationInput;
}>;


export type CreateSaleMutation = { __typename?: 'Mutation', createSale?: Maybe<{ __typename?: 'CreateSaleMutationPayload', sale?: Maybe<(
      { __typename?: 'SaleType' }
      & SaleDetailFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateSaleMutationVariables = Exact<{
  input: UpdateSaleMutationInput;
}>;


export type UpdateSaleMutation = { __typename?: 'Mutation', updateSale?: Maybe<{ __typename?: 'UpdateSaleMutationPayload', sale?: Maybe<(
      { __typename?: 'SaleType' }
      & SaleDetailFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteSaleMutationVariables = Exact<{
  input: DeleteSaleMutationInput;
}>;


export type DeleteSaleMutation = { __typename?: 'Mutation', deleteSale?: Maybe<{ __typename?: 'DeleteSaleMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type GetCurrentSeasonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentSeasonQuery = { __typename?: 'Query', currentSeason?: Maybe<(
    { __typename?: 'SeasonType' }
    & SeasonFragment
  )> };

export type GetSeasonsQueryVariables = Exact<{
  options?: Maybe<SeasonListOptionsInputType>;
}>;


export type GetSeasonsQuery = { __typename?: 'Query', seasons: { __typename?: 'SeasonListType', count: number, items: Array<(
      { __typename?: 'SeasonType' }
      & SeasonFragment
    )> } };

export type GetSeasonQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetSeasonQuery = { __typename?: 'Query', season: (
    { __typename?: 'SeasonType' }
    & SeasonFragment
  ) };

export type CreateSeasonMutationVariables = Exact<{
  input: CreateSeasonMutationInput;
}>;


export type CreateSeasonMutation = { __typename?: 'Mutation', createSeason?: Maybe<{ __typename?: 'CreateSeasonMutationPayload', season?: Maybe<(
      { __typename?: 'SeasonType' }
      & SeasonFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateSeasonMutationVariables = Exact<{
  input: UpdateSeasonMutationInput;
}>;


export type UpdateSeasonMutation = { __typename?: 'Mutation', updateSeason?: Maybe<{ __typename?: 'UpdateSeasonMutationPayload', season?: Maybe<(
      { __typename?: 'SeasonType' }
      & SeasonFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteSeasonMutationVariables = Exact<{
  input: DeleteSeasonMutationInput;
}>;


export type DeleteSeasonMutation = { __typename?: 'Mutation', deleteSeason?: Maybe<{ __typename?: 'DeleteSeasonMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type GetSalesTargetsQueryVariables = Exact<{
  options?: Maybe<SalesTargetListOptionsInputType>;
}>;


export type GetSalesTargetsQuery = { __typename?: 'Query', salesTargets: { __typename?: 'SalesTargetListType', count: number, items: Array<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetFragment
    )> } };

export type GetSalesTargetQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetSalesTargetQuery = { __typename?: 'Query', salesTarget: (
    { __typename?: 'SalesTargetType' }
    & SalesTargetFragment
  ) };

export type GetSalesTargetsByUserQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetSalesTargetsByUserQuery = { __typename?: 'Query', salesTargetsByUser: Array<Maybe<(
    { __typename?: 'SalesTargetType' }
    & SalesTargetsByUserFragment
  )>> };

export type GetCurrentUsersSalesTargetsQueryVariables = Exact<{
  options?: Maybe<CurrentUsersSalesTargetsListOptionsInputType>;
  includeSelf?: Maybe<Scalars['Boolean']>;
}>;


export type GetCurrentUsersSalesTargetsQuery = { __typename?: 'Query', currentUsersSalesTargets?: Maybe<{ __typename?: 'CurrentUsersSalesTargetsListType', count: number, items: Array<(
      { __typename?: 'UserType' }
      & CurrentUsersSalesTargetsFragment
    )> }> };

export type GetAggregatedSalesTargetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAggregatedSalesTargetsQuery = { __typename?: 'Query', aggregatedSalesTargets: Array<Maybe<(
    { __typename?: 'AggregatedSalesTargetType' }
    & AggregatedSalesTargetFragment
  )>> };

export type CreateSalesTargetMutationVariables = Exact<{
  input: CreateSalesTargetMutationInput;
}>;


export type CreateSalesTargetMutation = { __typename?: 'Mutation', createSalesTarget?: Maybe<{ __typename?: 'CreateSalesTargetMutationPayload', salesTarget?: Maybe<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateMultipleSalesTargetsMutationVariables = Exact<{
  salesTargets: Array<SalesTargetFormInput>;
  deleteSalesTargets?: Maybe<Array<Scalars['UUID']>>;
}>;


export type CreateMultipleSalesTargetsMutation = { __typename?: 'Mutation', createMultipleSalesTargets?: Maybe<{ __typename?: 'CreateSalesTargetsMutation', salesTargets: Array<Maybe<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetFragment
    )>>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateSalesTargetMutationVariables = Exact<{
  input: UpdateSalesTargetMutationInput;
}>;


export type UpdateSalesTargetMutation = { __typename?: 'Mutation', updateSalesTarget?: Maybe<{ __typename?: 'UpdateSalesTargetMutationPayload', salesTarget?: Maybe<(
      { __typename?: 'SalesTargetType' }
      & SalesTargetFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteSalesTargetMutationVariables = Exact<{
  input: DeleteSalesTargetMutationInput;
}>;


export type DeleteSalesTargetMutation = { __typename?: 'Mutation', deleteSalesTarget?: Maybe<{ __typename?: 'DeleteSalesTargetMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteMultipleSalesTargetsMutationVariables = Exact<{
  salesTargets: Array<Scalars['UUID']>;
}>;


export type DeleteMultipleSalesTargetsMutation = { __typename?: 'Mutation', deleteMultipleSalesTargets?: Maybe<{ __typename?: 'DeleteSalesTargetsMutation', ok?: Maybe<boolean> }> };

export type ImportSalesTargetMutationVariables = Exact<{
  input: ImportSalesTargetMutationInput;
}>;


export type ImportSalesTargetMutation = { __typename?: 'Mutation', importSalesTarget?: Maybe<{ __typename?: 'ImportSalesTargetMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type CreateSalePdfMutationVariables = Exact<{
  input: CreateSalePdfMutationInput;
}>;


export type CreateSalePdfMutation = { __typename?: 'Mutation', createSalePDF?: Maybe<{ __typename?: 'CreateSalePDFMutationPayload', salePDF?: Maybe<{ __typename?: 'SalePDFType', file?: Maybe<string>, slug: string, sale: { __typename?: 'SaleType', id: string } }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type AnnulSaleMutationVariables = Exact<{
  input: AnnulSaleMutationInput;
}>;


export type AnnulSaleMutation = { __typename?: 'Mutation', annulSale?: Maybe<{ __typename?: 'AnnulSaleMutationPayload', sale?: Maybe<{ __typename?: 'SaleType', id: string }> }> };

export type CreateSaleExportMutationVariables = Exact<{
  options?: Maybe<SaleListOptionsInputType>;
}>;


export type CreateSaleExportMutation = { __typename?: 'Mutation', createSaleExport?: Maybe<{ __typename?: 'CreateSaleExport', job: (
      { __typename?: 'ExportJobType' }
      & ExportJobFragment
    ) }> };

export type FeatureTutorialFragment = { __typename?: 'FeatureTutorialType', id: string, name: string, codename: SystemFeatureTutorialCodenameChoices, steps?: Maybe<Array<Maybe<{ __typename?: 'TutorialStepType', content: string }>>> };

export type GetFeatureTutorialQueryVariables = Exact<{
  codename: Scalars['String'];
}>;


export type GetFeatureTutorialQuery = { __typename?: 'Query', featureTutorial?: Maybe<(
    { __typename?: 'FeatureTutorialType' }
    & FeatureTutorialFragment
  )> };

export type JobFragment = { __typename?: 'JobType', id: string, status: JobStatus, result?: Maybe<string> };

export type ExportJobFragment = { __typename?: 'ExportJobType', id: string, status: JobStatus, result?: Maybe<(
    { __typename?: 'DownloadType' }
    & DownloadFragment
  )> };

export type DownloadFragment = { __typename?: 'DownloadType', contentType: string, data: any, filename: string };

export type GetExportFileJobQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetExportFileJobQuery = { __typename?: 'Query', exportFileJob?: Maybe<(
    { __typename?: 'ExportJobType' }
    & ExportJobFragment
  )> };

export type TenantFragment = { __typename?: 'TenantType', id: string, name: string, subdomain: string, logo: string, pdfLogo: string, taxIdFormat: TenantsTenantTaxIdFormatChoices, quotePdfDisclaimer: string, salePdfDisclaimer: string, currency: string, quoteExpirationDays: number, maxAllowedDaysConvertSale: number, currencyLabel: TenantsTenantCurrencyLabelChoices, isCurrencyShort: boolean, workflow?: Maybe<(
    { __typename?: 'WorkflowType' }
    & WorkflowFragment
  )> };

export type GetActiveTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveTenantQuery = { __typename?: 'Query', activeTenant?: Maybe<(
    { __typename?: 'TenantType' }
    & TenantFragment
  )> };

export type UpdateQuoteDisclaimerMutationVariables = Exact<{
  input: UpdateQuoteDisclaimerMutationInput;
}>;


export type UpdateQuoteDisclaimerMutation = { __typename?: 'Mutation', updateQuoteDisclaimer?: Maybe<{ __typename?: 'UpdateQuoteDisclaimerMutationPayload', tenant?: Maybe<{ __typename?: 'TenantType', id: string, quotePdfDisclaimer: string }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateSaleDisclaimerMutationVariables = Exact<{
  input: UpdateSaleDisclaimerMutationInput;
}>;


export type UpdateSaleDisclaimerMutation = { __typename?: 'Mutation', updateSaleDisclaimer?: Maybe<{ __typename?: 'UpdateSaleDisclaimerMutationPayload', tenant?: Maybe<{ __typename?: 'TenantType', id: string, salePdfDisclaimer: string }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateQuoteExpirationDaysMutationVariables = Exact<{
  input: UpdateQuoteExpirationDaysFormMutationInput;
}>;


export type UpdateQuoteExpirationDaysMutation = { __typename?: 'Mutation', updateQuoteExpirationDays?: Maybe<{ __typename?: 'UpdateQuoteExpirationDaysFormMutationPayload', tenant?: Maybe<{ __typename?: 'TenantType', id: string, quoteExpirationDays: number }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type LogoMutationVariables = Exact<{
  input: LogoMutationInput;
}>;


export type LogoMutation = { __typename?: 'Mutation', logo?: Maybe<{ __typename?: 'LogoMutationPayload', tenant?: Maybe<{ __typename?: 'TenantType', id: string }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type PdfConfigFragment = { __typename?: 'PdfConfigType', key: string, categoryKey: TenantsPdfConfigCategoryKeyChoices, type: TenantsPdfConfigTypeChoices, value: boolean };

export type UpdatePdfConfigMutationVariables = Exact<{
  input: Array<PdfConfigInput>;
}>;


export type UpdatePdfConfigMutation = { __typename?: 'Mutation', updatePdfConfig?: Maybe<{ __typename?: 'PdfConfigMutation', ok?: Maybe<boolean> }> };

export type GetPdfConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPdfConfigsQuery = { __typename?: 'Query', pdfConfigs?: Maybe<Array<Maybe<(
    { __typename?: 'PdfConfigType' }
    & PdfConfigFragment
  )>>> };

export type UserFragment = { __typename?: 'UserType', id: string, email: string, firstName: string, lastName: string, displayName: string, language?: Maybe<string>, isActive: boolean, isStaff: boolean, isTenantStaff: boolean, dateJoined: string, lastLogin?: Maybe<string>, phone: string, phoneCountryCode: string, hasUsablePassword: boolean, emailNotifications: boolean, role?: Maybe<{ __typename?: 'RoleType', id: string, name: string }>, parentsUsers: Array<(
    { __typename?: 'UserType', usernodeSet: Array<{ __typename?: 'UserNodeType', id: string }> }
    & MinimalUserFragment
  )>, organization?: Maybe<{ __typename?: 'OrganizationType', id: string, name: string }>, permissions: { __typename?: 'UserPermissions', createQuotes: boolean, viewQuotes: boolean, viewSales: boolean, annulSales: boolean, viewAccounts: boolean, viewContacts: boolean, viewActivities: boolean, assignAccountOwner: boolean, requestQuoteReview: boolean, convertQuoteToSale: boolean, viewAnalytics: boolean, approveException: boolean, notificateQuoteLost: boolean, notificateQuoteInSale: boolean, assignActivity: boolean, notificateSaleAnnuled: boolean }, usernodeSet: Array<{ __typename?: 'UserNodeType', id: string }> };

export type MinimalUserFragment = { __typename?: 'UserType', id: string, displayName: string, email: string, phone: string, phoneCountryCode: string };

export type RoleFragment = { __typename?: 'RoleType', id: string, name: string, permissions: Array<(
    { __typename?: 'PermissionType' }
    & PermissionFragment
  )> };

export type RoleOptionFragment = { __typename?: 'RoleType', id: string, name: string };

export type PermissionFragment = { __typename?: 'PermissionType', id: string, name: string, codename: string, displayName: string };

export type OrganizationFragment = { __typename?: 'OrganizationType', id: string, name: string };

export type GetAuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthUserQuery = { __typename?: 'Query', authUser?: Maybe<(
    { __typename?: 'UserType' }
    & UserFragment
  )> };

export type GetCustomPermissionsQueryVariables = Exact<{
  options?: Maybe<PermissionListOptionsInputType>;
}>;


export type GetCustomPermissionsQuery = { __typename?: 'Query', customPermissions: { __typename?: 'PermissionListType', items: Array<(
      { __typename?: 'PermissionType' }
      & PermissionFragment
    )> } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetUserQuery = { __typename?: 'Query', user: (
    { __typename?: 'UserType' }
    & UserFragment
  ) };

export type GetUsersQueryVariables = Exact<{
  options?: Maybe<UserListOptionsInputType>;
  includeStaff?: Maybe<Scalars['Boolean']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserListType', count: number, items: Array<(
      { __typename?: 'UserType' }
      & UserFragment
    )> } };

export type GetUserOptionsQueryVariables = Exact<{
  options?: Maybe<UserListOptionsInputType>;
}>;


export type GetUserOptionsQuery = { __typename?: 'Query', users: { __typename?: 'UserListType', items: Array<(
      { __typename?: 'UserType' }
      & MinimalUserFragment
    )> } };

export type GetSupervisorUserOptionsQueryVariables = Exact<{
  options?: Maybe<UserListOptionsInputType>;
}>;


export type GetSupervisorUserOptionsQuery = { __typename?: 'Query', users: { __typename?: 'UserListType', items: Array<(
      { __typename?: 'UserType', childUsers: Array<{ __typename?: 'UserType', id: string }>, parentsUsers: Array<{ __typename?: 'UserType', id: string }>, usernodeSet: Array<{ __typename?: 'UserNodeType', id: string, displayName: string, level: number }>, childNodes: Array<{ __typename?: 'UserNodeType', id: string, displayName: string, level: number }> }
      & MinimalUserFragment
    )> } };

export type GetChildUsersQueryVariables = Exact<{
  options?: Maybe<UserListOptionsInputType>;
}>;


export type GetChildUsersQuery = { __typename?: 'Query', childUsers: { __typename?: 'UserListType', count: number, items: Array<(
      { __typename?: 'UserType' }
      & UserFragment
    )> } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserMutationInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: Maybe<{ __typename?: 'CreateUserMutationPayload', user?: Maybe<(
      { __typename?: 'UserType' }
      & UserFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserMutationInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: Maybe<{ __typename?: 'UpdateUserMutationPayload', user?: Maybe<(
      { __typename?: 'UserType' }
      & UserFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type GetRoleQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetRoleQuery = { __typename?: 'Query', role: (
    { __typename?: 'RoleType' }
    & RoleFragment
  ) };

export type GetRolesQueryVariables = Exact<{
  options?: Maybe<RoleListOptionsInputType>;
}>;


export type GetRolesQuery = { __typename?: 'Query', roles: { __typename?: 'RoleListType', count: number, items: Array<(
      { __typename?: 'RoleType' }
      & RoleFragment
    )> } };

export type GetRoleOptionsQueryVariables = Exact<{
  options?: Maybe<RoleListOptionsInputType>;
}>;


export type GetRoleOptionsQuery = { __typename?: 'Query', roles: { __typename?: 'RoleListType', items: Array<(
      { __typename?: 'RoleType' }
      & RoleOptionFragment
    )> } };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleMutationInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole?: Maybe<{ __typename?: 'CreateRoleMutationPayload', role?: Maybe<(
      { __typename?: 'RoleType' }
      & RoleFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleMutationInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole?: Maybe<{ __typename?: 'UpdateRoleMutationPayload', role?: Maybe<(
      { __typename?: 'RoleType' }
      & RoleFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleMutationInput;
}>;


export type DeleteRoleMutation = { __typename?: 'Mutation', deleteRole?: Maybe<{ __typename?: 'DeleteRoleMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', organization: (
    { __typename?: 'OrganizationType' }
    & OrganizationFragment
  ) };

export type GetOrganizationsQueryVariables = Exact<{
  options?: Maybe<OrganizationListOptionsInputType>;
}>;


export type GetOrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'OrganizationListType', count: number, items: Array<(
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    )> } };

export type GetChildOrganizationsQueryVariables = Exact<{
  options?: Maybe<OrganizationListOptionsInputType>;
}>;


export type GetChildOrganizationsQuery = { __typename?: 'Query', childOrganizations: { __typename?: 'OrganizationListType', count: number, items: Array<(
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    )> } };

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationMutationInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization?: Maybe<{ __typename?: 'CreateOrganizationMutationPayload', organization?: Maybe<(
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationMutationInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: Maybe<{ __typename?: 'UpdateOrganizationMutationPayload', organization?: Maybe<(
      { __typename?: 'OrganizationType' }
      & OrganizationFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type DeleteOrganizationMutationVariables = Exact<{
  input: DeleteOrganizationMutationInput;
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization?: Maybe<{ __typename?: 'DeleteOrganizationMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: Maybe<{ __typename?: 'LoginPayload', user?: Maybe<(
      { __typename?: 'UserType' }
      & UserFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout?: Maybe<{ __typename?: 'Logout', success?: Maybe<boolean> }> };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordMutationInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: Maybe<{ __typename?: 'ResetPasswordMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordMutationInput;
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword?: Maybe<{ __typename?: 'SetPasswordMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordMutationInput;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword?: Maybe<{ __typename?: 'UpdatePasswordMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type SendResetPasswordEmailMutationVariables = Exact<{
  input: SendResetPasswordEmailMutationInput;
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail?: Maybe<{ __typename?: 'SendResetPasswordEmailMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type SendUserActivationEmailMutationVariables = Exact<{
  input: SendUserActivationEmailMutationInput;
}>;


export type SendUserActivationEmailMutation = { __typename?: 'Mutation', sendUserActivationEmail?: Maybe<{ __typename?: 'SendUserActivationEmailMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileMutationInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile?: Maybe<{ __typename?: 'UpdateUserProfileMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type UpdateCurrencyMutationVariables = Exact<{
  input: UpdateCurrencyMutationInput;
}>;


export type UpdateCurrencyMutation = { __typename?: 'Mutation', updateCurrency?: Maybe<{ __typename?: 'UpdateCurrencyMutationPayload', tenant?: Maybe<{ __typename?: 'TenantType', currency: string }>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ImportUsersMutationVariables = Exact<{
  input: ImportUsersMutationInput;
}>;


export type ImportUsersMutation = { __typename?: 'Mutation', importUsers?: Maybe<{ __typename?: 'ImportUsersMutationPayload', errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export type ToggleEmailNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type ToggleEmailNotificationsMutation = { __typename?: 'Mutation', toggleEmailNotifications?: Maybe<{ __typename?: 'ToggleEmailNotifications', ok?: Maybe<boolean> }> };

export type TreeUserFragment = { __typename?: 'UserType', id: string, displayName: string, email: string, isStaff: boolean, parentsUsers: Array<{ __typename?: 'UserType', id: string, displayName: string }>, role?: Maybe<{ __typename?: 'RoleType', id: string, name: string }> };

export type GetTreeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTreeQuery = { __typename?: 'Query', tree: { __typename?: 'TreeType', parents: Array<(
      { __typename?: 'UserType' }
      & TreeUserFragment
    )>, childs: Array<(
      { __typename?: 'UserType' }
      & TreeUserFragment
    )>, equal: Array<(
      { __typename?: 'UserType' }
      & TreeUserFragment
    )> } };

export type GetUserNodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNodesQuery = { __typename?: 'Query', userNodes: Array<{ __typename?: 'UserNodeType', id: string, parent?: Maybe<{ __typename?: 'UserNodeType', id: string }>, users: Array<(
      { __typename?: 'UserType' }
      & TreeUserFragment
    )> }> };

export type WorkflowFragment = { __typename?: 'WorkflowType', id: string, schema: any, initial?: Maybe<string>, saleState?: Maybe<string>, lostState?: Maybe<string>, approveState?: Maybe<string>, states?: Maybe<Array<string>>, initialReview?: Maybe<string>, exceptionState?: Maybe<string>, deleted?: Maybe<string>, expiredState?: Maybe<string>, saleCanceled?: Maybe<string> };

export type GetWorkflowQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkflowQuery = { __typename?: 'Query', workflow: (
    { __typename?: 'WorkflowType' }
    & WorkflowFragment
  ) };

export type GetWorkflowStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkflowStatesQuery = { __typename?: 'Query', workflowStates: Array<Maybe<string>> };

export type UpdateWorkflowMutationVariables = Exact<{
  input: UpdateWorkflowMutationInput;
}>;


export type UpdateWorkflowMutation = { __typename?: 'Mutation', updateWorkflow?: Maybe<{ __typename?: 'UpdateWorkflowMutationPayload', workflow?: Maybe<(
      { __typename?: 'WorkflowType' }
      & WorkflowFragment
    )>, errors?: Maybe<Array<Maybe<{ __typename?: 'ErrorType', field: string, messages: Array<string> }>>> }> };

export const AccountTypeFragmentDoc = gql`
    fragment AccountType on AccountTypeType {
  id
  name
  published
  order
}
    `;
export const AccountRegionFragmentDoc = gql`
    fragment AccountRegion on AccountRegionType {
  id
  name
  published
  order
}
    `;
export const MinimalUserFragmentDoc = gql`
    fragment MinimalUser on UserType {
  id
  displayName
  email
  phone
  phoneCountryCode
}
    `;
export const MinimalAccountFragmentDoc = gql`
    fragment MinimalAccount on AccountType {
  id
  name
  legalName
  taxId
  taxIdFormat
  type {
    ...AccountType
  }
  region {
    ...AccountRegion
  }
  externalId
  owner {
    ...MinimalUser
  }
  notes
  active
}
    ${AccountTypeFragmentDoc}
${AccountRegionFragmentDoc}
${MinimalUserFragmentDoc}`;
export const ContactTypeFragmentDoc = gql`
    fragment ContactType on ContactTypeType {
  id
  name
  published
  order
}
    `;
export const MinimalContactFragmentDoc = gql`
    fragment MinimalContact on ContactType {
  id
  email
  firstName
  lastName
  displayName
  phoneCountryCode
  phone
  mobileCountryCode
  mobilePhone
  type {
    ...ContactType
  }
  accounts {
    id
    name
    taxId
    legalName
    active
    owner {
      ...MinimalUser
    }
  }
  notes
  active
}
    ${ContactTypeFragmentDoc}
${MinimalUserFragmentDoc}`;
export const MinimalActivityFragmentDoc = gql`
    fragment MinimalActivity on ActivityType {
  id
  date
  channel
  reason
  notes
  createdBy {
    ...MinimalUser
  }
  contact {
    id
    displayName
    accounts {
      id
      name
      taxId
    }
  }
  account {
    id
    name
    taxId
  }
  assignedTo {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}`;
export const MinimalQuoteProductFragmentDoc = gql`
    fragment MinimalQuoteProduct on QuoteProductType {
  price
  variationMultiplier
  supplyCompany
  category
  priceModifiers {
    multiplier
  }
  quantity
  unitPrice
  totalMultiplier
  unitSymbol
  unitName
}
    `;
export const QuotePaymentMethodFragmentDoc = gql`
    fragment QuotePaymentMethod on QuotePaymentMethodType {
  id
  name
  multiplier
  minMultiplier
  maxMultiplier
  allowsFinancing
  defaultFinancingMonths
  defaultInterestRate
  order
  financingMonths
  interestRate
  originalPaymentMethod {
    id
    isAnnualInterestRate
  }
  total
  financingMultiplier
  isAnnualInterestRate
  uiTrackId
}
    `;
export const MinimalQuoteFragmentDoc = gql`
    fragment MinimalQuote on QuoteType {
  id
  displayId
  statusDisplay
  expirationDate
  contact {
    id
    displayName
    accounts {
      id
      name
      taxId
    }
  }
  account {
    id
    name
  }
  created
  createdBy {
    ...MinimalUser
  }
  products {
    id
    ...MinimalQuoteProduct
  }
  paymentMethods {
    ...QuotePaymentMethod
  }
  conditionsOutdated
  sale {
    id
    paymentMethods {
      id
      percentage
      paymentMethod {
        ...QuotePaymentMethod
      }
    }
  }
  subTotal
}
    ${MinimalUserFragmentDoc}
${MinimalQuoteProductFragmentDoc}
${QuotePaymentMethodFragmentDoc}`;
export const AccountFragmentDoc = gql`
    fragment Account on AccountType {
  ...MinimalAccount
  contacts {
    ...MinimalContact
  }
  activities {
    ...MinimalActivity
  }
  quotes {
    ...MinimalQuote
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
}
    ${MinimalAccountFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalActivityFragmentDoc}
${MinimalQuoteFragmentDoc}
${MinimalUserFragmentDoc}`;
export const ActivityFragmentDoc = gql`
    fragment Activity on ActivityType {
  ...MinimalActivity
  created
  updated
  updatedBy {
    ...MinimalUser
  }
}
    ${MinimalActivityFragmentDoc}
${MinimalUserFragmentDoc}`;
export const ActivityChannelFragmentDoc = gql`
    fragment ActivityChannel on ActivityChannelType {
  id
  name
  order
}
    `;
export const ActivityReasonFragmentDoc = gql`
    fragment ActivityReason on ActivityReasonType {
  id
  name
  order
}
    `;
export const SeasonFragmentDoc = gql`
    fragment Season on SeasonType {
  id
  name
  startDate
  endDate
}
    `;
export const ActivitiesAnalyticsFragmentDoc = gql`
    fragment ActivitiesAnalytics on ActivitiesAnalyticsType {
  season {
    ...Season
  }
  total {
    count
  }
  byChannel {
    label
    count
    percentage
  }
  byReason {
    label
    count
    percentage
  }
  byDate {
    date
    count
  }
}
    ${SeasonFragmentDoc}`;
export const QuotesAnalyticsFragmentDoc = gql`
    fragment QuotesAnalytics on QuotesAnalyticsType {
  season {
    ...Season
  }
  total {
    count
    amount
  }
  byStatus {
    label
    count
    percentage
    amount
  }
  bySuccessChance {
    label
    count
    percentage
    amount
  }
  byPaymentMethod {
    label
    count
    percentage
    amount
  }
  byAccountType {
    label
    count
    percentage
    amount
  }
  byLostReason {
    label
    count
    percentage
    amount
  }
  byDate {
    date
    count
  }
}
    ${SeasonFragmentDoc}`;
export const SalesAmountAnalyticsFragmentDoc = gql`
    fragment SalesAmountAnalytics on SalesAmountAnalyticsType {
  salesTarget
  cumulativeSales {
    date
    amount
    forecastAmount
  }
}
    `;
export const SalesByTypeAnalyticsFragmentDoc = gql`
    fragment SalesByTypeAnalytics on SalesByTypeAnalyticsType {
  bySuccessChance {
    label
    count
    percentage
    amount
  }
  byAccountType {
    label
    count
    percentage
    amount
  }
  amountByPaymentMethod {
    label
    count
    percentage
    amount
  }
  bySuppliesCompany {
    label
    count
    percentage
    amount
  }
  countByPaymentMethod {
    label
    count
    percentage
    amount
  }
  byBillingType {
    label
    count
    percentage
    amount
  }
  productRanking {
    product {
      name
      supplyCompany
    }
    count
    amount
    ranking
  }
  byAccount {
    label
    count
    amount
  }
  byCategory {
    label
    count
    amount
  }
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on ContactType {
  ...MinimalContact
  activities {
    ...MinimalActivity
  }
  quotes {
    ...MinimalQuote
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
}
    ${MinimalContactFragmentDoc}
${MinimalActivityFragmentDoc}
${MinimalQuoteFragmentDoc}
${MinimalUserFragmentDoc}`;
export const ContactOptionFragmentDoc = gql`
    fragment ContactOption on ContactType {
  id
  email
  displayName
  active
  phoneCountryCode
  phone
  mobileCountryCode
  mobilePhone
}
    `;
export const AccountOptionFragmentDoc = gql`
    fragment AccountOption on AccountType {
  id
  name
  legalName
  active
}
    `;
export const ContactThoughAccountFragmentDoc = gql`
    fragment ContactThoughAccount on ContactThoughAccountType {
  contact {
    ...ContactOption
  }
  account {
    ...AccountOption
    taxId
    legalName
    externalId
  }
}
    ${ContactOptionFragmentDoc}
${AccountOptionFragmentDoc}`;
export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationType {
  id
  content
  icon
  isRead
  isArchived
  created
  path
  actionPath
  actionLabel
}
    `;
export const PaymentMethodFragmentDoc = gql`
    fragment PaymentMethod on PaymentMethodType {
  id
  name
  minMultiplier
  maxMultiplier
  allowsFinancing
  defaultFinancingMonths
  defaultInterestRate
  order
  published
  isAnnualInterestRate
}
    `;
export const MinimalProductCategoryFragmentDoc = gql`
    fragment MinimalProductCategory on ProductCategoryType {
  id
  name
}
    `;
export const ProductSuppliesCompanyFragmentDoc = gql`
    fragment ProductSuppliesCompany on ProductSuppliesCompanyType {
  id
  name
  category {
    ...MinimalProductCategory
  }
  order
  published
}
    ${MinimalProductCategoryFragmentDoc}`;
export const UnitFragmentDoc = gql`
    fragment Unit on UnitType {
  id
  name
  symbol
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductType {
  id
  name
  externalId
  description
  created
  updated
  priceUpdated
  suppliesCompany {
    ...ProductSuppliesCompany
  }
  unit {
    ...Unit
  }
  price
  published
}
    ${ProductSuppliesCompanyFragmentDoc}
${UnitFragmentDoc}`;
export const MinimalProductFragmentDoc = gql`
    fragment MinimalProduct on ProductType {
  id
  name
}
    `;
export const ProductCategoryOptionFragmentDoc = gql`
    fragment ProductCategoryOption on ProductCategoryType {
  id
  name
  order
  published
}
    `;
export const ProductCategoryFragmentDoc = gql`
    fragment ProductCategory on ProductCategoryType {
  id
  name
  productSuppliesCompanies {
    ...ProductSuppliesCompany
  }
  order
  published
}
    ${ProductSuppliesCompanyFragmentDoc}`;
export const ProductPriceModifierFragmentDoc = gql`
    fragment ProductPriceModifier on ProductPriceModifierType {
  id
  name
  minSurcharge
  maxSurcharge
  maxWorkflowSurcharge
  minDiscount
  maxDiscount
  maxWorkflowDiscount
  discountHasWorkflowRange
  surchargeHasWorkflowRange
  expirationDate
  categories {
    ...ProductCategory
  }
  order
  published
}
    ${ProductCategoryFragmentDoc}`;
export const DefaultProductCategoryFragmentDoc = gql`
    fragment DefaultProductCategory on DefaultProductCategoryType {
  id
  name
  order
}
    `;
export const DefaultProductSuppliesCompanyFragmentDoc = gql`
    fragment DefaultProductSuppliesCompany on DefaultProductSuppliesCompanyType {
  id
  name
  order
}
    `;
export const ListQuoteFragmentDoc = gql`
    fragment ListQuote on QuoteType {
  id
  displayId
  created
  expirationDate
  statusDisplay
  contact {
    id
    displayName
    accounts {
      id
      name
    }
  }
  account {
    id
    name
  }
  createdBy {
    id
    displayName
  }
  conditionsOutdated
  sale {
    id
  }
  paymentMethods {
    id
    name
    financingMonths
    interestRate
    allowsFinancing
    multiplier
    total
    financingMultiplier
  }
  subTotal
}
    `;
export const ProductVariationFragmentDoc = gql`
    fragment ProductVariation on ProductVariationType {
  id
  name
  multiplier
  categories {
    ...ProductCategory
  }
  order
  published
}
    ${ProductCategoryFragmentDoc}`;
export const QuoteProductPriceModifierFragmentDoc = gql`
    fragment QuoteProductPriceModifier on QuoteProductPriceModifierType {
  id
  name
  minDiscount
  maxDiscount
  maxWorkflowDiscount
  minSurcharge
  maxSurcharge
  maxWorkflowSurcharge
  discountHasWorkflowRange
  surchargeHasWorkflowRange
  expirationDate
  multiplier
  order
}
    `;
export const QuoteProductFragmentDoc = gql`
    fragment QuoteProduct on QuoteProductType {
  id
  name
  externalId
  description
  supplyCompany
  category
  originalProduct {
    id
    published
    price
    suppliesCompany {
      id
      name
      category {
        id
        name
      }
    }
  }
  price
  variation {
    ...ProductVariation
  }
  variationMultiplier
  priceModifiers {
    ...QuoteProductPriceModifier
  }
  quantity
  unitPrice
  totalMultiplier
  unitSymbol
  unitName
  paymentMethod {
    ...QuotePaymentMethod
  }
}
    ${ProductVariationFragmentDoc}
${QuoteProductPriceModifierFragmentDoc}
${QuotePaymentMethodFragmentDoc}`;
export const QuoteTransitionFragmentDoc = gql`
    fragment QuoteTransition on QuoteTransitionType {
  id
  trigger
  sourceState
  destState
  created
  statusDisplay
  status
  notes
  createdBy {
    ...MinimalUser
  }
}
    ${MinimalUserFragmentDoc}`;
export const QuotePrintFragmentDoc = gql`
    fragment QuotePrint on QuoteType {
  id
  displayId
  internalNotes
  clientNotes
  created
  expirationDate
  canConvertToSale
  createdBy {
    displayName
    email
  }
  contact {
    id
    firstName
    lastName
    email
    displayName
    accounts {
      name
      taxId
    }
  }
  account {
    id
    name
    taxId
    owner {
      ...MinimalUser
    }
  }
  paymentMethods {
    ...QuotePaymentMethod
  }
  products {
    ...QuoteProduct
  }
  transitions {
    ...QuoteTransition
  }
  subTotal
}
    ${MinimalUserFragmentDoc}
${QuotePaymentMethodFragmentDoc}
${QuoteProductFragmentDoc}
${QuoteTransitionFragmentDoc}`;
export const QuoteOptionFragmentDoc = gql`
    fragment QuoteOption on QuoteType {
  id
  status
}
    `;
export const MinimalQuotePaymentMethodFragmentDoc = gql`
    fragment MinimalQuotePaymentMethod on QuotePaymentMethodType {
  multiplier
  financingMonths
  interestRate
  allowsFinancing
}
    `;
export const BillingTypeOptionFragmentDoc = gql`
    fragment BillingTypeOption on BillingTypeType {
  id
  name
}
    `;
export const UpdatedQuoteInfoFragmentDoc = gql`
    fragment UpdatedQuoteInfo on UpdatedQuoteInfoType {
  updatedProducts {
    id
    price
    outdatedQuoteProduct {
      id
      name
      supplyCompany
      category
      originalProduct {
        id
        suppliesCompany {
          id
          name
          category {
            ...MinimalProductCategory
          }
        }
      }
      price
      variation {
        ...ProductVariation
      }
      variationMultiplier
      priceModifiers {
        ...QuoteProductPriceModifier
      }
      quantity
    }
  }
}
    ${MinimalProductCategoryFragmentDoc}
${ProductVariationFragmentDoc}
${QuoteProductPriceModifierFragmentDoc}`;
export const SalesTargetFragmentDoc = gql`
    fragment SalesTarget on SalesTargetType {
  id
  season {
    ...Season
  }
  user {
    ...MinimalUser
    role {
      name
    }
    salesTargets {
      amount
      season {
        ...Season
      }
    }
  }
  subtotal
  amount
}
    ${SeasonFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SalesTargetsByUserFragmentDoc = gql`
    fragment SalesTargetsByUser on SalesTargetType {
  id
  user {
    ...MinimalUser
  }
  amount
  season {
    ...Season
  }
}
    ${MinimalUserFragmentDoc}
${SeasonFragmentDoc}`;
export const CurrentUsersSalesTargetsFragmentDoc = gql`
    fragment CurrentUsersSalesTargets on UserType {
  id
  email
  firstName
  lastName
  displayName
  childUsers(includeSelf: $includeSelf) {
    id
    email
  }
  role {
    id
    name
  }
  parentsUsers {
    ...MinimalUser
  }
  salesTargetsAmount
  salesTargetsChildsAmount
  salesTargets {
    id
    season {
      ...Season
    }
  }
}
    ${MinimalUserFragmentDoc}
${SeasonFragmentDoc}`;
export const AggregatedSalesTargetFragmentDoc = gql`
    fragment AggregatedSalesTarget on AggregatedSalesTargetType {
  amount
  season {
    ...Season
  }
}
    ${SeasonFragmentDoc}`;
export const MinimalSalePaymentMethodFragmentDoc = gql`
    fragment MinimalSalePaymentMethod on SalePaymentMethodType {
  id
  sale {
    id
  }
  percentage
  paymentMethod {
    ...QuotePaymentMethod
  }
  total
  subTotal
}
    ${QuotePaymentMethodFragmentDoc}`;
export const SalePdfRecipientFragmentDoc = gql`
    fragment SalePDFRecipient on SalePDFRecipientType {
  id
  email
  statusDisplay
}
    `;
export const SalePdfFragmentDoc = gql`
    fragment SalePDF on SalePDFType {
  id
  file
  created
  slug
  recipients {
    ...SalePDFRecipient
  }
  createdBy {
    ...MinimalUser
  }
}
    ${SalePdfRecipientFragmentDoc}
${MinimalUserFragmentDoc}`;
export const SaleFragmentDoc = gql`
    fragment Sale on SaleType {
  id
  displayId
  displayName
  annuledReason
  annuled
  date
  internalNotes
  clientNotes
  paymentMethods {
    ...MinimalSalePaymentMethod
  }
  quote {
    status
    sale {
      id
    }
    products {
      ...QuoteProduct
    }
    account {
      id
    }
    contact {
      ...MinimalContact
      accounts {
        ...MinimalAccount
      }
    }
  }
  created
  createdBy {
    ...MinimalUser
  }
  pdfs {
    ...SalePDF
  }
}
    ${MinimalSalePaymentMethodFragmentDoc}
${QuoteProductFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalAccountFragmentDoc}
${MinimalUserFragmentDoc}
${SalePdfFragmentDoc}`;
export const SalePaymentMethodFragmentDoc = gql`
    fragment SalePaymentMethod on SalePaymentMethodType {
  id
  sale {
    ...Sale
  }
  percentage
  paymentMethod {
    ...QuotePaymentMethod
  }
  total
  subTotal
}
    ${SaleFragmentDoc}
${QuotePaymentMethodFragmentDoc}`;
export const MinimalSaleFragmentDoc = gql`
    fragment MinimalSale on SaleType {
  id
  displayId
  annuledReason
  annuled
  createdBy {
    ...MinimalUser
  }
  paymentMethods {
    ...MinimalSalePaymentMethod
  }
  quote {
    id
    products {
      id
      price
      quantity
    }
  }
}
    ${MinimalUserFragmentDoc}
${MinimalSalePaymentMethodFragmentDoc}`;
export const SalePrintFragmentDoc = gql`
    fragment SalePrint on SaleType {
  id
  displayId
  displayName
  date
  internalNotes
  clientNotes
  paymentMethods {
    ...MinimalSalePaymentMethod
  }
  quote {
    account {
      id
      name
      taxId
    }
    products {
      ...QuoteProduct
    }
    contact {
      ...MinimalContact
      accounts {
        ...MinimalAccount
      }
    }
  }
  created
  createdBy {
    displayName
    email
  }
}
    ${MinimalSalePaymentMethodFragmentDoc}
${QuoteProductFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalAccountFragmentDoc}`;
export const LostReasonFragmentDoc = gql`
    fragment LostReason on LostReasonType {
  id
  name
  published
  order
}
    `;
export const QuoteSuccessChanceFragmentDoc = gql`
    fragment QuoteSuccessChance on QuoteSuccessChanceType {
  id
  name
  published
  order
}
    `;
export const BillingTypeFragmentDoc = gql`
    fragment BillingType on BillingTypeType {
  id
  name
  order
  published
}
    `;
export const QuotePdfRecipientFragmentDoc = gql`
    fragment QuotePDFRecipient on QuotePDFRecipientType {
  id
  email
  statusDisplay
}
    `;
export const QuotePdfFragmentDoc = gql`
    fragment QuotePDF on QuotePDFType {
  id
  file
  type
  typeDisplay
  mode
  modeDisplay
  created
  slug
  recipients {
    ...QuotePDFRecipient
  }
  createdBy {
    ...MinimalUser
  }
  quoteStatus
}
    ${QuotePdfRecipientFragmentDoc}
${MinimalUserFragmentDoc}`;
export const QuoteFragmentDoc = gql`
    fragment Quote on QuoteType {
  id
  displayId
  status
  expirationDate
  statusDisplay
  reviewStart
  canConvertToSale
  canReviewStart
  internalNotes
  clientNotes
  lostReason {
    ...LostReason
  }
  transitions {
    ...QuoteTransition
  }
  sale {
    id
    paymentMethods {
      id
      percentage
      paymentMethod {
        id
        name
      }
    }
  }
  conditionsOutdated
  successChance {
    ...QuoteSuccessChance
  }
  billingType {
    ...BillingType
  }
  contact {
    ...MinimalContact
  }
  account {
    id
    name
    taxId
    owner {
      ...MinimalUser
    }
  }
  products {
    ...QuoteProduct
  }
  paymentMethods {
    ...QuotePaymentMethod
  }
  pdfs {
    ...QuotePDF
  }
  created
  createdBy {
    ...MinimalUser
  }
  updated
  updatedBy {
    ...MinimalUser
  }
  subTotal
}
    ${LostReasonFragmentDoc}
${QuoteTransitionFragmentDoc}
${QuoteSuccessChanceFragmentDoc}
${BillingTypeFragmentDoc}
${MinimalContactFragmentDoc}
${MinimalUserFragmentDoc}
${QuoteProductFragmentDoc}
${QuotePaymentMethodFragmentDoc}
${QuotePdfFragmentDoc}`;
export const SaleDetailFragmentDoc = gql`
    fragment SaleDetail on SaleType {
  ...Sale
  updated
  updatedBy {
    ...MinimalUser
  }
  paymentMethods {
    ...MinimalSalePaymentMethod
  }
  quote {
    ...Quote
    products {
      ...QuoteProduct
    }
  }
}
    ${SaleFragmentDoc}
${MinimalUserFragmentDoc}
${MinimalSalePaymentMethodFragmentDoc}
${QuoteFragmentDoc}
${QuoteProductFragmentDoc}`;
export const SaleDetailListFragmentDoc = gql`
    fragment SaleDetailList on SaleType {
  id
  displayId
  date
  annuled
  annuledReason
  createdBy {
    ...MinimalUser
  }
  paymentMethods {
    id
    percentage
    total
    subTotal
  }
  quote {
    contact {
      ...MinimalContact
    }
    account {
      id
      name
      taxId
      owner {
        ...MinimalUser
      }
    }
  }
}
    ${MinimalUserFragmentDoc}
${MinimalContactFragmentDoc}`;
export const FeatureTutorialFragmentDoc = gql`
    fragment FeatureTutorial on FeatureTutorialType {
  id
  name
  codename
  steps {
    content
  }
}
    `;
export const JobFragmentDoc = gql`
    fragment Job on JobType {
  id
  status
  result
}
    `;
export const DownloadFragmentDoc = gql`
    fragment Download on DownloadType {
  contentType
  data
  filename
}
    `;
export const ExportJobFragmentDoc = gql`
    fragment ExportJob on ExportJobType {
  id
  status
  result {
    ...Download
  }
}
    ${DownloadFragmentDoc}`;
export const WorkflowFragmentDoc = gql`
    fragment Workflow on WorkflowType {
  id
  schema
  initial
  saleState
  lostState
  approveState
  states
  initialReview
  exceptionState
  deleted
  expiredState
  saleCanceled
}
    `;
export const TenantFragmentDoc = gql`
    fragment Tenant on TenantType {
  id
  name
  subdomain
  logo
  pdfLogo
  taxIdFormat
  quotePdfDisclaimer
  salePdfDisclaimer
  currency
  workflow {
    ...Workflow
  }
  quoteExpirationDays
  maxAllowedDaysConvertSale
  currencyLabel
  isCurrencyShort
}
    ${WorkflowFragmentDoc}`;
export const PdfConfigFragmentDoc = gql`
    fragment PdfConfig on PdfConfigType {
  key
  categoryKey
  type
  value
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserType {
  id
  email
  firstName
  lastName
  displayName
  language
  isActive
  isStaff
  isTenantStaff
  dateJoined
  lastLogin
  phone
  phoneCountryCode
  hasUsablePassword
  emailNotifications
  role {
    id
    name
  }
  parentsUsers {
    usernodeSet {
      id
    }
    ...MinimalUser
  }
  organization {
    id
    name
  }
  permissions {
    createQuotes
    viewQuotes
    viewSales
    annulSales
    viewAccounts
    viewContacts
    viewActivities
    assignAccountOwner
    requestQuoteReview
    convertQuoteToSale
    viewAnalytics
    approveException
    notificateQuoteLost
    notificateQuoteInSale
    assignActivity
    notificateSaleAnnuled
  }
  usernodeSet {
    id
  }
}
    ${MinimalUserFragmentDoc}`;
export const PermissionFragmentDoc = gql`
    fragment Permission on PermissionType {
  id
  name
  codename
  displayName
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on RoleType {
  id
  name
  permissions {
    ...Permission
  }
}
    ${PermissionFragmentDoc}`;
export const RoleOptionFragmentDoc = gql`
    fragment RoleOption on RoleType {
  id
  name
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on OrganizationType {
  id
  name
}
    `;
export const TreeUserFragmentDoc = gql`
    fragment TreeUser on UserType {
  id
  displayName
  email
  parentsUsers {
    id
    displayName
  }
  isStaff
  role {
    id
    name
  }
}
    `;
export const GetAccountsDocument = gql`
    query getAccounts($options: AccountListOptionsInputType) {
  accounts(options: $options) {
    items {
      ...MinimalAccount
    }
    count
  }
}
    ${MinimalAccountFragmentDoc}`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export function refetchGetAccountsQuery(variables?: GetAccountsQueryVariables) {
      return { query: GetAccountsDocument, variables: variables }
    }
export const GetAccountOptionsDocument = gql`
    query getAccountOptions($options: AccountListOptionsInputType, $filterByUser: UUID) {
  accounts(options: $options, filterByUser: $filterByUser) {
    items {
      ...AccountOption
    }
  }
}
    ${AccountOptionFragmentDoc}`;

/**
 * __useGetAccountOptionsQuery__
 *
 * To run a query within a React component, call `useGetAccountOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      filterByUser: // value for 'filterByUser'
 *   },
 * });
 */
export function useGetAccountOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>) {
        return Apollo.useQuery<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>(GetAccountOptionsDocument, baseOptions);
      }
export function useGetAccountOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>(GetAccountOptionsDocument, baseOptions);
        }
export type GetAccountOptionsQueryHookResult = ReturnType<typeof useGetAccountOptionsQuery>;
export type GetAccountOptionsLazyQueryHookResult = ReturnType<typeof useGetAccountOptionsLazyQuery>;
export type GetAccountOptionsQueryResult = Apollo.QueryResult<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>;
export function refetchGetAccountOptionsQuery(variables?: GetAccountOptionsQueryVariables) {
      return { query: GetAccountOptionsDocument, variables: variables }
    }
export const GetAccountDocument = gql`
    query getAccount($id: UUID!) {
  account(id: $id) {
    ...Account
  }
}
    ${AccountFragmentDoc}`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export function refetchGetAccountQuery(variables?: GetAccountQueryVariables) {
      return { query: GetAccountDocument, variables: variables }
    }
export const GetAccountTypeDocument = gql`
    query getAccountType($id: UUID!) {
  accountType(id: $id) {
    ...AccountType
  }
}
    ${AccountTypeFragmentDoc}`;

/**
 * __useGetAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountTypeQuery, GetAccountTypeQueryVariables>) {
        return Apollo.useQuery<GetAccountTypeQuery, GetAccountTypeQueryVariables>(GetAccountTypeDocument, baseOptions);
      }
export function useGetAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTypeQuery, GetAccountTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountTypeQuery, GetAccountTypeQueryVariables>(GetAccountTypeDocument, baseOptions);
        }
export type GetAccountTypeQueryHookResult = ReturnType<typeof useGetAccountTypeQuery>;
export type GetAccountTypeLazyQueryHookResult = ReturnType<typeof useGetAccountTypeLazyQuery>;
export type GetAccountTypeQueryResult = Apollo.QueryResult<GetAccountTypeQuery, GetAccountTypeQueryVariables>;
export function refetchGetAccountTypeQuery(variables?: GetAccountTypeQueryVariables) {
      return { query: GetAccountTypeDocument, variables: variables }
    }
export const GetAccountTypesDocument = gql`
    query getAccountTypes($options: AccountTypeListOptionsInputType, $includeUnpublished: Boolean) {
  accountTypes(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...AccountType
    }
    count
  }
}
    ${AccountTypeFragmentDoc}`;

/**
 * __useGetAccountTypesQuery__
 *
 * To run a query within a React component, call `useGetAccountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTypesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetAccountTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>) {
        return Apollo.useQuery<GetAccountTypesQuery, GetAccountTypesQueryVariables>(GetAccountTypesDocument, baseOptions);
      }
export function useGetAccountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountTypesQuery, GetAccountTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountTypesQuery, GetAccountTypesQueryVariables>(GetAccountTypesDocument, baseOptions);
        }
export type GetAccountTypesQueryHookResult = ReturnType<typeof useGetAccountTypesQuery>;
export type GetAccountTypesLazyQueryHookResult = ReturnType<typeof useGetAccountTypesLazyQuery>;
export type GetAccountTypesQueryResult = Apollo.QueryResult<GetAccountTypesQuery, GetAccountTypesQueryVariables>;
export function refetchGetAccountTypesQuery(variables?: GetAccountTypesQueryVariables) {
      return { query: GetAccountTypesDocument, variables: variables }
    }
export const GetAccountRegionDocument = gql`
    query getAccountRegion($id: UUID!) {
  accountRegion(id: $id) {
    ...AccountRegion
  }
}
    ${AccountRegionFragmentDoc}`;

/**
 * __useGetAccountRegionQuery__
 *
 * To run a query within a React component, call `useGetAccountRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRegionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountRegionQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountRegionQuery, GetAccountRegionQueryVariables>) {
        return Apollo.useQuery<GetAccountRegionQuery, GetAccountRegionQueryVariables>(GetAccountRegionDocument, baseOptions);
      }
export function useGetAccountRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRegionQuery, GetAccountRegionQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountRegionQuery, GetAccountRegionQueryVariables>(GetAccountRegionDocument, baseOptions);
        }
export type GetAccountRegionQueryHookResult = ReturnType<typeof useGetAccountRegionQuery>;
export type GetAccountRegionLazyQueryHookResult = ReturnType<typeof useGetAccountRegionLazyQuery>;
export type GetAccountRegionQueryResult = Apollo.QueryResult<GetAccountRegionQuery, GetAccountRegionQueryVariables>;
export function refetchGetAccountRegionQuery(variables?: GetAccountRegionQueryVariables) {
      return { query: GetAccountRegionDocument, variables: variables }
    }
export const GetAccountRegionsDocument = gql`
    query getAccountRegions($options: AccountRegionListOptionsInputType, $includeUnpublished: Boolean) {
  accountRegions(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...AccountRegion
    }
    count
  }
}
    ${AccountRegionFragmentDoc}`;

/**
 * __useGetAccountRegionsQuery__
 *
 * To run a query within a React component, call `useGetAccountRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRegionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetAccountRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>) {
        return Apollo.useQuery<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>(GetAccountRegionsDocument, baseOptions);
      }
export function useGetAccountRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>(GetAccountRegionsDocument, baseOptions);
        }
export type GetAccountRegionsQueryHookResult = ReturnType<typeof useGetAccountRegionsQuery>;
export type GetAccountRegionsLazyQueryHookResult = ReturnType<typeof useGetAccountRegionsLazyQuery>;
export type GetAccountRegionsQueryResult = Apollo.QueryResult<GetAccountRegionsQuery, GetAccountRegionsQueryVariables>;
export function refetchGetAccountRegionsQuery(variables?: GetAccountRegionsQueryVariables) {
      return { query: GetAccountRegionsDocument, variables: variables }
    }
export const CreateAccountDocument = gql`
    mutation createAccount($input: CreateAccountMutationInput!) {
  createAccount(input: $input) {
    account {
      ...Account
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountFragmentDoc}`;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, baseOptions);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($input: UpdateAccountMutationInput!) {
  updateAccount(input: $input) {
    account {
      ...Account
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountFragmentDoc}`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const ActiveTogglerAccountDocument = gql`
    mutation activeTogglerAccount($input: ActiveTogglerAccountMutationInput!) {
  activeTogglerAccount(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ActiveTogglerAccountMutationFn = Apollo.MutationFunction<ActiveTogglerAccountMutation, ActiveTogglerAccountMutationVariables>;

/**
 * __useActiveTogglerAccountMutation__
 *
 * To run a mutation, you first call `useActiveTogglerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveTogglerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeTogglerAccountMutation, { data, loading, error }] = useActiveTogglerAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveTogglerAccountMutation(baseOptions?: Apollo.MutationHookOptions<ActiveTogglerAccountMutation, ActiveTogglerAccountMutationVariables>) {
        return Apollo.useMutation<ActiveTogglerAccountMutation, ActiveTogglerAccountMutationVariables>(ActiveTogglerAccountDocument, baseOptions);
      }
export type ActiveTogglerAccountMutationHookResult = ReturnType<typeof useActiveTogglerAccountMutation>;
export type ActiveTogglerAccountMutationResult = Apollo.MutationResult<ActiveTogglerAccountMutation>;
export type ActiveTogglerAccountMutationOptions = Apollo.BaseMutationOptions<ActiveTogglerAccountMutation, ActiveTogglerAccountMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($input: DeleteAccountMutationInput!) {
  deleteAccount(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const CreateAccountTypeDocument = gql`
    mutation createAccountType($input: CreateAccountTypeMutationInput!) {
  createAccountType(input: $input) {
    accountType {
      ...AccountType
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountTypeFragmentDoc}`;
export type CreateAccountTypeMutationFn = Apollo.MutationFunction<CreateAccountTypeMutation, CreateAccountTypeMutationVariables>;

/**
 * __useCreateAccountTypeMutation__
 *
 * To run a mutation, you first call `useCreateAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountTypeMutation, { data, loading, error }] = useCreateAccountTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountTypeMutation, CreateAccountTypeMutationVariables>) {
        return Apollo.useMutation<CreateAccountTypeMutation, CreateAccountTypeMutationVariables>(CreateAccountTypeDocument, baseOptions);
      }
export type CreateAccountTypeMutationHookResult = ReturnType<typeof useCreateAccountTypeMutation>;
export type CreateAccountTypeMutationResult = Apollo.MutationResult<CreateAccountTypeMutation>;
export type CreateAccountTypeMutationOptions = Apollo.BaseMutationOptions<CreateAccountTypeMutation, CreateAccountTypeMutationVariables>;
export const UpdateAccountTypeDocument = gql`
    mutation updateAccountType($input: UpdateAccountTypeMutationInput!) {
  updateAccountType(input: $input) {
    accountType {
      ...AccountType
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountTypeFragmentDoc}`;
export type UpdateAccountTypeMutationFn = Apollo.MutationFunction<UpdateAccountTypeMutation, UpdateAccountTypeMutationVariables>;

/**
 * __useUpdateAccountTypeMutation__
 *
 * To run a mutation, you first call `useUpdateAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountTypeMutation, { data, loading, error }] = useUpdateAccountTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountTypeMutation, UpdateAccountTypeMutationVariables>) {
        return Apollo.useMutation<UpdateAccountTypeMutation, UpdateAccountTypeMutationVariables>(UpdateAccountTypeDocument, baseOptions);
      }
export type UpdateAccountTypeMutationHookResult = ReturnType<typeof useUpdateAccountTypeMutation>;
export type UpdateAccountTypeMutationResult = Apollo.MutationResult<UpdateAccountTypeMutation>;
export type UpdateAccountTypeMutationOptions = Apollo.BaseMutationOptions<UpdateAccountTypeMutation, UpdateAccountTypeMutationVariables>;
export const DeleteAccountTypeDocument = gql`
    mutation deleteAccountType($input: DeleteAccountTypeMutationInput!) {
  deleteAccountType(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteAccountTypeMutationFn = Apollo.MutationFunction<DeleteAccountTypeMutation, DeleteAccountTypeMutationVariables>;

/**
 * __useDeleteAccountTypeMutation__
 *
 * To run a mutation, you first call `useDeleteAccountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountTypeMutation, { data, loading, error }] = useDeleteAccountTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountTypeMutation, DeleteAccountTypeMutationVariables>) {
        return Apollo.useMutation<DeleteAccountTypeMutation, DeleteAccountTypeMutationVariables>(DeleteAccountTypeDocument, baseOptions);
      }
export type DeleteAccountTypeMutationHookResult = ReturnType<typeof useDeleteAccountTypeMutation>;
export type DeleteAccountTypeMutationResult = Apollo.MutationResult<DeleteAccountTypeMutation>;
export type DeleteAccountTypeMutationOptions = Apollo.BaseMutationOptions<DeleteAccountTypeMutation, DeleteAccountTypeMutationVariables>;
export const CreateAccountRegionDocument = gql`
    mutation createAccountRegion($input: CreateAccountRegionMutationInput!) {
  createAccountRegion(input: $input) {
    accountRegion {
      ...AccountRegion
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountRegionFragmentDoc}`;
export type CreateAccountRegionMutationFn = Apollo.MutationFunction<CreateAccountRegionMutation, CreateAccountRegionMutationVariables>;

/**
 * __useCreateAccountRegionMutation__
 *
 * To run a mutation, you first call `useCreateAccountRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountRegionMutation, { data, loading, error }] = useCreateAccountRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountRegionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountRegionMutation, CreateAccountRegionMutationVariables>) {
        return Apollo.useMutation<CreateAccountRegionMutation, CreateAccountRegionMutationVariables>(CreateAccountRegionDocument, baseOptions);
      }
export type CreateAccountRegionMutationHookResult = ReturnType<typeof useCreateAccountRegionMutation>;
export type CreateAccountRegionMutationResult = Apollo.MutationResult<CreateAccountRegionMutation>;
export type CreateAccountRegionMutationOptions = Apollo.BaseMutationOptions<CreateAccountRegionMutation, CreateAccountRegionMutationVariables>;
export const UpdateAccountRegionDocument = gql`
    mutation updateAccountRegion($input: UpdateAccountRegionMutationInput!) {
  updateAccountRegion(input: $input) {
    accountRegion {
      ...AccountRegion
    }
    errors {
      field
      messages
    }
  }
}
    ${AccountRegionFragmentDoc}`;
export type UpdateAccountRegionMutationFn = Apollo.MutationFunction<UpdateAccountRegionMutation, UpdateAccountRegionMutationVariables>;

/**
 * __useUpdateAccountRegionMutation__
 *
 * To run a mutation, you first call `useUpdateAccountRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountRegionMutation, { data, loading, error }] = useUpdateAccountRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountRegionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountRegionMutation, UpdateAccountRegionMutationVariables>) {
        return Apollo.useMutation<UpdateAccountRegionMutation, UpdateAccountRegionMutationVariables>(UpdateAccountRegionDocument, baseOptions);
      }
export type UpdateAccountRegionMutationHookResult = ReturnType<typeof useUpdateAccountRegionMutation>;
export type UpdateAccountRegionMutationResult = Apollo.MutationResult<UpdateAccountRegionMutation>;
export type UpdateAccountRegionMutationOptions = Apollo.BaseMutationOptions<UpdateAccountRegionMutation, UpdateAccountRegionMutationVariables>;
export const DeleteAccountRegionDocument = gql`
    mutation deleteAccountRegion($input: DeleteAccountRegionMutationInput!) {
  deleteAccountRegion(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteAccountRegionMutationFn = Apollo.MutationFunction<DeleteAccountRegionMutation, DeleteAccountRegionMutationVariables>;

/**
 * __useDeleteAccountRegionMutation__
 *
 * To run a mutation, you first call `useDeleteAccountRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountRegionMutation, { data, loading, error }] = useDeleteAccountRegionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountRegionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountRegionMutation, DeleteAccountRegionMutationVariables>) {
        return Apollo.useMutation<DeleteAccountRegionMutation, DeleteAccountRegionMutationVariables>(DeleteAccountRegionDocument, baseOptions);
      }
export type DeleteAccountRegionMutationHookResult = ReturnType<typeof useDeleteAccountRegionMutation>;
export type DeleteAccountRegionMutationResult = Apollo.MutationResult<DeleteAccountRegionMutation>;
export type DeleteAccountRegionMutationOptions = Apollo.BaseMutationOptions<DeleteAccountRegionMutation, DeleteAccountRegionMutationVariables>;
export const ImportAccountsDocument = gql`
    mutation importAccounts($input: ImportAccountsMutationInput!) {
  importAccounts(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ImportAccountsMutationFn = Apollo.MutationFunction<ImportAccountsMutation, ImportAccountsMutationVariables>;

/**
 * __useImportAccountsMutation__
 *
 * To run a mutation, you first call `useImportAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importAccountsMutation, { data, loading, error }] = useImportAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportAccountsMutation(baseOptions?: Apollo.MutationHookOptions<ImportAccountsMutation, ImportAccountsMutationVariables>) {
        return Apollo.useMutation<ImportAccountsMutation, ImportAccountsMutationVariables>(ImportAccountsDocument, baseOptions);
      }
export type ImportAccountsMutationHookResult = ReturnType<typeof useImportAccountsMutation>;
export type ImportAccountsMutationResult = Apollo.MutationResult<ImportAccountsMutation>;
export type ImportAccountsMutationOptions = Apollo.BaseMutationOptions<ImportAccountsMutation, ImportAccountsMutationVariables>;
export const GetActivitiesDocument = gql`
    query getActivities($options: ActivityListOptionsInputType) {
  activities(options: $options) {
    items {
      ...MinimalActivity
    }
    count
  }
}
    ${MinimalActivityFragmentDoc}`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
        return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, baseOptions);
      }
export function useGetActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
          return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, baseOptions);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export type GetActivitiesQueryResult = Apollo.QueryResult<GetActivitiesQuery, GetActivitiesQueryVariables>;
export function refetchGetActivitiesQuery(variables?: GetActivitiesQueryVariables) {
      return { query: GetActivitiesDocument, variables: variables }
    }
export const GetActivityDocument = gql`
    query getActivity($id: UUID!) {
  activity(id: $id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useGetActivityQuery__
 *
 * To run a query within a React component, call `useGetActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
        return Apollo.useQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, baseOptions);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, baseOptions);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<GetActivityQuery, GetActivityQueryVariables>;
export function refetchGetActivityQuery(variables?: GetActivityQueryVariables) {
      return { query: GetActivityDocument, variables: variables }
    }
export const GetActivityChannelDocument = gql`
    query getActivityChannel($id: UUID!) {
  activityChannel(id: $id) {
    ...ActivityChannel
  }
}
    ${ActivityChannelFragmentDoc}`;

/**
 * __useGetActivityChannelQuery__
 *
 * To run a query within a React component, call `useGetActivityChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityChannelQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityChannelQuery, GetActivityChannelQueryVariables>) {
        return Apollo.useQuery<GetActivityChannelQuery, GetActivityChannelQueryVariables>(GetActivityChannelDocument, baseOptions);
      }
export function useGetActivityChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityChannelQuery, GetActivityChannelQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityChannelQuery, GetActivityChannelQueryVariables>(GetActivityChannelDocument, baseOptions);
        }
export type GetActivityChannelQueryHookResult = ReturnType<typeof useGetActivityChannelQuery>;
export type GetActivityChannelLazyQueryHookResult = ReturnType<typeof useGetActivityChannelLazyQuery>;
export type GetActivityChannelQueryResult = Apollo.QueryResult<GetActivityChannelQuery, GetActivityChannelQueryVariables>;
export function refetchGetActivityChannelQuery(variables?: GetActivityChannelQueryVariables) {
      return { query: GetActivityChannelDocument, variables: variables }
    }
export const GetActivityChannelsDocument = gql`
    query getActivityChannels($options: ActivityChannelListOptionsInputType) {
  activityChannels(options: $options) {
    items {
      ...ActivityChannel
    }
    count
  }
}
    ${ActivityChannelFragmentDoc}`;

/**
 * __useGetActivityChannelsQuery__
 *
 * To run a query within a React component, call `useGetActivityChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityChannelsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetActivityChannelsQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityChannelsQuery, GetActivityChannelsQueryVariables>) {
        return Apollo.useQuery<GetActivityChannelsQuery, GetActivityChannelsQueryVariables>(GetActivityChannelsDocument, baseOptions);
      }
export function useGetActivityChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityChannelsQuery, GetActivityChannelsQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityChannelsQuery, GetActivityChannelsQueryVariables>(GetActivityChannelsDocument, baseOptions);
        }
export type GetActivityChannelsQueryHookResult = ReturnType<typeof useGetActivityChannelsQuery>;
export type GetActivityChannelsLazyQueryHookResult = ReturnType<typeof useGetActivityChannelsLazyQuery>;
export type GetActivityChannelsQueryResult = Apollo.QueryResult<GetActivityChannelsQuery, GetActivityChannelsQueryVariables>;
export function refetchGetActivityChannelsQuery(variables?: GetActivityChannelsQueryVariables) {
      return { query: GetActivityChannelsDocument, variables: variables }
    }
export const GetActivityReasonDocument = gql`
    query getActivityReason($id: UUID!) {
  activityReason(id: $id) {
    ...ActivityReason
  }
}
    ${ActivityReasonFragmentDoc}`;

/**
 * __useGetActivityReasonQuery__
 *
 * To run a query within a React component, call `useGetActivityReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityReasonQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityReasonQuery, GetActivityReasonQueryVariables>) {
        return Apollo.useQuery<GetActivityReasonQuery, GetActivityReasonQueryVariables>(GetActivityReasonDocument, baseOptions);
      }
export function useGetActivityReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityReasonQuery, GetActivityReasonQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityReasonQuery, GetActivityReasonQueryVariables>(GetActivityReasonDocument, baseOptions);
        }
export type GetActivityReasonQueryHookResult = ReturnType<typeof useGetActivityReasonQuery>;
export type GetActivityReasonLazyQueryHookResult = ReturnType<typeof useGetActivityReasonLazyQuery>;
export type GetActivityReasonQueryResult = Apollo.QueryResult<GetActivityReasonQuery, GetActivityReasonQueryVariables>;
export function refetchGetActivityReasonQuery(variables?: GetActivityReasonQueryVariables) {
      return { query: GetActivityReasonDocument, variables: variables }
    }
export const GetActivityReasonsDocument = gql`
    query getActivityReasons($options: ActivityReasonListOptionsInputType) {
  activityReasons(options: $options) {
    items {
      ...ActivityReason
    }
    count
  }
}
    ${ActivityReasonFragmentDoc}`;

/**
 * __useGetActivityReasonsQuery__
 *
 * To run a query within a React component, call `useGetActivityReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityReasonsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetActivityReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityReasonsQuery, GetActivityReasonsQueryVariables>) {
        return Apollo.useQuery<GetActivityReasonsQuery, GetActivityReasonsQueryVariables>(GetActivityReasonsDocument, baseOptions);
      }
export function useGetActivityReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityReasonsQuery, GetActivityReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GetActivityReasonsQuery, GetActivityReasonsQueryVariables>(GetActivityReasonsDocument, baseOptions);
        }
export type GetActivityReasonsQueryHookResult = ReturnType<typeof useGetActivityReasonsQuery>;
export type GetActivityReasonsLazyQueryHookResult = ReturnType<typeof useGetActivityReasonsLazyQuery>;
export type GetActivityReasonsQueryResult = Apollo.QueryResult<GetActivityReasonsQuery, GetActivityReasonsQueryVariables>;
export function refetchGetActivityReasonsQuery(variables?: GetActivityReasonsQueryVariables) {
      return { query: GetActivityReasonsDocument, variables: variables }
    }
export const CreateActivityReasonDocument = gql`
    mutation createActivityReason($input: CreateActivityReasonMutationInput!) {
  createActivityReason(input: $input) {
    activityReason {
      ...ActivityReason
    }
    errors {
      field
      messages
    }
  }
}
    ${ActivityReasonFragmentDoc}`;
export type CreateActivityReasonMutationFn = Apollo.MutationFunction<CreateActivityReasonMutation, CreateActivityReasonMutationVariables>;

/**
 * __useCreateActivityReasonMutation__
 *
 * To run a mutation, you first call `useCreateActivityReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityReasonMutation, { data, loading, error }] = useCreateActivityReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityReasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityReasonMutation, CreateActivityReasonMutationVariables>) {
        return Apollo.useMutation<CreateActivityReasonMutation, CreateActivityReasonMutationVariables>(CreateActivityReasonDocument, baseOptions);
      }
export type CreateActivityReasonMutationHookResult = ReturnType<typeof useCreateActivityReasonMutation>;
export type CreateActivityReasonMutationResult = Apollo.MutationResult<CreateActivityReasonMutation>;
export type CreateActivityReasonMutationOptions = Apollo.BaseMutationOptions<CreateActivityReasonMutation, CreateActivityReasonMutationVariables>;
export const UpdateActivityReasonDocument = gql`
    mutation updateActivityReason($input: UpdateActivityReasonMutationInput!) {
  updateActivityReason(input: $input) {
    activityReason {
      ...ActivityReason
    }
    errors {
      field
      messages
    }
  }
}
    ${ActivityReasonFragmentDoc}`;
export type UpdateActivityReasonMutationFn = Apollo.MutationFunction<UpdateActivityReasonMutation, UpdateActivityReasonMutationVariables>;

/**
 * __useUpdateActivityReasonMutation__
 *
 * To run a mutation, you first call `useUpdateActivityReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityReasonMutation, { data, loading, error }] = useUpdateActivityReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityReasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityReasonMutation, UpdateActivityReasonMutationVariables>) {
        return Apollo.useMutation<UpdateActivityReasonMutation, UpdateActivityReasonMutationVariables>(UpdateActivityReasonDocument, baseOptions);
      }
export type UpdateActivityReasonMutationHookResult = ReturnType<typeof useUpdateActivityReasonMutation>;
export type UpdateActivityReasonMutationResult = Apollo.MutationResult<UpdateActivityReasonMutation>;
export type UpdateActivityReasonMutationOptions = Apollo.BaseMutationOptions<UpdateActivityReasonMutation, UpdateActivityReasonMutationVariables>;
export const DeleteActivityReasonDocument = gql`
    mutation deleteActivityReason($input: DeleteActivityReasonMutationInput!) {
  deleteActivityReason(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteActivityReasonMutationFn = Apollo.MutationFunction<DeleteActivityReasonMutation, DeleteActivityReasonMutationVariables>;

/**
 * __useDeleteActivityReasonMutation__
 *
 * To run a mutation, you first call `useDeleteActivityReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityReasonMutation, { data, loading, error }] = useDeleteActivityReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActivityReasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityReasonMutation, DeleteActivityReasonMutationVariables>) {
        return Apollo.useMutation<DeleteActivityReasonMutation, DeleteActivityReasonMutationVariables>(DeleteActivityReasonDocument, baseOptions);
      }
export type DeleteActivityReasonMutationHookResult = ReturnType<typeof useDeleteActivityReasonMutation>;
export type DeleteActivityReasonMutationResult = Apollo.MutationResult<DeleteActivityReasonMutation>;
export type DeleteActivityReasonMutationOptions = Apollo.BaseMutationOptions<DeleteActivityReasonMutation, DeleteActivityReasonMutationVariables>;
export const CreateActivityDocument = gql`
    mutation createActivity($input: CreateActivityMutationInput!) {
  createActivity(input: $input) {
    activity {
      ...Activity
    }
    errors {
      field
      messages
    }
  }
}
    ${ActivityFragmentDoc}`;
export type CreateActivityMutationFn = Apollo.MutationFunction<CreateActivityMutation, CreateActivityMutationVariables>;

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityMutation, CreateActivityMutationVariables>) {
        return Apollo.useMutation<CreateActivityMutation, CreateActivityMutationVariables>(CreateActivityDocument, baseOptions);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = Apollo.MutationResult<CreateActivityMutation>;
export type CreateActivityMutationOptions = Apollo.BaseMutationOptions<CreateActivityMutation, CreateActivityMutationVariables>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($input: UpdateActivityMutationInput!) {
  updateActivity(input: $input) {
    activity {
      ...Activity
    }
    errors {
      field
      messages
    }
  }
}
    ${ActivityFragmentDoc}`;
export type UpdateActivityMutationFn = Apollo.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, baseOptions);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<UpdateActivityMutation, UpdateActivityMutationVariables>;
export const DeleteActivityDocument = gql`
    mutation deleteActivity($input: DeleteActivityMutationInput!) {
  deleteActivity(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteActivityMutationFn = Apollo.MutationFunction<DeleteActivityMutation, DeleteActivityMutationVariables>;

/**
 * __useDeleteActivityMutation__
 *
 * To run a mutation, you first call `useDeleteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityMutation, { data, loading, error }] = useDeleteActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>) {
        return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DeleteActivityDocument, baseOptions);
      }
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export type DeleteActivityMutationOptions = Apollo.BaseMutationOptions<DeleteActivityMutation, DeleteActivityMutationVariables>;
export const CreateActivityChannelDocument = gql`
    mutation createActivityChannel($input: CreateActivityChannelMutationInput!) {
  createActivityChannel(input: $input) {
    activityChannel {
      ...ActivityChannel
    }
    errors {
      field
      messages
    }
  }
}
    ${ActivityChannelFragmentDoc}`;
export type CreateActivityChannelMutationFn = Apollo.MutationFunction<CreateActivityChannelMutation, CreateActivityChannelMutationVariables>;

/**
 * __useCreateActivityChannelMutation__
 *
 * To run a mutation, you first call `useCreateActivityChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityChannelMutation, { data, loading, error }] = useCreateActivityChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityChannelMutation, CreateActivityChannelMutationVariables>) {
        return Apollo.useMutation<CreateActivityChannelMutation, CreateActivityChannelMutationVariables>(CreateActivityChannelDocument, baseOptions);
      }
export type CreateActivityChannelMutationHookResult = ReturnType<typeof useCreateActivityChannelMutation>;
export type CreateActivityChannelMutationResult = Apollo.MutationResult<CreateActivityChannelMutation>;
export type CreateActivityChannelMutationOptions = Apollo.BaseMutationOptions<CreateActivityChannelMutation, CreateActivityChannelMutationVariables>;
export const UpdateActivityChannelDocument = gql`
    mutation updateActivityChannel($input: UpdateActivityChannelMutationInput!) {
  updateActivityChannel(input: $input) {
    activityChannel {
      ...ActivityChannel
    }
    errors {
      field
      messages
    }
  }
}
    ${ActivityChannelFragmentDoc}`;
export type UpdateActivityChannelMutationFn = Apollo.MutationFunction<UpdateActivityChannelMutation, UpdateActivityChannelMutationVariables>;

/**
 * __useUpdateActivityChannelMutation__
 *
 * To run a mutation, you first call `useUpdateActivityChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityChannelMutation, { data, loading, error }] = useUpdateActivityChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityChannelMutation, UpdateActivityChannelMutationVariables>) {
        return Apollo.useMutation<UpdateActivityChannelMutation, UpdateActivityChannelMutationVariables>(UpdateActivityChannelDocument, baseOptions);
      }
export type UpdateActivityChannelMutationHookResult = ReturnType<typeof useUpdateActivityChannelMutation>;
export type UpdateActivityChannelMutationResult = Apollo.MutationResult<UpdateActivityChannelMutation>;
export type UpdateActivityChannelMutationOptions = Apollo.BaseMutationOptions<UpdateActivityChannelMutation, UpdateActivityChannelMutationVariables>;
export const DeleteActivityChannelDocument = gql`
    mutation deleteActivityChannel($input: DeleteActivityChannelMutationInput!) {
  deleteActivityChannel(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteActivityChannelMutationFn = Apollo.MutationFunction<DeleteActivityChannelMutation, DeleteActivityChannelMutationVariables>;

/**
 * __useDeleteActivityChannelMutation__
 *
 * To run a mutation, you first call `useDeleteActivityChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityChannelMutation, { data, loading, error }] = useDeleteActivityChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActivityChannelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityChannelMutation, DeleteActivityChannelMutationVariables>) {
        return Apollo.useMutation<DeleteActivityChannelMutation, DeleteActivityChannelMutationVariables>(DeleteActivityChannelDocument, baseOptions);
      }
export type DeleteActivityChannelMutationHookResult = ReturnType<typeof useDeleteActivityChannelMutation>;
export type DeleteActivityChannelMutationResult = Apollo.MutationResult<DeleteActivityChannelMutation>;
export type DeleteActivityChannelMutationOptions = Apollo.BaseMutationOptions<DeleteActivityChannelMutation, DeleteActivityChannelMutationVariables>;
export const GetSalesAmountAnalyticsDocument = gql`
    query getSalesAmountAnalytics($filters: SalesAnalyticsFiltersInput!) {
  salesAmountAnalytics(filters: $filters) {
    ...SalesAmountAnalytics
  }
}
    ${SalesAmountAnalyticsFragmentDoc}`;

/**
 * __useGetSalesAmountAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetSalesAmountAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesAmountAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesAmountAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSalesAmountAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesAmountAnalyticsQuery, GetSalesAmountAnalyticsQueryVariables>) {
        return Apollo.useQuery<GetSalesAmountAnalyticsQuery, GetSalesAmountAnalyticsQueryVariables>(GetSalesAmountAnalyticsDocument, baseOptions);
      }
export function useGetSalesAmountAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesAmountAnalyticsQuery, GetSalesAmountAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesAmountAnalyticsQuery, GetSalesAmountAnalyticsQueryVariables>(GetSalesAmountAnalyticsDocument, baseOptions);
        }
export type GetSalesAmountAnalyticsQueryHookResult = ReturnType<typeof useGetSalesAmountAnalyticsQuery>;
export type GetSalesAmountAnalyticsLazyQueryHookResult = ReturnType<typeof useGetSalesAmountAnalyticsLazyQuery>;
export type GetSalesAmountAnalyticsQueryResult = Apollo.QueryResult<GetSalesAmountAnalyticsQuery, GetSalesAmountAnalyticsQueryVariables>;
export function refetchGetSalesAmountAnalyticsQuery(variables?: GetSalesAmountAnalyticsQueryVariables) {
      return { query: GetSalesAmountAnalyticsDocument, variables: variables }
    }
export const GetSalesByTypeAnalyticsDocument = gql`
    query getSalesByTypeAnalytics($filters: SalesAnalyticsFiltersInput!) {
  salesByTypeAnalytics(filters: $filters) {
    ...SalesByTypeAnalytics
  }
}
    ${SalesByTypeAnalyticsFragmentDoc}`;

/**
 * __useGetSalesByTypeAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetSalesByTypeAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesByTypeAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesByTypeAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSalesByTypeAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesByTypeAnalyticsQuery, GetSalesByTypeAnalyticsQueryVariables>) {
        return Apollo.useQuery<GetSalesByTypeAnalyticsQuery, GetSalesByTypeAnalyticsQueryVariables>(GetSalesByTypeAnalyticsDocument, baseOptions);
      }
export function useGetSalesByTypeAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesByTypeAnalyticsQuery, GetSalesByTypeAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesByTypeAnalyticsQuery, GetSalesByTypeAnalyticsQueryVariables>(GetSalesByTypeAnalyticsDocument, baseOptions);
        }
export type GetSalesByTypeAnalyticsQueryHookResult = ReturnType<typeof useGetSalesByTypeAnalyticsQuery>;
export type GetSalesByTypeAnalyticsLazyQueryHookResult = ReturnType<typeof useGetSalesByTypeAnalyticsLazyQuery>;
export type GetSalesByTypeAnalyticsQueryResult = Apollo.QueryResult<GetSalesByTypeAnalyticsQuery, GetSalesByTypeAnalyticsQueryVariables>;
export function refetchGetSalesByTypeAnalyticsQuery(variables?: GetSalesByTypeAnalyticsQueryVariables) {
      return { query: GetSalesByTypeAnalyticsDocument, variables: variables }
    }
export const GetActivitiesAnalyticsDocument = gql`
    query getActivitiesAnalytics($filters: ActivitiesAnalyticsFiltersInput!) {
  activitiesAnalytics(filters: $filters) {
    ...ActivitiesAnalytics
  }
}
    ${ActivitiesAnalyticsFragmentDoc}`;

/**
 * __useGetActivitiesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetActivitiesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetActivitiesAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<GetActivitiesAnalyticsQuery, GetActivitiesAnalyticsQueryVariables>) {
        return Apollo.useQuery<GetActivitiesAnalyticsQuery, GetActivitiesAnalyticsQueryVariables>(GetActivitiesAnalyticsDocument, baseOptions);
      }
export function useGetActivitiesAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitiesAnalyticsQuery, GetActivitiesAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<GetActivitiesAnalyticsQuery, GetActivitiesAnalyticsQueryVariables>(GetActivitiesAnalyticsDocument, baseOptions);
        }
export type GetActivitiesAnalyticsQueryHookResult = ReturnType<typeof useGetActivitiesAnalyticsQuery>;
export type GetActivitiesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetActivitiesAnalyticsLazyQuery>;
export type GetActivitiesAnalyticsQueryResult = Apollo.QueryResult<GetActivitiesAnalyticsQuery, GetActivitiesAnalyticsQueryVariables>;
export function refetchGetActivitiesAnalyticsQuery(variables?: GetActivitiesAnalyticsQueryVariables) {
      return { query: GetActivitiesAnalyticsDocument, variables: variables }
    }
export const GetQuotesAnalyticsDocument = gql`
    query getQuotesAnalytics($filters: QuotesAnalyticsFiltersInput!) {
  quotesAnalytics(filters: $filters) {
    ...QuotesAnalytics
  }
}
    ${QuotesAnalyticsFragmentDoc}`;

/**
 * __useGetQuotesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetQuotesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesAnalyticsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetQuotesAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>) {
        return Apollo.useQuery<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>(GetQuotesAnalyticsDocument, baseOptions);
      }
export function useGetQuotesAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>) {
          return Apollo.useLazyQuery<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>(GetQuotesAnalyticsDocument, baseOptions);
        }
export type GetQuotesAnalyticsQueryHookResult = ReturnType<typeof useGetQuotesAnalyticsQuery>;
export type GetQuotesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetQuotesAnalyticsLazyQuery>;
export type GetQuotesAnalyticsQueryResult = Apollo.QueryResult<GetQuotesAnalyticsQuery, GetQuotesAnalyticsQueryVariables>;
export function refetchGetQuotesAnalyticsQuery(variables?: GetQuotesAnalyticsQueryVariables) {
      return { query: GetQuotesAnalyticsDocument, variables: variables }
    }
export const GetContactsDocument = gql`
    query getContacts($options: ContactListOptionsInputType, $filterByUser: UUID) {
  contacts(options: $options, filterByUser: $filterByUser) {
    items {
      ...MinimalContact
    }
    count
  }
}
    ${MinimalContactFragmentDoc}`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      filterByUser: // value for 'filterByUser'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export function refetchGetContactsQuery(variables?: GetContactsQueryVariables) {
      return { query: GetContactsDocument, variables: variables }
    }
export const GetContactOptionsDocument = gql`
    query getContactOptions($options: ContactListOptionsInputType, $filterByUser: UUID) {
  contacts(options: $options, filterByUser: $filterByUser) {
    items {
      ...ContactOption
    }
  }
}
    ${ContactOptionFragmentDoc}`;

/**
 * __useGetContactOptionsQuery__
 *
 * To run a query within a React component, call `useGetContactOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      filterByUser: // value for 'filterByUser'
 *   },
 * });
 */
export function useGetContactOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactOptionsQuery, GetContactOptionsQueryVariables>) {
        return Apollo.useQuery<GetContactOptionsQuery, GetContactOptionsQueryVariables>(GetContactOptionsDocument, baseOptions);
      }
export function useGetContactOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactOptionsQuery, GetContactOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetContactOptionsQuery, GetContactOptionsQueryVariables>(GetContactOptionsDocument, baseOptions);
        }
export type GetContactOptionsQueryHookResult = ReturnType<typeof useGetContactOptionsQuery>;
export type GetContactOptionsLazyQueryHookResult = ReturnType<typeof useGetContactOptionsLazyQuery>;
export type GetContactOptionsQueryResult = Apollo.QueryResult<GetContactOptionsQuery, GetContactOptionsQueryVariables>;
export function refetchGetContactOptionsQuery(variables?: GetContactOptionsQueryVariables) {
      return { query: GetContactOptionsDocument, variables: variables }
    }
export const GetContactAccountsDocument = gql`
    query getContactAccounts($id: UUID!) {
  contact(id: $id) {
    accounts {
      id
    }
  }
}
    `;

/**
 * __useGetContactAccountsQuery__
 *
 * To run a query within a React component, call `useGetContactAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactAccountsQuery, GetContactAccountsQueryVariables>) {
        return Apollo.useQuery<GetContactAccountsQuery, GetContactAccountsQueryVariables>(GetContactAccountsDocument, baseOptions);
      }
export function useGetContactAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactAccountsQuery, GetContactAccountsQueryVariables>) {
          return Apollo.useLazyQuery<GetContactAccountsQuery, GetContactAccountsQueryVariables>(GetContactAccountsDocument, baseOptions);
        }
export type GetContactAccountsQueryHookResult = ReturnType<typeof useGetContactAccountsQuery>;
export type GetContactAccountsLazyQueryHookResult = ReturnType<typeof useGetContactAccountsLazyQuery>;
export type GetContactAccountsQueryResult = Apollo.QueryResult<GetContactAccountsQuery, GetContactAccountsQueryVariables>;
export function refetchGetContactAccountsQuery(variables?: GetContactAccountsQueryVariables) {
      return { query: GetContactAccountsDocument, variables: variables }
    }
export const GetContactDocument = gql`
    query getContact($id: UUID!) {
  contact(id: $id) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions?: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export function refetchGetContactQuery(variables?: GetContactQueryVariables) {
      return { query: GetContactDocument, variables: variables }
    }
export const GetContactTypeDocument = gql`
    query getContactType($id: UUID!) {
  contactType(id: $id) {
    ...ContactType
  }
}
    ${ContactTypeFragmentDoc}`;

/**
 * __useGetContactTypeQuery__
 *
 * To run a query within a React component, call `useGetContactTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetContactTypeQuery, GetContactTypeQueryVariables>) {
        return Apollo.useQuery<GetContactTypeQuery, GetContactTypeQueryVariables>(GetContactTypeDocument, baseOptions);
      }
export function useGetContactTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactTypeQuery, GetContactTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetContactTypeQuery, GetContactTypeQueryVariables>(GetContactTypeDocument, baseOptions);
        }
export type GetContactTypeQueryHookResult = ReturnType<typeof useGetContactTypeQuery>;
export type GetContactTypeLazyQueryHookResult = ReturnType<typeof useGetContactTypeLazyQuery>;
export type GetContactTypeQueryResult = Apollo.QueryResult<GetContactTypeQuery, GetContactTypeQueryVariables>;
export function refetchGetContactTypeQuery(variables?: GetContactTypeQueryVariables) {
      return { query: GetContactTypeDocument, variables: variables }
    }
export const GetContactTypesDocument = gql`
    query getContactTypes($options: ContactTypeListOptionsInputType, $includeUnpublished: Boolean) {
  contactTypes(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...ContactType
    }
    count
  }
}
    ${ContactTypeFragmentDoc}`;

/**
 * __useGetContactTypesQuery__
 *
 * To run a query within a React component, call `useGetContactTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTypesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetContactTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetContactTypesQuery, GetContactTypesQueryVariables>) {
        return Apollo.useQuery<GetContactTypesQuery, GetContactTypesQueryVariables>(GetContactTypesDocument, baseOptions);
      }
export function useGetContactTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactTypesQuery, GetContactTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetContactTypesQuery, GetContactTypesQueryVariables>(GetContactTypesDocument, baseOptions);
        }
export type GetContactTypesQueryHookResult = ReturnType<typeof useGetContactTypesQuery>;
export type GetContactTypesLazyQueryHookResult = ReturnType<typeof useGetContactTypesLazyQuery>;
export type GetContactTypesQueryResult = Apollo.QueryResult<GetContactTypesQuery, GetContactTypesQueryVariables>;
export function refetchGetContactTypesQuery(variables?: GetContactTypesQueryVariables) {
      return { query: GetContactTypesDocument, variables: variables }
    }
export const GetSearchContactDocument = gql`
    query getSearchContact($id: ID!) {
  searchContact(id: $id) {
    ...ContactThoughAccount
  }
}
    ${ContactThoughAccountFragmentDoc}`;

/**
 * __useGetSearchContactQuery__
 *
 * To run a query within a React component, call `useGetSearchContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSearchContactQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchContactQuery, GetSearchContactQueryVariables>) {
        return Apollo.useQuery<GetSearchContactQuery, GetSearchContactQueryVariables>(GetSearchContactDocument, baseOptions);
      }
export function useGetSearchContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchContactQuery, GetSearchContactQueryVariables>) {
          return Apollo.useLazyQuery<GetSearchContactQuery, GetSearchContactQueryVariables>(GetSearchContactDocument, baseOptions);
        }
export type GetSearchContactQueryHookResult = ReturnType<typeof useGetSearchContactQuery>;
export type GetSearchContactLazyQueryHookResult = ReturnType<typeof useGetSearchContactLazyQuery>;
export type GetSearchContactQueryResult = Apollo.QueryResult<GetSearchContactQuery, GetSearchContactQueryVariables>;
export function refetchGetSearchContactQuery(variables?: GetSearchContactQueryVariables) {
      return { query: GetSearchContactDocument, variables: variables }
    }
export const GetSearchContactAccountDocument = gql`
    query getSearchContactAccount($accountId: ID!, $contactId: ID) {
  searchContactAccount(accountId: $accountId, contactId: $contactId) {
    ...ContactThoughAccount
  }
}
    ${ContactThoughAccountFragmentDoc}`;

/**
 * __useGetSearchContactAccountQuery__
 *
 * To run a query within a React component, call `useGetSearchContactAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchContactAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchContactAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGetSearchContactAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchContactAccountQuery, GetSearchContactAccountQueryVariables>) {
        return Apollo.useQuery<GetSearchContactAccountQuery, GetSearchContactAccountQueryVariables>(GetSearchContactAccountDocument, baseOptions);
      }
export function useGetSearchContactAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchContactAccountQuery, GetSearchContactAccountQueryVariables>) {
          return Apollo.useLazyQuery<GetSearchContactAccountQuery, GetSearchContactAccountQueryVariables>(GetSearchContactAccountDocument, baseOptions);
        }
export type GetSearchContactAccountQueryHookResult = ReturnType<typeof useGetSearchContactAccountQuery>;
export type GetSearchContactAccountLazyQueryHookResult = ReturnType<typeof useGetSearchContactAccountLazyQuery>;
export type GetSearchContactAccountQueryResult = Apollo.QueryResult<GetSearchContactAccountQuery, GetSearchContactAccountQueryVariables>;
export function refetchGetSearchContactAccountQuery(variables?: GetSearchContactAccountQueryVariables) {
      return { query: GetSearchContactAccountDocument, variables: variables }
    }
export const GetSearchContactsDocument = gql`
    query getSearchContacts($q: String!) {
  searchContacts(q: $q) {
    ...ContactThoughAccount
  }
}
    ${ContactThoughAccountFragmentDoc}`;

/**
 * __useGetSearchContactsQuery__
 *
 * To run a query within a React component, call `useGetSearchContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchContactsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetSearchContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchContactsQuery, GetSearchContactsQueryVariables>) {
        return Apollo.useQuery<GetSearchContactsQuery, GetSearchContactsQueryVariables>(GetSearchContactsDocument, baseOptions);
      }
export function useGetSearchContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchContactsQuery, GetSearchContactsQueryVariables>) {
          return Apollo.useLazyQuery<GetSearchContactsQuery, GetSearchContactsQueryVariables>(GetSearchContactsDocument, baseOptions);
        }
export type GetSearchContactsQueryHookResult = ReturnType<typeof useGetSearchContactsQuery>;
export type GetSearchContactsLazyQueryHookResult = ReturnType<typeof useGetSearchContactsLazyQuery>;
export type GetSearchContactsQueryResult = Apollo.QueryResult<GetSearchContactsQuery, GetSearchContactsQueryVariables>;
export function refetchGetSearchContactsQuery(variables?: GetSearchContactsQueryVariables) {
      return { query: GetSearchContactsDocument, variables: variables }
    }
export const CreateContactTypeDocument = gql`
    mutation createContactType($input: CreateContactTypeMutationInput!) {
  createContactType(input: $input) {
    contactType {
      ...ContactType
    }
    errors {
      field
      messages
    }
  }
}
    ${ContactTypeFragmentDoc}`;
export type CreateContactTypeMutationFn = Apollo.MutationFunction<CreateContactTypeMutation, CreateContactTypeMutationVariables>;

/**
 * __useCreateContactTypeMutation__
 *
 * To run a mutation, you first call `useCreateContactTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactTypeMutation, { data, loading, error }] = useCreateContactTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactTypeMutation, CreateContactTypeMutationVariables>) {
        return Apollo.useMutation<CreateContactTypeMutation, CreateContactTypeMutationVariables>(CreateContactTypeDocument, baseOptions);
      }
export type CreateContactTypeMutationHookResult = ReturnType<typeof useCreateContactTypeMutation>;
export type CreateContactTypeMutationResult = Apollo.MutationResult<CreateContactTypeMutation>;
export type CreateContactTypeMutationOptions = Apollo.BaseMutationOptions<CreateContactTypeMutation, CreateContactTypeMutationVariables>;
export const UpdateContactTypeDocument = gql`
    mutation updateContactType($input: UpdateContactTypeMutationInput!) {
  updateContactType(input: $input) {
    contactType {
      ...ContactType
    }
    errors {
      field
      messages
    }
  }
}
    ${ContactTypeFragmentDoc}`;
export type UpdateContactTypeMutationFn = Apollo.MutationFunction<UpdateContactTypeMutation, UpdateContactTypeMutationVariables>;

/**
 * __useUpdateContactTypeMutation__
 *
 * To run a mutation, you first call `useUpdateContactTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactTypeMutation, { data, loading, error }] = useUpdateContactTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactTypeMutation, UpdateContactTypeMutationVariables>) {
        return Apollo.useMutation<UpdateContactTypeMutation, UpdateContactTypeMutationVariables>(UpdateContactTypeDocument, baseOptions);
      }
export type UpdateContactTypeMutationHookResult = ReturnType<typeof useUpdateContactTypeMutation>;
export type UpdateContactTypeMutationResult = Apollo.MutationResult<UpdateContactTypeMutation>;
export type UpdateContactTypeMutationOptions = Apollo.BaseMutationOptions<UpdateContactTypeMutation, UpdateContactTypeMutationVariables>;
export const ActiveTogglerContactDocument = gql`
    mutation activeTogglerContact($input: ActiveTogglerContactMutationInput!) {
  activeTogglerContact(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ActiveTogglerContactMutationFn = Apollo.MutationFunction<ActiveTogglerContactMutation, ActiveTogglerContactMutationVariables>;

/**
 * __useActiveTogglerContactMutation__
 *
 * To run a mutation, you first call `useActiveTogglerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveTogglerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeTogglerContactMutation, { data, loading, error }] = useActiveTogglerContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveTogglerContactMutation(baseOptions?: Apollo.MutationHookOptions<ActiveTogglerContactMutation, ActiveTogglerContactMutationVariables>) {
        return Apollo.useMutation<ActiveTogglerContactMutation, ActiveTogglerContactMutationVariables>(ActiveTogglerContactDocument, baseOptions);
      }
export type ActiveTogglerContactMutationHookResult = ReturnType<typeof useActiveTogglerContactMutation>;
export type ActiveTogglerContactMutationResult = Apollo.MutationResult<ActiveTogglerContactMutation>;
export type ActiveTogglerContactMutationOptions = Apollo.BaseMutationOptions<ActiveTogglerContactMutation, ActiveTogglerContactMutationVariables>;
export const DeleteContactTypeDocument = gql`
    mutation deleteContactType($input: DeleteContactTypeMutationInput!) {
  deleteContactType(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteContactTypeMutationFn = Apollo.MutationFunction<DeleteContactTypeMutation, DeleteContactTypeMutationVariables>;

/**
 * __useDeleteContactTypeMutation__
 *
 * To run a mutation, you first call `useDeleteContactTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactTypeMutation, { data, loading, error }] = useDeleteContactTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactTypeMutation, DeleteContactTypeMutationVariables>) {
        return Apollo.useMutation<DeleteContactTypeMutation, DeleteContactTypeMutationVariables>(DeleteContactTypeDocument, baseOptions);
      }
export type DeleteContactTypeMutationHookResult = ReturnType<typeof useDeleteContactTypeMutation>;
export type DeleteContactTypeMutationResult = Apollo.MutationResult<DeleteContactTypeMutation>;
export type DeleteContactTypeMutationOptions = Apollo.BaseMutationOptions<DeleteContactTypeMutation, DeleteContactTypeMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactMutationInput!) {
  createContact(input: $input) {
    contact {
      ...Contact
    }
    errors {
      field
      messages
    }
  }
}
    ${ContactFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($input: UpdateContactMutationInput!) {
  updateContact(input: $input) {
    contact {
      ...Contact
    }
    errors {
      field
      messages
    }
  }
}
    ${ContactFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation deleteContact($input: DeleteContactMutationInput!) {
  deleteContact(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const ImportContactsDocument = gql`
    mutation importContacts($input: ImportContactsMutationInput!) {
  importContacts(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ImportContactsMutationFn = Apollo.MutationFunction<ImportContactsMutation, ImportContactsMutationVariables>;

/**
 * __useImportContactsMutation__
 *
 * To run a mutation, you first call `useImportContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importContactsMutation, { data, loading, error }] = useImportContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportContactsMutation(baseOptions?: Apollo.MutationHookOptions<ImportContactsMutation, ImportContactsMutationVariables>) {
        return Apollo.useMutation<ImportContactsMutation, ImportContactsMutationVariables>(ImportContactsDocument, baseOptions);
      }
export type ImportContactsMutationHookResult = ReturnType<typeof useImportContactsMutation>;
export type ImportContactsMutationResult = Apollo.MutationResult<ImportContactsMutation>;
export type ImportContactsMutationOptions = Apollo.BaseMutationOptions<ImportContactsMutation, ImportContactsMutationVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($options: NotificationListOptionsInputType) {
  notifications(options: $options) {
    count
    notReadCount
    page
    items {
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export function refetchGetNotificationsQuery(variables?: GetNotificationsQueryVariables) {
      return { query: GetNotificationsDocument, variables: variables }
    }
export const GetNotificationsCountDocument = gql`
    query getNotificationsCount($options: NotificationListOptionsInputType) {
  notifications(options: $options) {
    count
    notReadCount
  }
}
    `;

/**
 * __useGetNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsCountQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>) {
        return Apollo.useQuery<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>(GetNotificationsCountDocument, baseOptions);
      }
export function useGetNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>) {
          return Apollo.useLazyQuery<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>(GetNotificationsCountDocument, baseOptions);
        }
export type GetNotificationsCountQueryHookResult = ReturnType<typeof useGetNotificationsCountQuery>;
export type GetNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetNotificationsCountLazyQuery>;
export type GetNotificationsCountQueryResult = Apollo.QueryResult<GetNotificationsCountQuery, GetNotificationsCountQueryVariables>;
export function refetchGetNotificationsCountQuery(variables?: GetNotificationsCountQueryVariables) {
      return { query: GetNotificationsCountDocument, variables: variables }
    }
export const MarkNotificationAsReadDocument = gql`
    mutation markNotificationAsRead($id: UUID!) {
  markNotificationAsRead(id: $id) {
    ok
  }
}
    `;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, baseOptions);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation markAllNotificationsAsRead {
  markAllNotificationsAsRead {
    ok
  }
}
    `;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>) {
        return Apollo.useMutation<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>(MarkAllNotificationsAsReadDocument, baseOptions);
      }
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = Apollo.MutationResult<MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAllNotificationsAsReadMutation, MarkAllNotificationsAsReadMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: UUID!) {
  deleteNotification(id: $id) {
    ok
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, baseOptions);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const GetPaymentMethodDocument = gql`
    query getPaymentMethod($id: UUID!) {
  paymentMethod(id: $id) {
    ...PaymentMethod
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __useGetPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>) {
        return Apollo.useQuery<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, baseOptions);
      }
export function useGetPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>(GetPaymentMethodDocument, baseOptions);
        }
export type GetPaymentMethodQueryHookResult = ReturnType<typeof useGetPaymentMethodQuery>;
export type GetPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodLazyQuery>;
export type GetPaymentMethodQueryResult = Apollo.QueryResult<GetPaymentMethodQuery, GetPaymentMethodQueryVariables>;
export function refetchGetPaymentMethodQuery(variables?: GetPaymentMethodQueryVariables) {
      return { query: GetPaymentMethodDocument, variables: variables }
    }
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods($options: PaymentMethodListOptionsInputType, $includeUnpublished: Boolean) {
  paymentMethods(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...PaymentMethod
    }
    count
  }
}
    ${PaymentMethodFragmentDoc}`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export function refetchGetPaymentMethodsQuery(variables?: GetPaymentMethodsQueryVariables) {
      return { query: GetPaymentMethodsDocument, variables: variables }
    }
export const CreatePaymentMethodDocument = gql`
    mutation createPaymentMethod($input: CreatePaymentMethodMutationInput!) {
  createPaymentMethod(input: $input) {
    paymentMethod {
      ...PaymentMethod
    }
    errors {
      field
      messages
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>) {
        return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(CreatePaymentMethodDocument, baseOptions);
      }
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation updatePaymentMethod($input: UpdatePaymentMethodMutationInput!) {
  updatePaymentMethod(input: $input) {
    paymentMethod {
      ...PaymentMethod
    }
    errors {
      field
      messages
    }
  }
}
    ${PaymentMethodFragmentDoc}`;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, baseOptions);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const DeletePaymentMethodDocument = gql`
    mutation deletePaymentMethod($input: DeletePaymentMethodMutationInput!) {
  deletePaymentMethod(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>) {
        return Apollo.useMutation<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>(DeletePaymentMethodDocument, baseOptions);
      }
export type DeletePaymentMethodMutationHookResult = ReturnType<typeof useDeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationResult = Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeletePaymentMethodMutation, DeletePaymentMethodMutationVariables>;
export const GetProductsDocument = gql`
    query getProducts($options: ProductListOptionsInputType, $includeUnpublished: Boolean) {
  products(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...Product
    }
    count
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, baseOptions);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export function refetchGetProductsQuery(variables?: GetProductsQueryVariables) {
      return { query: GetProductsDocument, variables: variables }
    }
export const GetProductOptionsDocument = gql`
    query getProductOptions($options: ProductListOptionsInputType) {
  products(options: $options) {
    items {
      ...MinimalProduct
    }
    count
  }
}
    ${MinimalProductFragmentDoc}`;

/**
 * __useGetProductOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetProductOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductOptionsQuery, GetProductOptionsQueryVariables>) {
        return Apollo.useQuery<GetProductOptionsQuery, GetProductOptionsQueryVariables>(GetProductOptionsDocument, baseOptions);
      }
export function useGetProductOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductOptionsQuery, GetProductOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetProductOptionsQuery, GetProductOptionsQueryVariables>(GetProductOptionsDocument, baseOptions);
        }
export type GetProductOptionsQueryHookResult = ReturnType<typeof useGetProductOptionsQuery>;
export type GetProductOptionsLazyQueryHookResult = ReturnType<typeof useGetProductOptionsLazyQuery>;
export type GetProductOptionsQueryResult = Apollo.QueryResult<GetProductOptionsQuery, GetProductOptionsQueryVariables>;
export function refetchGetProductOptionsQuery(variables?: GetProductOptionsQueryVariables) {
      return { query: GetProductOptionsDocument, variables: variables }
    }
export const GetProductDocument = gql`
    query getProduct($id: UUID!) {
  product(id: $id) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions?: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, baseOptions);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export function refetchGetProductQuery(variables?: GetProductQueryVariables) {
      return { query: GetProductDocument, variables: variables }
    }
export const GetProductSuppliesCompaniesDocument = gql`
    query getProductSuppliesCompanies($options: ProductSuppliesCompanyListOptionsInputType, $includeUnpublished: Boolean) {
  productSuppliesCompanies(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...ProductSuppliesCompany
    }
    count
  }
}
    ${ProductSuppliesCompanyFragmentDoc}`;

/**
 * __useGetProductSuppliesCompaniesQuery__
 *
 * To run a query within a React component, call `useGetProductSuppliesCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSuppliesCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSuppliesCompaniesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductSuppliesCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductSuppliesCompaniesQuery, GetProductSuppliesCompaniesQueryVariables>) {
        return Apollo.useQuery<GetProductSuppliesCompaniesQuery, GetProductSuppliesCompaniesQueryVariables>(GetProductSuppliesCompaniesDocument, baseOptions);
      }
export function useGetProductSuppliesCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSuppliesCompaniesQuery, GetProductSuppliesCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<GetProductSuppliesCompaniesQuery, GetProductSuppliesCompaniesQueryVariables>(GetProductSuppliesCompaniesDocument, baseOptions);
        }
export type GetProductSuppliesCompaniesQueryHookResult = ReturnType<typeof useGetProductSuppliesCompaniesQuery>;
export type GetProductSuppliesCompaniesLazyQueryHookResult = ReturnType<typeof useGetProductSuppliesCompaniesLazyQuery>;
export type GetProductSuppliesCompaniesQueryResult = Apollo.QueryResult<GetProductSuppliesCompaniesQuery, GetProductSuppliesCompaniesQueryVariables>;
export function refetchGetProductSuppliesCompaniesQuery(variables?: GetProductSuppliesCompaniesQueryVariables) {
      return { query: GetProductSuppliesCompaniesDocument, variables: variables }
    }
export const GetProductSuppliesCompanyDocument = gql`
    query getProductSuppliesCompany($id: UUID!) {
  productSuppliesCompany(id: $id) {
    ...ProductSuppliesCompany
  }
}
    ${ProductSuppliesCompanyFragmentDoc}`;

/**
 * __useGetProductSuppliesCompanyQuery__
 *
 * To run a query within a React component, call `useGetProductSuppliesCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSuppliesCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSuppliesCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductSuppliesCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetProductSuppliesCompanyQuery, GetProductSuppliesCompanyQueryVariables>) {
        return Apollo.useQuery<GetProductSuppliesCompanyQuery, GetProductSuppliesCompanyQueryVariables>(GetProductSuppliesCompanyDocument, baseOptions);
      }
export function useGetProductSuppliesCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSuppliesCompanyQuery, GetProductSuppliesCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetProductSuppliesCompanyQuery, GetProductSuppliesCompanyQueryVariables>(GetProductSuppliesCompanyDocument, baseOptions);
        }
export type GetProductSuppliesCompanyQueryHookResult = ReturnType<typeof useGetProductSuppliesCompanyQuery>;
export type GetProductSuppliesCompanyLazyQueryHookResult = ReturnType<typeof useGetProductSuppliesCompanyLazyQuery>;
export type GetProductSuppliesCompanyQueryResult = Apollo.QueryResult<GetProductSuppliesCompanyQuery, GetProductSuppliesCompanyQueryVariables>;
export function refetchGetProductSuppliesCompanyQuery(variables?: GetProductSuppliesCompanyQueryVariables) {
      return { query: GetProductSuppliesCompanyDocument, variables: variables }
    }
export const GetProductSuppliesCompaniesByExactNameDocument = gql`
    query getProductSuppliesCompaniesByExactName($name: String!, $includeUnpublished: Boolean) {
  productSuppliesCompaniesByExactName(name: $name, includeUnpublished: $includeUnpublished) {
    ...ProductSuppliesCompany
  }
}
    ${ProductSuppliesCompanyFragmentDoc}`;

/**
 * __useGetProductSuppliesCompaniesByExactNameQuery__
 *
 * To run a query within a React component, call `useGetProductSuppliesCompaniesByExactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSuppliesCompaniesByExactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSuppliesCompaniesByExactNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductSuppliesCompaniesByExactNameQuery(baseOptions?: Apollo.QueryHookOptions<GetProductSuppliesCompaniesByExactNameQuery, GetProductSuppliesCompaniesByExactNameQueryVariables>) {
        return Apollo.useQuery<GetProductSuppliesCompaniesByExactNameQuery, GetProductSuppliesCompaniesByExactNameQueryVariables>(GetProductSuppliesCompaniesByExactNameDocument, baseOptions);
      }
export function useGetProductSuppliesCompaniesByExactNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductSuppliesCompaniesByExactNameQuery, GetProductSuppliesCompaniesByExactNameQueryVariables>) {
          return Apollo.useLazyQuery<GetProductSuppliesCompaniesByExactNameQuery, GetProductSuppliesCompaniesByExactNameQueryVariables>(GetProductSuppliesCompaniesByExactNameDocument, baseOptions);
        }
export type GetProductSuppliesCompaniesByExactNameQueryHookResult = ReturnType<typeof useGetProductSuppliesCompaniesByExactNameQuery>;
export type GetProductSuppliesCompaniesByExactNameLazyQueryHookResult = ReturnType<typeof useGetProductSuppliesCompaniesByExactNameLazyQuery>;
export type GetProductSuppliesCompaniesByExactNameQueryResult = Apollo.QueryResult<GetProductSuppliesCompaniesByExactNameQuery, GetProductSuppliesCompaniesByExactNameQueryVariables>;
export function refetchGetProductSuppliesCompaniesByExactNameQuery(variables?: GetProductSuppliesCompaniesByExactNameQueryVariables) {
      return { query: GetProductSuppliesCompaniesByExactNameDocument, variables: variables }
    }
export const GetProductCategoriesDocument = gql`
    query getProductCategories($options: ProductCategoryListOptionsInputType, $includeUnpublished: Boolean) {
  productCategories(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...ProductCategory
    }
    count
  }
}
    ${ProductCategoryFragmentDoc}`;

/**
 * __useGetProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
        return Apollo.useQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, baseOptions);
      }
export function useGetProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(GetProductCategoriesDocument, baseOptions);
        }
export type GetProductCategoriesQueryHookResult = ReturnType<typeof useGetProductCategoriesQuery>;
export type GetProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesLazyQuery>;
export type GetProductCategoriesQueryResult = Apollo.QueryResult<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>;
export function refetchGetProductCategoriesQuery(variables?: GetProductCategoriesQueryVariables) {
      return { query: GetProductCategoriesDocument, variables: variables }
    }
export const GetProductCategoriesOptionsDocument = gql`
    query getProductCategoriesOptions($options: ProductCategoryListOptionsInputType, $includeUnpublished: Boolean) {
  productCategories(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...ProductCategoryOption
    }
    count
  }
}
    ${ProductCategoryOptionFragmentDoc}`;

/**
 * __useGetProductCategoriesOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductCategoriesOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoriesOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoriesOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductCategoriesOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoriesOptionsQuery, GetProductCategoriesOptionsQueryVariables>) {
        return Apollo.useQuery<GetProductCategoriesOptionsQuery, GetProductCategoriesOptionsQueryVariables>(GetProductCategoriesOptionsDocument, baseOptions);
      }
export function useGetProductCategoriesOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoriesOptionsQuery, GetProductCategoriesOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetProductCategoriesOptionsQuery, GetProductCategoriesOptionsQueryVariables>(GetProductCategoriesOptionsDocument, baseOptions);
        }
export type GetProductCategoriesOptionsQueryHookResult = ReturnType<typeof useGetProductCategoriesOptionsQuery>;
export type GetProductCategoriesOptionsLazyQueryHookResult = ReturnType<typeof useGetProductCategoriesOptionsLazyQuery>;
export type GetProductCategoriesOptionsQueryResult = Apollo.QueryResult<GetProductCategoriesOptionsQuery, GetProductCategoriesOptionsQueryVariables>;
export function refetchGetProductCategoriesOptionsQuery(variables?: GetProductCategoriesOptionsQueryVariables) {
      return { query: GetProductCategoriesOptionsDocument, variables: variables }
    }
export const GetProductCategoryDocument = gql`
    query getProductCategory($id: UUID!) {
  productCategory(id: $id) {
    ...ProductCategory
  }
}
    ${ProductCategoryFragmentDoc}`;

/**
 * __useGetProductCategoryQuery__
 *
 * To run a query within a React component, call `useGetProductCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetProductCategoryQuery, GetProductCategoryQueryVariables>) {
        return Apollo.useQuery<GetProductCategoryQuery, GetProductCategoryQueryVariables>(GetProductCategoryDocument, baseOptions);
      }
export function useGetProductCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductCategoryQuery, GetProductCategoryQueryVariables>) {
          return Apollo.useLazyQuery<GetProductCategoryQuery, GetProductCategoryQueryVariables>(GetProductCategoryDocument, baseOptions);
        }
export type GetProductCategoryQueryHookResult = ReturnType<typeof useGetProductCategoryQuery>;
export type GetProductCategoryLazyQueryHookResult = ReturnType<typeof useGetProductCategoryLazyQuery>;
export type GetProductCategoryQueryResult = Apollo.QueryResult<GetProductCategoryQuery, GetProductCategoryQueryVariables>;
export function refetchGetProductCategoryQuery(variables?: GetProductCategoryQueryVariables) {
      return { query: GetProductCategoryDocument, variables: variables }
    }
export const GetUnitsDocument = gql`
    query getUnits($options: UnitListOptionsInputType) {
  units(options: $options) {
    items {
      ...Unit
    }
    count
  }
}
    ${UnitFragmentDoc}`;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, baseOptions);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, baseOptions);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export function refetchGetUnitsQuery(variables?: GetUnitsQueryVariables) {
      return { query: GetUnitsDocument, variables: variables }
    }
export const GetUnitDocument = gql`
    query getUnit($id: UUID!) {
  unit(id: $id) {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;

/**
 * __useGetUnitQuery__
 *
 * To run a query within a React component, call `useGetUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnitQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitQuery, GetUnitQueryVariables>) {
        return Apollo.useQuery<GetUnitQuery, GetUnitQueryVariables>(GetUnitDocument, baseOptions);
      }
export function useGetUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitQuery, GetUnitQueryVariables>) {
          return Apollo.useLazyQuery<GetUnitQuery, GetUnitQueryVariables>(GetUnitDocument, baseOptions);
        }
export type GetUnitQueryHookResult = ReturnType<typeof useGetUnitQuery>;
export type GetUnitLazyQueryHookResult = ReturnType<typeof useGetUnitLazyQuery>;
export type GetUnitQueryResult = Apollo.QueryResult<GetUnitQuery, GetUnitQueryVariables>;
export function refetchGetUnitQuery(variables?: GetUnitQueryVariables) {
      return { query: GetUnitDocument, variables: variables }
    }
export const GetProductVariationsDocument = gql`
    query getProductVariations($options: ProductVariationListOptionsInputType, $includeUnpublished: Boolean) {
  productVariations(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...ProductVariation
    }
    count
  }
}
    ${ProductVariationFragmentDoc}`;

/**
 * __useGetProductVariationsQuery__
 *
 * To run a query within a React component, call `useGetProductVariationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductVariationsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductVariationsQuery, GetProductVariationsQueryVariables>) {
        return Apollo.useQuery<GetProductVariationsQuery, GetProductVariationsQueryVariables>(GetProductVariationsDocument, baseOptions);
      }
export function useGetProductVariationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVariationsQuery, GetProductVariationsQueryVariables>) {
          return Apollo.useLazyQuery<GetProductVariationsQuery, GetProductVariationsQueryVariables>(GetProductVariationsDocument, baseOptions);
        }
export type GetProductVariationsQueryHookResult = ReturnType<typeof useGetProductVariationsQuery>;
export type GetProductVariationsLazyQueryHookResult = ReturnType<typeof useGetProductVariationsLazyQuery>;
export type GetProductVariationsQueryResult = Apollo.QueryResult<GetProductVariationsQuery, GetProductVariationsQueryVariables>;
export function refetchGetProductVariationsQuery(variables?: GetProductVariationsQueryVariables) {
      return { query: GetProductVariationsDocument, variables: variables }
    }
export const GetProductVariationDocument = gql`
    query getProductVariation($id: UUID!) {
  productVariation(id: $id) {
    ...ProductVariation
  }
}
    ${ProductVariationFragmentDoc}`;

/**
 * __useGetProductVariationQuery__
 *
 * To run a query within a React component, call `useGetProductVariationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductVariationQuery(baseOptions?: Apollo.QueryHookOptions<GetProductVariationQuery, GetProductVariationQueryVariables>) {
        return Apollo.useQuery<GetProductVariationQuery, GetProductVariationQueryVariables>(GetProductVariationDocument, baseOptions);
      }
export function useGetProductVariationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductVariationQuery, GetProductVariationQueryVariables>) {
          return Apollo.useLazyQuery<GetProductVariationQuery, GetProductVariationQueryVariables>(GetProductVariationDocument, baseOptions);
        }
export type GetProductVariationQueryHookResult = ReturnType<typeof useGetProductVariationQuery>;
export type GetProductVariationLazyQueryHookResult = ReturnType<typeof useGetProductVariationLazyQuery>;
export type GetProductVariationQueryResult = Apollo.QueryResult<GetProductVariationQuery, GetProductVariationQueryVariables>;
export function refetchGetProductVariationQuery(variables?: GetProductVariationQueryVariables) {
      return { query: GetProductVariationDocument, variables: variables }
    }
export const GetProductPriceModifiersDocument = gql`
    query getProductPriceModifiers($options: ProductPriceModifierListOptionsInputType, $includeUnpublished: Boolean) {
  productPriceModifiers(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...ProductPriceModifier
    }
    count
  }
}
    ${ProductPriceModifierFragmentDoc}`;

/**
 * __useGetProductPriceModifiersQuery__
 *
 * To run a query within a React component, call `useGetProductPriceModifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPriceModifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPriceModifiersQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetProductPriceModifiersQuery(baseOptions?: Apollo.QueryHookOptions<GetProductPriceModifiersQuery, GetProductPriceModifiersQueryVariables>) {
        return Apollo.useQuery<GetProductPriceModifiersQuery, GetProductPriceModifiersQueryVariables>(GetProductPriceModifiersDocument, baseOptions);
      }
export function useGetProductPriceModifiersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductPriceModifiersQuery, GetProductPriceModifiersQueryVariables>) {
          return Apollo.useLazyQuery<GetProductPriceModifiersQuery, GetProductPriceModifiersQueryVariables>(GetProductPriceModifiersDocument, baseOptions);
        }
export type GetProductPriceModifiersQueryHookResult = ReturnType<typeof useGetProductPriceModifiersQuery>;
export type GetProductPriceModifiersLazyQueryHookResult = ReturnType<typeof useGetProductPriceModifiersLazyQuery>;
export type GetProductPriceModifiersQueryResult = Apollo.QueryResult<GetProductPriceModifiersQuery, GetProductPriceModifiersQueryVariables>;
export function refetchGetProductPriceModifiersQuery(variables?: GetProductPriceModifiersQueryVariables) {
      return { query: GetProductPriceModifiersDocument, variables: variables }
    }
export const GetProductPriceModifierDocument = gql`
    query getProductPriceModifier($id: UUID!) {
  productPriceModifier(id: $id) {
    ...ProductPriceModifier
  }
}
    ${ProductPriceModifierFragmentDoc}`;

/**
 * __useGetProductPriceModifierQuery__
 *
 * To run a query within a React component, call `useGetProductPriceModifierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPriceModifierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPriceModifierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductPriceModifierQuery(baseOptions?: Apollo.QueryHookOptions<GetProductPriceModifierQuery, GetProductPriceModifierQueryVariables>) {
        return Apollo.useQuery<GetProductPriceModifierQuery, GetProductPriceModifierQueryVariables>(GetProductPriceModifierDocument, baseOptions);
      }
export function useGetProductPriceModifierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductPriceModifierQuery, GetProductPriceModifierQueryVariables>) {
          return Apollo.useLazyQuery<GetProductPriceModifierQuery, GetProductPriceModifierQueryVariables>(GetProductPriceModifierDocument, baseOptions);
        }
export type GetProductPriceModifierQueryHookResult = ReturnType<typeof useGetProductPriceModifierQuery>;
export type GetProductPriceModifierLazyQueryHookResult = ReturnType<typeof useGetProductPriceModifierLazyQuery>;
export type GetProductPriceModifierQueryResult = Apollo.QueryResult<GetProductPriceModifierQuery, GetProductPriceModifierQueryVariables>;
export function refetchGetProductPriceModifierQuery(variables?: GetProductPriceModifierQueryVariables) {
      return { query: GetProductPriceModifierDocument, variables: variables }
    }
export const GetDefaultProductCategoriesDocument = gql`
    query getDefaultProductCategories {
  defaultProductCategories {
    ...DefaultProductCategory
  }
}
    ${DefaultProductCategoryFragmentDoc}`;

/**
 * __useGetDefaultProductCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDefaultProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultProductCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultProductCategoriesQuery, GetDefaultProductCategoriesQueryVariables>) {
        return Apollo.useQuery<GetDefaultProductCategoriesQuery, GetDefaultProductCategoriesQueryVariables>(GetDefaultProductCategoriesDocument, baseOptions);
      }
export function useGetDefaultProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultProductCategoriesQuery, GetDefaultProductCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetDefaultProductCategoriesQuery, GetDefaultProductCategoriesQueryVariables>(GetDefaultProductCategoriesDocument, baseOptions);
        }
export type GetDefaultProductCategoriesQueryHookResult = ReturnType<typeof useGetDefaultProductCategoriesQuery>;
export type GetDefaultProductCategoriesLazyQueryHookResult = ReturnType<typeof useGetDefaultProductCategoriesLazyQuery>;
export type GetDefaultProductCategoriesQueryResult = Apollo.QueryResult<GetDefaultProductCategoriesQuery, GetDefaultProductCategoriesQueryVariables>;
export function refetchGetDefaultProductCategoriesQuery(variables?: GetDefaultProductCategoriesQueryVariables) {
      return { query: GetDefaultProductCategoriesDocument, variables: variables }
    }
export const GetDefaultProductSuppliesCompanyDocument = gql`
    query getDefaultProductSuppliesCompany {
  defaultProductSuppliesCompany {
    ...DefaultProductSuppliesCompany
  }
}
    ${DefaultProductSuppliesCompanyFragmentDoc}`;

/**
 * __useGetDefaultProductSuppliesCompanyQuery__
 *
 * To run a query within a React component, call `useGetDefaultProductSuppliesCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultProductSuppliesCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultProductSuppliesCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultProductSuppliesCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultProductSuppliesCompanyQuery, GetDefaultProductSuppliesCompanyQueryVariables>) {
        return Apollo.useQuery<GetDefaultProductSuppliesCompanyQuery, GetDefaultProductSuppliesCompanyQueryVariables>(GetDefaultProductSuppliesCompanyDocument, baseOptions);
      }
export function useGetDefaultProductSuppliesCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultProductSuppliesCompanyQuery, GetDefaultProductSuppliesCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetDefaultProductSuppliesCompanyQuery, GetDefaultProductSuppliesCompanyQueryVariables>(GetDefaultProductSuppliesCompanyDocument, baseOptions);
        }
export type GetDefaultProductSuppliesCompanyQueryHookResult = ReturnType<typeof useGetDefaultProductSuppliesCompanyQuery>;
export type GetDefaultProductSuppliesCompanyLazyQueryHookResult = ReturnType<typeof useGetDefaultProductSuppliesCompanyLazyQuery>;
export type GetDefaultProductSuppliesCompanyQueryResult = Apollo.QueryResult<GetDefaultProductSuppliesCompanyQuery, GetDefaultProductSuppliesCompanyQueryVariables>;
export function refetchGetDefaultProductSuppliesCompanyQuery(variables?: GetDefaultProductSuppliesCompanyQueryVariables) {
      return { query: GetDefaultProductSuppliesCompanyDocument, variables: variables }
    }
export const CreateProductDocument = gql`
    mutation createProduct($input: CreateProductMutationInput!) {
  createProduct(input: $input) {
    product {
      ...Product
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($input: UpdateProductMutationInput!) {
  updateProduct(input: $input) {
    product {
      ...Product
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($input: DeleteProductMutationInput!) {
  deleteProduct(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, baseOptions);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const CreateProductSuppliesCompanyDocument = gql`
    mutation createProductSuppliesCompany($input: CreateProductSuppliesCompanyMutationInput!) {
  createProductSuppliesCompany(input: $input) {
    productSuppliesCompany {
      ...ProductSuppliesCompany
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductSuppliesCompanyFragmentDoc}`;
export type CreateProductSuppliesCompanyMutationFn = Apollo.MutationFunction<CreateProductSuppliesCompanyMutation, CreateProductSuppliesCompanyMutationVariables>;

/**
 * __useCreateProductSuppliesCompanyMutation__
 *
 * To run a mutation, you first call `useCreateProductSuppliesCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSuppliesCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductSuppliesCompanyMutation, { data, loading, error }] = useCreateProductSuppliesCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductSuppliesCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductSuppliesCompanyMutation, CreateProductSuppliesCompanyMutationVariables>) {
        return Apollo.useMutation<CreateProductSuppliesCompanyMutation, CreateProductSuppliesCompanyMutationVariables>(CreateProductSuppliesCompanyDocument, baseOptions);
      }
export type CreateProductSuppliesCompanyMutationHookResult = ReturnType<typeof useCreateProductSuppliesCompanyMutation>;
export type CreateProductSuppliesCompanyMutationResult = Apollo.MutationResult<CreateProductSuppliesCompanyMutation>;
export type CreateProductSuppliesCompanyMutationOptions = Apollo.BaseMutationOptions<CreateProductSuppliesCompanyMutation, CreateProductSuppliesCompanyMutationVariables>;
export const UpdateProductSuppliesCompanyDocument = gql`
    mutation updateProductSuppliesCompany($input: UpdateProductSuppliesCompanyMutationInput!) {
  updateProductSuppliesCompany(input: $input) {
    productSuppliesCompany {
      ...ProductSuppliesCompany
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductSuppliesCompanyFragmentDoc}`;
export type UpdateProductSuppliesCompanyMutationFn = Apollo.MutationFunction<UpdateProductSuppliesCompanyMutation, UpdateProductSuppliesCompanyMutationVariables>;

/**
 * __useUpdateProductSuppliesCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateProductSuppliesCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductSuppliesCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductSuppliesCompanyMutation, { data, loading, error }] = useUpdateProductSuppliesCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductSuppliesCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductSuppliesCompanyMutation, UpdateProductSuppliesCompanyMutationVariables>) {
        return Apollo.useMutation<UpdateProductSuppliesCompanyMutation, UpdateProductSuppliesCompanyMutationVariables>(UpdateProductSuppliesCompanyDocument, baseOptions);
      }
export type UpdateProductSuppliesCompanyMutationHookResult = ReturnType<typeof useUpdateProductSuppliesCompanyMutation>;
export type UpdateProductSuppliesCompanyMutationResult = Apollo.MutationResult<UpdateProductSuppliesCompanyMutation>;
export type UpdateProductSuppliesCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateProductSuppliesCompanyMutation, UpdateProductSuppliesCompanyMutationVariables>;
export const DeleteProductSuppliesCompanyDocument = gql`
    mutation deleteProductSuppliesCompany($input: DeleteProductSuppliesCompanyMutationInput!) {
  deleteProductSuppliesCompany(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteProductSuppliesCompanyMutationFn = Apollo.MutationFunction<DeleteProductSuppliesCompanyMutation, DeleteProductSuppliesCompanyMutationVariables>;

/**
 * __useDeleteProductSuppliesCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteProductSuppliesCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductSuppliesCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductSuppliesCompanyMutation, { data, loading, error }] = useDeleteProductSuppliesCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductSuppliesCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductSuppliesCompanyMutation, DeleteProductSuppliesCompanyMutationVariables>) {
        return Apollo.useMutation<DeleteProductSuppliesCompanyMutation, DeleteProductSuppliesCompanyMutationVariables>(DeleteProductSuppliesCompanyDocument, baseOptions);
      }
export type DeleteProductSuppliesCompanyMutationHookResult = ReturnType<typeof useDeleteProductSuppliesCompanyMutation>;
export type DeleteProductSuppliesCompanyMutationResult = Apollo.MutationResult<DeleteProductSuppliesCompanyMutation>;
export type DeleteProductSuppliesCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteProductSuppliesCompanyMutation, DeleteProductSuppliesCompanyMutationVariables>;
export const CreateProductCategoryDocument = gql`
    mutation createProductCategory($input: CreateProductCategoryMutationInput!) {
  createProductCategory(input: $input) {
    productCategory {
      ...ProductCategory
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductCategoryFragmentDoc}`;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, baseOptions);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export const UpdateProductCategoryDocument = gql`
    mutation updateProductCategory($input: UpdateProductCategoryMutationInput!) {
  updateProductCategory(input: $input) {
    productCategory {
      ...ProductCategory
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductCategoryFragmentDoc}`;
export type UpdateProductCategoryMutationFn = Apollo.MutationFunction<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;

/**
 * __useUpdateProductCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoryMutation, { data, loading, error }] = useUpdateProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>) {
        return Apollo.useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(UpdateProductCategoryDocument, baseOptions);
      }
export type UpdateProductCategoryMutationHookResult = ReturnType<typeof useUpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationResult = Apollo.MutationResult<UpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;
export const DeleteProductCategoryDocument = gql`
    mutation deleteProductCategory($input: DeleteProductCategoryMutationInput!) {
  deleteProductCategory(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteProductCategoryMutationFn = Apollo.MutationFunction<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>;

/**
 * __useDeleteProductCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductCategoryMutation, { data, loading, error }] = useDeleteProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>) {
        return Apollo.useMutation<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>(DeleteProductCategoryDocument, baseOptions);
      }
export type DeleteProductCategoryMutationHookResult = ReturnType<typeof useDeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationResult = Apollo.MutationResult<DeleteProductCategoryMutation>;
export type DeleteProductCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteProductCategoryMutation, DeleteProductCategoryMutationVariables>;
export const CreateUnitDocument = gql`
    mutation createUnit($input: CreateUnitMutationInput!) {
  createUnit(input: $input) {
    unit {
      ...Unit
    }
    errors {
      field
      messages
    }
  }
}
    ${UnitFragmentDoc}`;
export type CreateUnitMutationFn = Apollo.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>;

/**
 * __useCreateUnitMutation__
 *
 * To run a mutation, you first call `useCreateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitMutation, { data, loading, error }] = useCreateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitMutation, CreateUnitMutationVariables>) {
        return Apollo.useMutation<CreateUnitMutation, CreateUnitMutationVariables>(CreateUnitDocument, baseOptions);
      }
export type CreateUnitMutationHookResult = ReturnType<typeof useCreateUnitMutation>;
export type CreateUnitMutationResult = Apollo.MutationResult<CreateUnitMutation>;
export type CreateUnitMutationOptions = Apollo.BaseMutationOptions<CreateUnitMutation, CreateUnitMutationVariables>;
export const UpdateUnitDocument = gql`
    mutation updateUnit($input: UpdateUnitMutationInput!) {
  updateUnit(input: $input) {
    unit {
      ...Unit
    }
    errors {
      field
      messages
    }
  }
}
    ${UnitFragmentDoc}`;
export type UpdateUnitMutationFn = Apollo.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>) {
        return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, baseOptions);
      }
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const DeleteUnitDocument = gql`
    mutation deleteUnit($input: DeleteUnitMutationInput!) {
  deleteUnit(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteUnitMutationFn = Apollo.MutationFunction<DeleteUnitMutation, DeleteUnitMutationVariables>;

/**
 * __useDeleteUnitMutation__
 *
 * To run a mutation, you first call `useDeleteUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitMutation, { data, loading, error }] = useDeleteUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitMutation, DeleteUnitMutationVariables>) {
        return Apollo.useMutation<DeleteUnitMutation, DeleteUnitMutationVariables>(DeleteUnitDocument, baseOptions);
      }
export type DeleteUnitMutationHookResult = ReturnType<typeof useDeleteUnitMutation>;
export type DeleteUnitMutationResult = Apollo.MutationResult<DeleteUnitMutation>;
export type DeleteUnitMutationOptions = Apollo.BaseMutationOptions<DeleteUnitMutation, DeleteUnitMutationVariables>;
export const CreateProductVariationDocument = gql`
    mutation createProductVariation($input: CreateProductVariationMutationInput!) {
  createProductVariation(input: $input) {
    productVariation {
      ...ProductVariation
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductVariationFragmentDoc}`;
export type CreateProductVariationMutationFn = Apollo.MutationFunction<CreateProductVariationMutation, CreateProductVariationMutationVariables>;

/**
 * __useCreateProductVariationMutation__
 *
 * To run a mutation, you first call `useCreateProductVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductVariationMutation, { data, loading, error }] = useCreateProductVariationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductVariationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductVariationMutation, CreateProductVariationMutationVariables>) {
        return Apollo.useMutation<CreateProductVariationMutation, CreateProductVariationMutationVariables>(CreateProductVariationDocument, baseOptions);
      }
export type CreateProductVariationMutationHookResult = ReturnType<typeof useCreateProductVariationMutation>;
export type CreateProductVariationMutationResult = Apollo.MutationResult<CreateProductVariationMutation>;
export type CreateProductVariationMutationOptions = Apollo.BaseMutationOptions<CreateProductVariationMutation, CreateProductVariationMutationVariables>;
export const UpdateProductVariationDocument = gql`
    mutation updateProductVariation($input: UpdateProductVariationMutationInput!) {
  updateProductVariation(input: $input) {
    productVariation {
      ...ProductVariation
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductVariationFragmentDoc}`;
export type UpdateProductVariationMutationFn = Apollo.MutationFunction<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>;

/**
 * __useUpdateProductVariationMutation__
 *
 * To run a mutation, you first call `useUpdateProductVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductVariationMutation, { data, loading, error }] = useUpdateProductVariationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductVariationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>) {
        return Apollo.useMutation<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>(UpdateProductVariationDocument, baseOptions);
      }
export type UpdateProductVariationMutationHookResult = ReturnType<typeof useUpdateProductVariationMutation>;
export type UpdateProductVariationMutationResult = Apollo.MutationResult<UpdateProductVariationMutation>;
export type UpdateProductVariationMutationOptions = Apollo.BaseMutationOptions<UpdateProductVariationMutation, UpdateProductVariationMutationVariables>;
export const DeleteProductVariationDocument = gql`
    mutation deleteProductVariation($input: DeleteProductVariationMutationInput!) {
  deleteProductVariation(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteProductVariationMutationFn = Apollo.MutationFunction<DeleteProductVariationMutation, DeleteProductVariationMutationVariables>;

/**
 * __useDeleteProductVariationMutation__
 *
 * To run a mutation, you first call `useDeleteProductVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductVariationMutation, { data, loading, error }] = useDeleteProductVariationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductVariationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductVariationMutation, DeleteProductVariationMutationVariables>) {
        return Apollo.useMutation<DeleteProductVariationMutation, DeleteProductVariationMutationVariables>(DeleteProductVariationDocument, baseOptions);
      }
export type DeleteProductVariationMutationHookResult = ReturnType<typeof useDeleteProductVariationMutation>;
export type DeleteProductVariationMutationResult = Apollo.MutationResult<DeleteProductVariationMutation>;
export type DeleteProductVariationMutationOptions = Apollo.BaseMutationOptions<DeleteProductVariationMutation, DeleteProductVariationMutationVariables>;
export const CreateProductPriceModifierDocument = gql`
    mutation createProductPriceModifier($input: CreateProductPriceModifierMutationInput!) {
  createProductPriceModifier(input: $input) {
    productPriceModifier {
      ...ProductPriceModifier
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductPriceModifierFragmentDoc}`;
export type CreateProductPriceModifierMutationFn = Apollo.MutationFunction<CreateProductPriceModifierMutation, CreateProductPriceModifierMutationVariables>;

/**
 * __useCreateProductPriceModifierMutation__
 *
 * To run a mutation, you first call `useCreateProductPriceModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductPriceModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductPriceModifierMutation, { data, loading, error }] = useCreateProductPriceModifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductPriceModifierMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductPriceModifierMutation, CreateProductPriceModifierMutationVariables>) {
        return Apollo.useMutation<CreateProductPriceModifierMutation, CreateProductPriceModifierMutationVariables>(CreateProductPriceModifierDocument, baseOptions);
      }
export type CreateProductPriceModifierMutationHookResult = ReturnType<typeof useCreateProductPriceModifierMutation>;
export type CreateProductPriceModifierMutationResult = Apollo.MutationResult<CreateProductPriceModifierMutation>;
export type CreateProductPriceModifierMutationOptions = Apollo.BaseMutationOptions<CreateProductPriceModifierMutation, CreateProductPriceModifierMutationVariables>;
export const UpdateProductPriceModifierDocument = gql`
    mutation updateProductPriceModifier($input: UpdateProductPriceModifierMutationInput!) {
  updateProductPriceModifier(input: $input) {
    productPriceModifier {
      ...ProductPriceModifier
    }
    errors {
      field
      messages
    }
  }
}
    ${ProductPriceModifierFragmentDoc}`;
export type UpdateProductPriceModifierMutationFn = Apollo.MutationFunction<UpdateProductPriceModifierMutation, UpdateProductPriceModifierMutationVariables>;

/**
 * __useUpdateProductPriceModifierMutation__
 *
 * To run a mutation, you first call `useUpdateProductPriceModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductPriceModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductPriceModifierMutation, { data, loading, error }] = useUpdateProductPriceModifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductPriceModifierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductPriceModifierMutation, UpdateProductPriceModifierMutationVariables>) {
        return Apollo.useMutation<UpdateProductPriceModifierMutation, UpdateProductPriceModifierMutationVariables>(UpdateProductPriceModifierDocument, baseOptions);
      }
export type UpdateProductPriceModifierMutationHookResult = ReturnType<typeof useUpdateProductPriceModifierMutation>;
export type UpdateProductPriceModifierMutationResult = Apollo.MutationResult<UpdateProductPriceModifierMutation>;
export type UpdateProductPriceModifierMutationOptions = Apollo.BaseMutationOptions<UpdateProductPriceModifierMutation, UpdateProductPriceModifierMutationVariables>;
export const DeleteProductPriceModifierDocument = gql`
    mutation deleteProductPriceModifier($input: DeleteProductPriceModifierMutationInput!) {
  deleteProductPriceModifier(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteProductPriceModifierMutationFn = Apollo.MutationFunction<DeleteProductPriceModifierMutation, DeleteProductPriceModifierMutationVariables>;

/**
 * __useDeleteProductPriceModifierMutation__
 *
 * To run a mutation, you first call `useDeleteProductPriceModifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductPriceModifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductPriceModifierMutation, { data, loading, error }] = useDeleteProductPriceModifierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductPriceModifierMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductPriceModifierMutation, DeleteProductPriceModifierMutationVariables>) {
        return Apollo.useMutation<DeleteProductPriceModifierMutation, DeleteProductPriceModifierMutationVariables>(DeleteProductPriceModifierDocument, baseOptions);
      }
export type DeleteProductPriceModifierMutationHookResult = ReturnType<typeof useDeleteProductPriceModifierMutation>;
export type DeleteProductPriceModifierMutationResult = Apollo.MutationResult<DeleteProductPriceModifierMutation>;
export type DeleteProductPriceModifierMutationOptions = Apollo.BaseMutationOptions<DeleteProductPriceModifierMutation, DeleteProductPriceModifierMutationVariables>;
export const ImportProductsDocument = gql`
    mutation importProducts($input: ImportProductsMutationInput!) {
  importProducts(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ImportProductsMutationFn = Apollo.MutationFunction<ImportProductsMutation, ImportProductsMutationVariables>;

/**
 * __useImportProductsMutation__
 *
 * To run a mutation, you first call `useImportProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductsMutation, { data, loading, error }] = useImportProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportProductsMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductsMutation, ImportProductsMutationVariables>) {
        return Apollo.useMutation<ImportProductsMutation, ImportProductsMutationVariables>(ImportProductsDocument, baseOptions);
      }
export type ImportProductsMutationHookResult = ReturnType<typeof useImportProductsMutation>;
export type ImportProductsMutationResult = Apollo.MutationResult<ImportProductsMutation>;
export type ImportProductsMutationOptions = Apollo.BaseMutationOptions<ImportProductsMutation, ImportProductsMutationVariables>;
export const GetQuotesDocument = gql`
    query getQuotes($options: QuoteListOptionsInputType) {
  quotes(options: $options) {
    items {
      ...ListQuote
    }
    count
  }
}
    ${ListQuoteFragmentDoc}`;

/**
 * __useGetQuotesQuery__
 *
 * To run a query within a React component, call `useGetQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetQuotesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
        return Apollo.useQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, baseOptions);
      }
export function useGetQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotesQuery, GetQuotesQueryVariables>) {
          return Apollo.useLazyQuery<GetQuotesQuery, GetQuotesQueryVariables>(GetQuotesDocument, baseOptions);
        }
export type GetQuotesQueryHookResult = ReturnType<typeof useGetQuotesQuery>;
export type GetQuotesLazyQueryHookResult = ReturnType<typeof useGetQuotesLazyQuery>;
export type GetQuotesQueryResult = Apollo.QueryResult<GetQuotesQuery, GetQuotesQueryVariables>;
export function refetchGetQuotesQuery(variables?: GetQuotesQueryVariables) {
      return { query: GetQuotesDocument, variables: variables }
    }
export const GetCountQuotesDocument = gql`
    query getCountQuotes($options: QuoteListOptionsInputType) {
  quotes(options: $options) {
    count
  }
}
    `;

/**
 * __useGetCountQuotesQuery__
 *
 * To run a query within a React component, call `useGetCountQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountQuotesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCountQuotesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountQuotesQuery, GetCountQuotesQueryVariables>) {
        return Apollo.useQuery<GetCountQuotesQuery, GetCountQuotesQueryVariables>(GetCountQuotesDocument, baseOptions);
      }
export function useGetCountQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountQuotesQuery, GetCountQuotesQueryVariables>) {
          return Apollo.useLazyQuery<GetCountQuotesQuery, GetCountQuotesQueryVariables>(GetCountQuotesDocument, baseOptions);
        }
export type GetCountQuotesQueryHookResult = ReturnType<typeof useGetCountQuotesQuery>;
export type GetCountQuotesLazyQueryHookResult = ReturnType<typeof useGetCountQuotesLazyQuery>;
export type GetCountQuotesQueryResult = Apollo.QueryResult<GetCountQuotesQuery, GetCountQuotesQueryVariables>;
export function refetchGetCountQuotesQuery(variables?: GetCountQuotesQueryVariables) {
      return { query: GetCountQuotesDocument, variables: variables }
    }
export const GetQuoteOptionsDocument = gql`
    query getQuoteOptions($options: QuoteListOptionsInputType) {
  quotes(options: $options) {
    items {
      ...QuoteOption
    }
  }
}
    ${QuoteOptionFragmentDoc}`;

/**
 * __useGetQuoteOptionsQuery__
 *
 * To run a query within a React component, call `useGetQuoteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetQuoteOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteOptionsQuery, GetQuoteOptionsQueryVariables>) {
        return Apollo.useQuery<GetQuoteOptionsQuery, GetQuoteOptionsQueryVariables>(GetQuoteOptionsDocument, baseOptions);
      }
export function useGetQuoteOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteOptionsQuery, GetQuoteOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetQuoteOptionsQuery, GetQuoteOptionsQueryVariables>(GetQuoteOptionsDocument, baseOptions);
        }
export type GetQuoteOptionsQueryHookResult = ReturnType<typeof useGetQuoteOptionsQuery>;
export type GetQuoteOptionsLazyQueryHookResult = ReturnType<typeof useGetQuoteOptionsLazyQuery>;
export type GetQuoteOptionsQueryResult = Apollo.QueryResult<GetQuoteOptionsQuery, GetQuoteOptionsQueryVariables>;
export function refetchGetQuoteOptionsQuery(variables?: GetQuoteOptionsQueryVariables) {
      return { query: GetQuoteOptionsDocument, variables: variables }
    }
export const GetQuoteDocument = gql`
    query getQuote($id: UUID!) {
  quote(id: $id) {
    ...Quote
  }
}
    ${QuoteFragmentDoc}`;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        return Apollo.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, baseOptions);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          return Apollo.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, baseOptions);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export function refetchGetQuoteQuery(variables?: GetQuoteQueryVariables) {
      return { query: GetQuoteDocument, variables: variables }
    }
export const GetQuotePrintDocument = gql`
    query getQuotePrint($id: UUID!) {
  quote(id: $id) {
    ...QuotePrint
  }
}
    ${QuotePrintFragmentDoc}`;

/**
 * __useGetQuotePrintQuery__
 *
 * To run a query within a React component, call `useGetQuotePrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuotePrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuotePrintQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuotePrintQuery(baseOptions?: Apollo.QueryHookOptions<GetQuotePrintQuery, GetQuotePrintQueryVariables>) {
        return Apollo.useQuery<GetQuotePrintQuery, GetQuotePrintQueryVariables>(GetQuotePrintDocument, baseOptions);
      }
export function useGetQuotePrintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuotePrintQuery, GetQuotePrintQueryVariables>) {
          return Apollo.useLazyQuery<GetQuotePrintQuery, GetQuotePrintQueryVariables>(GetQuotePrintDocument, baseOptions);
        }
export type GetQuotePrintQueryHookResult = ReturnType<typeof useGetQuotePrintQuery>;
export type GetQuotePrintLazyQueryHookResult = ReturnType<typeof useGetQuotePrintLazyQuery>;
export type GetQuotePrintQueryResult = Apollo.QueryResult<GetQuotePrintQuery, GetQuotePrintQueryVariables>;
export function refetchGetQuotePrintQuery(variables?: GetQuotePrintQueryVariables) {
      return { query: GetQuotePrintDocument, variables: variables }
    }
export const GetAvailableTransitionsDocument = gql`
    query getAvailableTransitions($id: UUID!) {
  availableTransitions(id: $id)
}
    `;

/**
 * __useGetAvailableTransitionsQuery__
 *
 * To run a query within a React component, call `useGetAvailableTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTransitionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAvailableTransitionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableTransitionsQuery, GetAvailableTransitionsQueryVariables>) {
        return Apollo.useQuery<GetAvailableTransitionsQuery, GetAvailableTransitionsQueryVariables>(GetAvailableTransitionsDocument, baseOptions);
      }
export function useGetAvailableTransitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableTransitionsQuery, GetAvailableTransitionsQueryVariables>) {
          return Apollo.useLazyQuery<GetAvailableTransitionsQuery, GetAvailableTransitionsQueryVariables>(GetAvailableTransitionsDocument, baseOptions);
        }
export type GetAvailableTransitionsQueryHookResult = ReturnType<typeof useGetAvailableTransitionsQuery>;
export type GetAvailableTransitionsLazyQueryHookResult = ReturnType<typeof useGetAvailableTransitionsLazyQuery>;
export type GetAvailableTransitionsQueryResult = Apollo.QueryResult<GetAvailableTransitionsQuery, GetAvailableTransitionsQueryVariables>;
export function refetchGetAvailableTransitionsQuery(variables?: GetAvailableTransitionsQueryVariables) {
      return { query: GetAvailableTransitionsDocument, variables: variables }
    }
export const GetUpdatedQuoteInfoDocument = gql`
    query getUpdatedQuoteInfo($id: UUID!) {
  updatedQuoteInfo(id: $id) {
    ...UpdatedQuoteInfo
  }
}
    ${UpdatedQuoteInfoFragmentDoc}`;

/**
 * __useGetUpdatedQuoteInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdatedQuoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdatedQuoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdatedQuoteInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUpdatedQuoteInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUpdatedQuoteInfoQuery, GetUpdatedQuoteInfoQueryVariables>) {
        return Apollo.useQuery<GetUpdatedQuoteInfoQuery, GetUpdatedQuoteInfoQueryVariables>(GetUpdatedQuoteInfoDocument, baseOptions);
      }
export function useGetUpdatedQuoteInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdatedQuoteInfoQuery, GetUpdatedQuoteInfoQueryVariables>) {
          return Apollo.useLazyQuery<GetUpdatedQuoteInfoQuery, GetUpdatedQuoteInfoQueryVariables>(GetUpdatedQuoteInfoDocument, baseOptions);
        }
export type GetUpdatedQuoteInfoQueryHookResult = ReturnType<typeof useGetUpdatedQuoteInfoQuery>;
export type GetUpdatedQuoteInfoLazyQueryHookResult = ReturnType<typeof useGetUpdatedQuoteInfoLazyQuery>;
export type GetUpdatedQuoteInfoQueryResult = Apollo.QueryResult<GetUpdatedQuoteInfoQuery, GetUpdatedQuoteInfoQueryVariables>;
export function refetchGetUpdatedQuoteInfoQuery(variables?: GetUpdatedQuoteInfoQueryVariables) {
      return { query: GetUpdatedQuoteInfoDocument, variables: variables }
    }
export const GetQuoteSuccessChanceDocument = gql`
    query getQuoteSuccessChance($id: UUID!) {
  quoteSuccessChance(id: $id) {
    ...QuoteSuccessChance
  }
}
    ${QuoteSuccessChanceFragmentDoc}`;

/**
 * __useGetQuoteSuccessChanceQuery__
 *
 * To run a query within a React component, call `useGetQuoteSuccessChanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteSuccessChanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteSuccessChanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteSuccessChanceQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteSuccessChanceQuery, GetQuoteSuccessChanceQueryVariables>) {
        return Apollo.useQuery<GetQuoteSuccessChanceQuery, GetQuoteSuccessChanceQueryVariables>(GetQuoteSuccessChanceDocument, baseOptions);
      }
export function useGetQuoteSuccessChanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteSuccessChanceQuery, GetQuoteSuccessChanceQueryVariables>) {
          return Apollo.useLazyQuery<GetQuoteSuccessChanceQuery, GetQuoteSuccessChanceQueryVariables>(GetQuoteSuccessChanceDocument, baseOptions);
        }
export type GetQuoteSuccessChanceQueryHookResult = ReturnType<typeof useGetQuoteSuccessChanceQuery>;
export type GetQuoteSuccessChanceLazyQueryHookResult = ReturnType<typeof useGetQuoteSuccessChanceLazyQuery>;
export type GetQuoteSuccessChanceQueryResult = Apollo.QueryResult<GetQuoteSuccessChanceQuery, GetQuoteSuccessChanceQueryVariables>;
export function refetchGetQuoteSuccessChanceQuery(variables?: GetQuoteSuccessChanceQueryVariables) {
      return { query: GetQuoteSuccessChanceDocument, variables: variables }
    }
export const GetQuoteSuccessChancesDocument = gql`
    query getQuoteSuccessChances($options: QuoteSuccessChanceListOptionsInputType, $includeUnpublished: Boolean) {
  quoteSuccessChances(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...QuoteSuccessChance
    }
    count
  }
}
    ${QuoteSuccessChanceFragmentDoc}`;

/**
 * __useGetQuoteSuccessChancesQuery__
 *
 * To run a query within a React component, call `useGetQuoteSuccessChancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteSuccessChancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteSuccessChancesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetQuoteSuccessChancesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteSuccessChancesQuery, GetQuoteSuccessChancesQueryVariables>) {
        return Apollo.useQuery<GetQuoteSuccessChancesQuery, GetQuoteSuccessChancesQueryVariables>(GetQuoteSuccessChancesDocument, baseOptions);
      }
export function useGetQuoteSuccessChancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteSuccessChancesQuery, GetQuoteSuccessChancesQueryVariables>) {
          return Apollo.useLazyQuery<GetQuoteSuccessChancesQuery, GetQuoteSuccessChancesQueryVariables>(GetQuoteSuccessChancesDocument, baseOptions);
        }
export type GetQuoteSuccessChancesQueryHookResult = ReturnType<typeof useGetQuoteSuccessChancesQuery>;
export type GetQuoteSuccessChancesLazyQueryHookResult = ReturnType<typeof useGetQuoteSuccessChancesLazyQuery>;
export type GetQuoteSuccessChancesQueryResult = Apollo.QueryResult<GetQuoteSuccessChancesQuery, GetQuoteSuccessChancesQueryVariables>;
export function refetchGetQuoteSuccessChancesQuery(variables?: GetQuoteSuccessChancesQueryVariables) {
      return { query: GetQuoteSuccessChancesDocument, variables: variables }
    }
export const GetBillingTypeDocument = gql`
    query getBillingType($id: UUID!) {
  billingType(id: $id) {
    ...BillingType
  }
}
    ${BillingTypeFragmentDoc}`;

/**
 * __useGetBillingTypeQuery__
 *
 * To run a query within a React component, call `useGetBillingTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBillingTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingTypeQuery, GetBillingTypeQueryVariables>) {
        return Apollo.useQuery<GetBillingTypeQuery, GetBillingTypeQueryVariables>(GetBillingTypeDocument, baseOptions);
      }
export function useGetBillingTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingTypeQuery, GetBillingTypeQueryVariables>) {
          return Apollo.useLazyQuery<GetBillingTypeQuery, GetBillingTypeQueryVariables>(GetBillingTypeDocument, baseOptions);
        }
export type GetBillingTypeQueryHookResult = ReturnType<typeof useGetBillingTypeQuery>;
export type GetBillingTypeLazyQueryHookResult = ReturnType<typeof useGetBillingTypeLazyQuery>;
export type GetBillingTypeQueryResult = Apollo.QueryResult<GetBillingTypeQuery, GetBillingTypeQueryVariables>;
export function refetchGetBillingTypeQuery(variables?: GetBillingTypeQueryVariables) {
      return { query: GetBillingTypeDocument, variables: variables }
    }
export const GetBillingTypesDocument = gql`
    query getBillingTypes($options: BillingTypeListOptionsInputType, $includeUnpublished: Boolean) {
  billingTypes(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...BillingType
    }
    count
  }
}
    ${BillingTypeFragmentDoc}`;

/**
 * __useGetBillingTypesQuery__
 *
 * To run a query within a React component, call `useGetBillingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingTypesQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetBillingTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingTypesQuery, GetBillingTypesQueryVariables>) {
        return Apollo.useQuery<GetBillingTypesQuery, GetBillingTypesQueryVariables>(GetBillingTypesDocument, baseOptions);
      }
export function useGetBillingTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingTypesQuery, GetBillingTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetBillingTypesQuery, GetBillingTypesQueryVariables>(GetBillingTypesDocument, baseOptions);
        }
export type GetBillingTypesQueryHookResult = ReturnType<typeof useGetBillingTypesQuery>;
export type GetBillingTypesLazyQueryHookResult = ReturnType<typeof useGetBillingTypesLazyQuery>;
export type GetBillingTypesQueryResult = Apollo.QueryResult<GetBillingTypesQuery, GetBillingTypesQueryVariables>;
export function refetchGetBillingTypesQuery(variables?: GetBillingTypesQueryVariables) {
      return { query: GetBillingTypesDocument, variables: variables }
    }
export const GetLostReasonDocument = gql`
    query getLostReason($id: UUID!) {
  lostReason(id: $id) {
    ...LostReason
  }
}
    ${LostReasonFragmentDoc}`;

/**
 * __useGetLostReasonQuery__
 *
 * To run a query within a React component, call `useGetLostReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLostReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLostReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLostReasonQuery(baseOptions?: Apollo.QueryHookOptions<GetLostReasonQuery, GetLostReasonQueryVariables>) {
        return Apollo.useQuery<GetLostReasonQuery, GetLostReasonQueryVariables>(GetLostReasonDocument, baseOptions);
      }
export function useGetLostReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLostReasonQuery, GetLostReasonQueryVariables>) {
          return Apollo.useLazyQuery<GetLostReasonQuery, GetLostReasonQueryVariables>(GetLostReasonDocument, baseOptions);
        }
export type GetLostReasonQueryHookResult = ReturnType<typeof useGetLostReasonQuery>;
export type GetLostReasonLazyQueryHookResult = ReturnType<typeof useGetLostReasonLazyQuery>;
export type GetLostReasonQueryResult = Apollo.QueryResult<GetLostReasonQuery, GetLostReasonQueryVariables>;
export function refetchGetLostReasonQuery(variables?: GetLostReasonQueryVariables) {
      return { query: GetLostReasonDocument, variables: variables }
    }
export const GetLostReasonsDocument = gql`
    query getLostReasons($options: LostReasonListOptionsInputType, $includeUnpublished: Boolean) {
  lostReasons(options: $options, includeUnpublished: $includeUnpublished) {
    items {
      ...LostReason
    }
    count
  }
}
    ${LostReasonFragmentDoc}`;

/**
 * __useGetLostReasonsQuery__
 *
 * To run a query within a React component, call `useGetLostReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLostReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLostReasonsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useGetLostReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetLostReasonsQuery, GetLostReasonsQueryVariables>) {
        return Apollo.useQuery<GetLostReasonsQuery, GetLostReasonsQueryVariables>(GetLostReasonsDocument, baseOptions);
      }
export function useGetLostReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLostReasonsQuery, GetLostReasonsQueryVariables>) {
          return Apollo.useLazyQuery<GetLostReasonsQuery, GetLostReasonsQueryVariables>(GetLostReasonsDocument, baseOptions);
        }
export type GetLostReasonsQueryHookResult = ReturnType<typeof useGetLostReasonsQuery>;
export type GetLostReasonsLazyQueryHookResult = ReturnType<typeof useGetLostReasonsLazyQuery>;
export type GetLostReasonsQueryResult = Apollo.QueryResult<GetLostReasonsQuery, GetLostReasonsQueryVariables>;
export function refetchGetLostReasonsQuery(variables?: GetLostReasonsQueryVariables) {
      return { query: GetLostReasonsDocument, variables: variables }
    }
export const CreateQuoteSuccessChanceDocument = gql`
    mutation createQuoteSuccessChance($input: CreateQuoteSuccessChanceMutationInput!) {
  createQuoteSuccessChance(input: $input) {
    quoteSuccessChance {
      ...QuoteSuccessChance
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteSuccessChanceFragmentDoc}`;
export type CreateQuoteSuccessChanceMutationFn = Apollo.MutationFunction<CreateQuoteSuccessChanceMutation, CreateQuoteSuccessChanceMutationVariables>;

/**
 * __useCreateQuoteSuccessChanceMutation__
 *
 * To run a mutation, you first call `useCreateQuoteSuccessChanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteSuccessChanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteSuccessChanceMutation, { data, loading, error }] = useCreateQuoteSuccessChanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteSuccessChanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteSuccessChanceMutation, CreateQuoteSuccessChanceMutationVariables>) {
        return Apollo.useMutation<CreateQuoteSuccessChanceMutation, CreateQuoteSuccessChanceMutationVariables>(CreateQuoteSuccessChanceDocument, baseOptions);
      }
export type CreateQuoteSuccessChanceMutationHookResult = ReturnType<typeof useCreateQuoteSuccessChanceMutation>;
export type CreateQuoteSuccessChanceMutationResult = Apollo.MutationResult<CreateQuoteSuccessChanceMutation>;
export type CreateQuoteSuccessChanceMutationOptions = Apollo.BaseMutationOptions<CreateQuoteSuccessChanceMutation, CreateQuoteSuccessChanceMutationVariables>;
export const UpdateQuoteSuccessChanceDocument = gql`
    mutation updateQuoteSuccessChance($input: UpdateQuoteSuccessChanceMutationInput!) {
  updateQuoteSuccessChance(input: $input) {
    quoteSuccessChance {
      ...QuoteSuccessChance
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteSuccessChanceFragmentDoc}`;
export type UpdateQuoteSuccessChanceMutationFn = Apollo.MutationFunction<UpdateQuoteSuccessChanceMutation, UpdateQuoteSuccessChanceMutationVariables>;

/**
 * __useUpdateQuoteSuccessChanceMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteSuccessChanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteSuccessChanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteSuccessChanceMutation, { data, loading, error }] = useUpdateQuoteSuccessChanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteSuccessChanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteSuccessChanceMutation, UpdateQuoteSuccessChanceMutationVariables>) {
        return Apollo.useMutation<UpdateQuoteSuccessChanceMutation, UpdateQuoteSuccessChanceMutationVariables>(UpdateQuoteSuccessChanceDocument, baseOptions);
      }
export type UpdateQuoteSuccessChanceMutationHookResult = ReturnType<typeof useUpdateQuoteSuccessChanceMutation>;
export type UpdateQuoteSuccessChanceMutationResult = Apollo.MutationResult<UpdateQuoteSuccessChanceMutation>;
export type UpdateQuoteSuccessChanceMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteSuccessChanceMutation, UpdateQuoteSuccessChanceMutationVariables>;
export const DeleteQuoteSuccessChanceDocument = gql`
    mutation deleteQuoteSuccessChance($input: DeleteQuoteSuccessChanceMutationInput!) {
  deleteQuoteSuccessChance(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteQuoteSuccessChanceMutationFn = Apollo.MutationFunction<DeleteQuoteSuccessChanceMutation, DeleteQuoteSuccessChanceMutationVariables>;

/**
 * __useDeleteQuoteSuccessChanceMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteSuccessChanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteSuccessChanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteSuccessChanceMutation, { data, loading, error }] = useDeleteQuoteSuccessChanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuoteSuccessChanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteSuccessChanceMutation, DeleteQuoteSuccessChanceMutationVariables>) {
        return Apollo.useMutation<DeleteQuoteSuccessChanceMutation, DeleteQuoteSuccessChanceMutationVariables>(DeleteQuoteSuccessChanceDocument, baseOptions);
      }
export type DeleteQuoteSuccessChanceMutationHookResult = ReturnType<typeof useDeleteQuoteSuccessChanceMutation>;
export type DeleteQuoteSuccessChanceMutationResult = Apollo.MutationResult<DeleteQuoteSuccessChanceMutation>;
export type DeleteQuoteSuccessChanceMutationOptions = Apollo.BaseMutationOptions<DeleteQuoteSuccessChanceMutation, DeleteQuoteSuccessChanceMutationVariables>;
export const CreateBillingTypeDocument = gql`
    mutation createBillingType($input: CreateBillingTypeMutationInput!) {
  createBillingType(input: $input) {
    billingType {
      ...BillingType
    }
    errors {
      field
      messages
    }
  }
}
    ${BillingTypeFragmentDoc}`;
export type CreateBillingTypeMutationFn = Apollo.MutationFunction<CreateBillingTypeMutation, CreateBillingTypeMutationVariables>;

/**
 * __useCreateBillingTypeMutation__
 *
 * To run a mutation, you first call `useCreateBillingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingTypeMutation, { data, loading, error }] = useCreateBillingTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBillingTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillingTypeMutation, CreateBillingTypeMutationVariables>) {
        return Apollo.useMutation<CreateBillingTypeMutation, CreateBillingTypeMutationVariables>(CreateBillingTypeDocument, baseOptions);
      }
export type CreateBillingTypeMutationHookResult = ReturnType<typeof useCreateBillingTypeMutation>;
export type CreateBillingTypeMutationResult = Apollo.MutationResult<CreateBillingTypeMutation>;
export type CreateBillingTypeMutationOptions = Apollo.BaseMutationOptions<CreateBillingTypeMutation, CreateBillingTypeMutationVariables>;
export const UpdateBillingTypeDocument = gql`
    mutation updateBillingType($input: UpdateBillingTypeMutationInput!) {
  updateBillingType(input: $input) {
    billingType {
      ...BillingType
    }
    errors {
      field
      messages
    }
  }
}
    ${BillingTypeFragmentDoc}`;
export type UpdateBillingTypeMutationFn = Apollo.MutationFunction<UpdateBillingTypeMutation, UpdateBillingTypeMutationVariables>;

/**
 * __useUpdateBillingTypeMutation__
 *
 * To run a mutation, you first call `useUpdateBillingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillingTypeMutation, { data, loading, error }] = useUpdateBillingTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillingTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillingTypeMutation, UpdateBillingTypeMutationVariables>) {
        return Apollo.useMutation<UpdateBillingTypeMutation, UpdateBillingTypeMutationVariables>(UpdateBillingTypeDocument, baseOptions);
      }
export type UpdateBillingTypeMutationHookResult = ReturnType<typeof useUpdateBillingTypeMutation>;
export type UpdateBillingTypeMutationResult = Apollo.MutationResult<UpdateBillingTypeMutation>;
export type UpdateBillingTypeMutationOptions = Apollo.BaseMutationOptions<UpdateBillingTypeMutation, UpdateBillingTypeMutationVariables>;
export const DeleteBillingTypeDocument = gql`
    mutation deleteBillingType($input: DeleteBillingTypeMutationInput!) {
  deleteBillingType(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteBillingTypeMutationFn = Apollo.MutationFunction<DeleteBillingTypeMutation, DeleteBillingTypeMutationVariables>;

/**
 * __useDeleteBillingTypeMutation__
 *
 * To run a mutation, you first call `useDeleteBillingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingTypeMutation, { data, loading, error }] = useDeleteBillingTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBillingTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBillingTypeMutation, DeleteBillingTypeMutationVariables>) {
        return Apollo.useMutation<DeleteBillingTypeMutation, DeleteBillingTypeMutationVariables>(DeleteBillingTypeDocument, baseOptions);
      }
export type DeleteBillingTypeMutationHookResult = ReturnType<typeof useDeleteBillingTypeMutation>;
export type DeleteBillingTypeMutationResult = Apollo.MutationResult<DeleteBillingTypeMutation>;
export type DeleteBillingTypeMutationOptions = Apollo.BaseMutationOptions<DeleteBillingTypeMutation, DeleteBillingTypeMutationVariables>;
export const CreateLostReasonDocument = gql`
    mutation createLostReason($input: CreateLostReasonMutationInput!) {
  createLostReason(input: $input) {
    lostReason {
      ...LostReason
    }
    errors {
      field
      messages
    }
  }
}
    ${LostReasonFragmentDoc}`;
export type CreateLostReasonMutationFn = Apollo.MutationFunction<CreateLostReasonMutation, CreateLostReasonMutationVariables>;

/**
 * __useCreateLostReasonMutation__
 *
 * To run a mutation, you first call `useCreateLostReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLostReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLostReasonMutation, { data, loading, error }] = useCreateLostReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLostReasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateLostReasonMutation, CreateLostReasonMutationVariables>) {
        return Apollo.useMutation<CreateLostReasonMutation, CreateLostReasonMutationVariables>(CreateLostReasonDocument, baseOptions);
      }
export type CreateLostReasonMutationHookResult = ReturnType<typeof useCreateLostReasonMutation>;
export type CreateLostReasonMutationResult = Apollo.MutationResult<CreateLostReasonMutation>;
export type CreateLostReasonMutationOptions = Apollo.BaseMutationOptions<CreateLostReasonMutation, CreateLostReasonMutationVariables>;
export const UpdateLostReasonDocument = gql`
    mutation updateLostReason($input: UpdateLostReasonMutationInput!) {
  updateLostReason(input: $input) {
    lostReason {
      ...LostReason
    }
    errors {
      field
      messages
    }
  }
}
    ${LostReasonFragmentDoc}`;
export type UpdateLostReasonMutationFn = Apollo.MutationFunction<UpdateLostReasonMutation, UpdateLostReasonMutationVariables>;

/**
 * __useUpdateLostReasonMutation__
 *
 * To run a mutation, you first call `useUpdateLostReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLostReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLostReasonMutation, { data, loading, error }] = useUpdateLostReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLostReasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLostReasonMutation, UpdateLostReasonMutationVariables>) {
        return Apollo.useMutation<UpdateLostReasonMutation, UpdateLostReasonMutationVariables>(UpdateLostReasonDocument, baseOptions);
      }
export type UpdateLostReasonMutationHookResult = ReturnType<typeof useUpdateLostReasonMutation>;
export type UpdateLostReasonMutationResult = Apollo.MutationResult<UpdateLostReasonMutation>;
export type UpdateLostReasonMutationOptions = Apollo.BaseMutationOptions<UpdateLostReasonMutation, UpdateLostReasonMutationVariables>;
export const DeleteLostReasonDocument = gql`
    mutation deleteLostReason($input: DeleteLostReasonMutationInput!) {
  deleteLostReason(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteLostReasonMutationFn = Apollo.MutationFunction<DeleteLostReasonMutation, DeleteLostReasonMutationVariables>;

/**
 * __useDeleteLostReasonMutation__
 *
 * To run a mutation, you first call `useDeleteLostReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLostReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLostReasonMutation, { data, loading, error }] = useDeleteLostReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLostReasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLostReasonMutation, DeleteLostReasonMutationVariables>) {
        return Apollo.useMutation<DeleteLostReasonMutation, DeleteLostReasonMutationVariables>(DeleteLostReasonDocument, baseOptions);
      }
export type DeleteLostReasonMutationHookResult = ReturnType<typeof useDeleteLostReasonMutation>;
export type DeleteLostReasonMutationResult = Apollo.MutationResult<DeleteLostReasonMutation>;
export type DeleteLostReasonMutationOptions = Apollo.BaseMutationOptions<DeleteLostReasonMutation, DeleteLostReasonMutationVariables>;
export const CreateQuoteDocument = gql`
    mutation createQuote($input: CreateQuoteMutationInput!) {
  createQuote(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, baseOptions);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const UpdateQuoteDocument = gql`
    mutation updateQuote($input: UpdateQuoteMutationInput!) {
  updateQuote(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type UpdateQuoteMutationFn = Apollo.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        return Apollo.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, baseOptions);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = Apollo.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>;
export const DeleteQuoteDocument = gql`
    mutation deleteQuote($input: DeleteQuoteMutationInput!) {
  deleteQuote(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteQuoteMutationFn = Apollo.MutationFunction<DeleteQuoteMutation, DeleteQuoteMutationVariables>;

/**
 * __useDeleteQuoteMutation__
 *
 * To run a mutation, you first call `useDeleteQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuoteMutation, { data, loading, error }] = useDeleteQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>) {
        return Apollo.useMutation<DeleteQuoteMutation, DeleteQuoteMutationVariables>(DeleteQuoteDocument, baseOptions);
      }
export type DeleteQuoteMutationHookResult = ReturnType<typeof useDeleteQuoteMutation>;
export type DeleteQuoteMutationResult = Apollo.MutationResult<DeleteQuoteMutation>;
export type DeleteQuoteMutationOptions = Apollo.BaseMutationOptions<DeleteQuoteMutation, DeleteQuoteMutationVariables>;
export const CreateQuotePdfDocument = gql`
    mutation createQuotePDF($input: CreateQuotePDFMutationInput!) {
  createQuotePDF(input: $input) {
    quotePDF {
      file
      slug
      quote {
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateQuotePdfMutationFn = Apollo.MutationFunction<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>;

/**
 * __useCreateQuotePdfMutation__
 *
 * To run a mutation, you first call `useCreateQuotePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuotePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuotePdfMutation, { data, loading, error }] = useCreateQuotePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuotePdfMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>) {
        return Apollo.useMutation<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>(CreateQuotePdfDocument, baseOptions);
      }
export type CreateQuotePdfMutationHookResult = ReturnType<typeof useCreateQuotePdfMutation>;
export type CreateQuotePdfMutationResult = Apollo.MutationResult<CreateQuotePdfMutation>;
export type CreateQuotePdfMutationOptions = Apollo.BaseMutationOptions<CreateQuotePdfMutation, CreateQuotePdfMutationVariables>;
export const QuoteTransitionDocument = gql`
    mutation QuoteTransition($input: QuoteTransitionMutationInput!) {
  quoteTransition(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type QuoteTransitionMutationFn = Apollo.MutationFunction<QuoteTransitionMutation, QuoteTransitionMutationVariables>;

/**
 * __useQuoteTransitionMutation__
 *
 * To run a mutation, you first call `useQuoteTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteTransitionMutation, { data, loading, error }] = useQuoteTransitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuoteTransitionMutation(baseOptions?: Apollo.MutationHookOptions<QuoteTransitionMutation, QuoteTransitionMutationVariables>) {
        return Apollo.useMutation<QuoteTransitionMutation, QuoteTransitionMutationVariables>(QuoteTransitionDocument, baseOptions);
      }
export type QuoteTransitionMutationHookResult = ReturnType<typeof useQuoteTransitionMutation>;
export type QuoteTransitionMutationResult = Apollo.MutationResult<QuoteTransitionMutation>;
export type QuoteTransitionMutationOptions = Apollo.BaseMutationOptions<QuoteTransitionMutation, QuoteTransitionMutationVariables>;
export const StartQuoteReviewDocument = gql`
    mutation startQuoteReview($input: StartQuoteReviewMutationInput!) {
  startQuoteReview(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type StartQuoteReviewMutationFn = Apollo.MutationFunction<StartQuoteReviewMutation, StartQuoteReviewMutationVariables>;

/**
 * __useStartQuoteReviewMutation__
 *
 * To run a mutation, you first call `useStartQuoteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuoteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuoteReviewMutation, { data, loading, error }] = useStartQuoteReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartQuoteReviewMutation(baseOptions?: Apollo.MutationHookOptions<StartQuoteReviewMutation, StartQuoteReviewMutationVariables>) {
        return Apollo.useMutation<StartQuoteReviewMutation, StartQuoteReviewMutationVariables>(StartQuoteReviewDocument, baseOptions);
      }
export type StartQuoteReviewMutationHookResult = ReturnType<typeof useStartQuoteReviewMutation>;
export type StartQuoteReviewMutationResult = Apollo.MutationResult<StartQuoteReviewMutation>;
export type StartQuoteReviewMutationOptions = Apollo.BaseMutationOptions<StartQuoteReviewMutation, StartQuoteReviewMutationVariables>;
export const MarkQuoteAsLostDocument = gql`
    mutation markQuoteAsLost($input: MarkQuoteAsLostMutationInput!) {
  markQuoteAsLost(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type MarkQuoteAsLostMutationFn = Apollo.MutationFunction<MarkQuoteAsLostMutation, MarkQuoteAsLostMutationVariables>;

/**
 * __useMarkQuoteAsLostMutation__
 *
 * To run a mutation, you first call `useMarkQuoteAsLostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuoteAsLostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuoteAsLostMutation, { data, loading, error }] = useMarkQuoteAsLostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkQuoteAsLostMutation(baseOptions?: Apollo.MutationHookOptions<MarkQuoteAsLostMutation, MarkQuoteAsLostMutationVariables>) {
        return Apollo.useMutation<MarkQuoteAsLostMutation, MarkQuoteAsLostMutationVariables>(MarkQuoteAsLostDocument, baseOptions);
      }
export type MarkQuoteAsLostMutationHookResult = ReturnType<typeof useMarkQuoteAsLostMutation>;
export type MarkQuoteAsLostMutationResult = Apollo.MutationResult<MarkQuoteAsLostMutation>;
export type MarkQuoteAsLostMutationOptions = Apollo.BaseMutationOptions<MarkQuoteAsLostMutation, MarkQuoteAsLostMutationVariables>;
export const MarkQuoteAsRequestedExceptionDocument = gql`
    mutation markQuoteAsRequestedException($input: MarkQuoteAsRequestedExceptionMutationInput!) {
  markQuoteAsRequestedException(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type MarkQuoteAsRequestedExceptionMutationFn = Apollo.MutationFunction<MarkQuoteAsRequestedExceptionMutation, MarkQuoteAsRequestedExceptionMutationVariables>;

/**
 * __useMarkQuoteAsRequestedExceptionMutation__
 *
 * To run a mutation, you first call `useMarkQuoteAsRequestedExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuoteAsRequestedExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuoteAsRequestedExceptionMutation, { data, loading, error }] = useMarkQuoteAsRequestedExceptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkQuoteAsRequestedExceptionMutation(baseOptions?: Apollo.MutationHookOptions<MarkQuoteAsRequestedExceptionMutation, MarkQuoteAsRequestedExceptionMutationVariables>) {
        return Apollo.useMutation<MarkQuoteAsRequestedExceptionMutation, MarkQuoteAsRequestedExceptionMutationVariables>(MarkQuoteAsRequestedExceptionDocument, baseOptions);
      }
export type MarkQuoteAsRequestedExceptionMutationHookResult = ReturnType<typeof useMarkQuoteAsRequestedExceptionMutation>;
export type MarkQuoteAsRequestedExceptionMutationResult = Apollo.MutationResult<MarkQuoteAsRequestedExceptionMutation>;
export type MarkQuoteAsRequestedExceptionMutationOptions = Apollo.BaseMutationOptions<MarkQuoteAsRequestedExceptionMutation, MarkQuoteAsRequestedExceptionMutationVariables>;
export const TransitionQuoteWithExceptionDocument = gql`
    mutation transitionQuoteWithException($input: TransitionQuoteWithExceptionMutationInput!) {
  transitionQuoteWithException(input: $input) {
    quote {
      ...Quote
    }
    errors {
      field
      messages
    }
  }
}
    ${QuoteFragmentDoc}`;
export type TransitionQuoteWithExceptionMutationFn = Apollo.MutationFunction<TransitionQuoteWithExceptionMutation, TransitionQuoteWithExceptionMutationVariables>;

/**
 * __useTransitionQuoteWithExceptionMutation__
 *
 * To run a mutation, you first call `useTransitionQuoteWithExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionQuoteWithExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionQuoteWithExceptionMutation, { data, loading, error }] = useTransitionQuoteWithExceptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransitionQuoteWithExceptionMutation(baseOptions?: Apollo.MutationHookOptions<TransitionQuoteWithExceptionMutation, TransitionQuoteWithExceptionMutationVariables>) {
        return Apollo.useMutation<TransitionQuoteWithExceptionMutation, TransitionQuoteWithExceptionMutationVariables>(TransitionQuoteWithExceptionDocument, baseOptions);
      }
export type TransitionQuoteWithExceptionMutationHookResult = ReturnType<typeof useTransitionQuoteWithExceptionMutation>;
export type TransitionQuoteWithExceptionMutationResult = Apollo.MutationResult<TransitionQuoteWithExceptionMutation>;
export type TransitionQuoteWithExceptionMutationOptions = Apollo.BaseMutationOptions<TransitionQuoteWithExceptionMutation, TransitionQuoteWithExceptionMutationVariables>;
export const UpdateQuotePricesDocument = gql`
    mutation updateQuotePrices($input: UpdateQuotePricesMutationInput!) {
  updateQuotePrices(input: $input) {
    quote
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateQuotePricesMutationFn = Apollo.MutationFunction<UpdateQuotePricesMutation, UpdateQuotePricesMutationVariables>;

/**
 * __useUpdateQuotePricesMutation__
 *
 * To run a mutation, you first call `useUpdateQuotePricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuotePricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuotePricesMutation, { data, loading, error }] = useUpdateQuotePricesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuotePricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuotePricesMutation, UpdateQuotePricesMutationVariables>) {
        return Apollo.useMutation<UpdateQuotePricesMutation, UpdateQuotePricesMutationVariables>(UpdateQuotePricesDocument, baseOptions);
      }
export type UpdateQuotePricesMutationHookResult = ReturnType<typeof useUpdateQuotePricesMutation>;
export type UpdateQuotePricesMutationResult = Apollo.MutationResult<UpdateQuotePricesMutation>;
export type UpdateQuotePricesMutationOptions = Apollo.BaseMutationOptions<UpdateQuotePricesMutation, UpdateQuotePricesMutationVariables>;
export const QuotePdfHtmlDocument = gql`
    query quotePdfHtml($pdfType: String!, $configs: [PdfConfigInput]!) {
  quotePdfHtml(pdfType: $pdfType, configs: $configs)
}
    `;

/**
 * __useQuotePdfHtmlQuery__
 *
 * To run a query within a React component, call `useQuotePdfHtmlQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotePdfHtmlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotePdfHtmlQuery({
 *   variables: {
 *      pdfType: // value for 'pdfType'
 *      configs: // value for 'configs'
 *   },
 * });
 */
export function useQuotePdfHtmlQuery(baseOptions?: Apollo.QueryHookOptions<QuotePdfHtmlQuery, QuotePdfHtmlQueryVariables>) {
        return Apollo.useQuery<QuotePdfHtmlQuery, QuotePdfHtmlQueryVariables>(QuotePdfHtmlDocument, baseOptions);
      }
export function useQuotePdfHtmlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotePdfHtmlQuery, QuotePdfHtmlQueryVariables>) {
          return Apollo.useLazyQuery<QuotePdfHtmlQuery, QuotePdfHtmlQueryVariables>(QuotePdfHtmlDocument, baseOptions);
        }
export type QuotePdfHtmlQueryHookResult = ReturnType<typeof useQuotePdfHtmlQuery>;
export type QuotePdfHtmlLazyQueryHookResult = ReturnType<typeof useQuotePdfHtmlLazyQuery>;
export type QuotePdfHtmlQueryResult = Apollo.QueryResult<QuotePdfHtmlQuery, QuotePdfHtmlQueryVariables>;
export function refetchQuotePdfHtmlQuery(variables?: QuotePdfHtmlQueryVariables) {
      return { query: QuotePdfHtmlDocument, variables: variables }
    }
export const CreateQuoteExportDocument = gql`
    mutation createQuoteExport($options: QuoteListOptionsInputType) {
  createQuoteExport(options: $options) {
    job {
      ...ExportJob
    }
  }
}
    ${ExportJobFragmentDoc}`;
export type CreateQuoteExportMutationFn = Apollo.MutationFunction<CreateQuoteExportMutation, CreateQuoteExportMutationVariables>;

/**
 * __useCreateQuoteExportMutation__
 *
 * To run a mutation, you first call `useCreateQuoteExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteExportMutation, { data, loading, error }] = useCreateQuoteExportMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateQuoteExportMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteExportMutation, CreateQuoteExportMutationVariables>) {
        return Apollo.useMutation<CreateQuoteExportMutation, CreateQuoteExportMutationVariables>(CreateQuoteExportDocument, baseOptions);
      }
export type CreateQuoteExportMutationHookResult = ReturnType<typeof useCreateQuoteExportMutation>;
export type CreateQuoteExportMutationResult = Apollo.MutationResult<CreateQuoteExportMutation>;
export type CreateQuoteExportMutationOptions = Apollo.BaseMutationOptions<CreateQuoteExportMutation, CreateQuoteExportMutationVariables>;
export const GetSalesDocument = gql`
    query getSales($options: SaleListOptionsInputType) {
  sales(options: $options) {
    items {
      ...Sale
    }
    count
  }
}
    ${SaleFragmentDoc}`;

/**
 * __useGetSalesQuery__
 *
 * To run a query within a React component, call `useGetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesQuery, GetSalesQueryVariables>) {
        return Apollo.useQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, baseOptions);
      }
export function useGetSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesQuery, GetSalesQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, baseOptions);
        }
export type GetSalesQueryHookResult = ReturnType<typeof useGetSalesQuery>;
export type GetSalesLazyQueryHookResult = ReturnType<typeof useGetSalesLazyQuery>;
export type GetSalesQueryResult = Apollo.QueryResult<GetSalesQuery, GetSalesQueryVariables>;
export function refetchGetSalesQuery(variables?: GetSalesQueryVariables) {
      return { query: GetSalesDocument, variables: variables }
    }
export const GetSaleDocument = gql`
    query getSale($id: UUID) {
  sale(id: $id) {
    ...SaleDetail
  }
}
    ${SaleDetailFragmentDoc}`;

/**
 * __useGetSaleQuery__
 *
 * To run a query within a React component, call `useGetSaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSaleQuery(baseOptions?: Apollo.QueryHookOptions<GetSaleQuery, GetSaleQueryVariables>) {
        return Apollo.useQuery<GetSaleQuery, GetSaleQueryVariables>(GetSaleDocument, baseOptions);
      }
export function useGetSaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSaleQuery, GetSaleQueryVariables>) {
          return Apollo.useLazyQuery<GetSaleQuery, GetSaleQueryVariables>(GetSaleDocument, baseOptions);
        }
export type GetSaleQueryHookResult = ReturnType<typeof useGetSaleQuery>;
export type GetSaleLazyQueryHookResult = ReturnType<typeof useGetSaleLazyQuery>;
export type GetSaleQueryResult = Apollo.QueryResult<GetSaleQuery, GetSaleQueryVariables>;
export function refetchGetSaleQuery(variables?: GetSaleQueryVariables) {
      return { query: GetSaleDocument, variables: variables }
    }
export const GetSalePrintDocument = gql`
    query getSalePrint($id: UUID!) {
  sale(id: $id) {
    ...SalePrint
  }
}
    ${SalePrintFragmentDoc}`;

/**
 * __useGetSalePrintQuery__
 *
 * To run a query within a React component, call `useGetSalePrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalePrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalePrintQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSalePrintQuery(baseOptions?: Apollo.QueryHookOptions<GetSalePrintQuery, GetSalePrintQueryVariables>) {
        return Apollo.useQuery<GetSalePrintQuery, GetSalePrintQueryVariables>(GetSalePrintDocument, baseOptions);
      }
export function useGetSalePrintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalePrintQuery, GetSalePrintQueryVariables>) {
          return Apollo.useLazyQuery<GetSalePrintQuery, GetSalePrintQueryVariables>(GetSalePrintDocument, baseOptions);
        }
export type GetSalePrintQueryHookResult = ReturnType<typeof useGetSalePrintQuery>;
export type GetSalePrintLazyQueryHookResult = ReturnType<typeof useGetSalePrintLazyQuery>;
export type GetSalePrintQueryResult = Apollo.QueryResult<GetSalePrintQuery, GetSalePrintQueryVariables>;
export function refetchGetSalePrintQuery(variables?: GetSalePrintQueryVariables) {
      return { query: GetSalePrintDocument, variables: variables }
    }
export const GetSalesListDocument = gql`
    query getSalesList($options: SaleListOptionsInputType) {
  sales(options: $options) {
    items {
      ...SaleDetailList
    }
    count
  }
}
    ${SaleDetailListFragmentDoc}`;

/**
 * __useGetSalesListQuery__
 *
 * To run a query within a React component, call `useGetSalesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesListQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesListQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesListQuery, GetSalesListQueryVariables>) {
        return Apollo.useQuery<GetSalesListQuery, GetSalesListQueryVariables>(GetSalesListDocument, baseOptions);
      }
export function useGetSalesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesListQuery, GetSalesListQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesListQuery, GetSalesListQueryVariables>(GetSalesListDocument, baseOptions);
        }
export type GetSalesListQueryHookResult = ReturnType<typeof useGetSalesListQuery>;
export type GetSalesListLazyQueryHookResult = ReturnType<typeof useGetSalesListLazyQuery>;
export type GetSalesListQueryResult = Apollo.QueryResult<GetSalesListQuery, GetSalesListQueryVariables>;
export function refetchGetSalesListQuery(variables?: GetSalesListQueryVariables) {
      return { query: GetSalesListDocument, variables: variables }
    }
export const CreateSaleDocument = gql`
    mutation createSale($input: CreateSaleMutationInput!) {
  createSale(input: $input) {
    sale {
      ...SaleDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type CreateSaleMutationFn = Apollo.MutationFunction<CreateSaleMutation, CreateSaleMutationVariables>;

/**
 * __useCreateSaleMutation__
 *
 * To run a mutation, you first call `useCreateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleMutation, { data, loading, error }] = useCreateSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSaleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSaleMutation, CreateSaleMutationVariables>) {
        return Apollo.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(CreateSaleDocument, baseOptions);
      }
export type CreateSaleMutationHookResult = ReturnType<typeof useCreateSaleMutation>;
export type CreateSaleMutationResult = Apollo.MutationResult<CreateSaleMutation>;
export type CreateSaleMutationOptions = Apollo.BaseMutationOptions<CreateSaleMutation, CreateSaleMutationVariables>;
export const UpdateSaleDocument = gql`
    mutation updateSale($input: UpdateSaleMutationInput!) {
  updateSale(input: $input) {
    sale {
      ...SaleDetail
    }
    errors {
      field
      messages
    }
  }
}
    ${SaleDetailFragmentDoc}`;
export type UpdateSaleMutationFn = Apollo.MutationFunction<UpdateSaleMutation, UpdateSaleMutationVariables>;

/**
 * __useUpdateSaleMutation__
 *
 * To run a mutation, you first call `useUpdateSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleMutation, { data, loading, error }] = useUpdateSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSaleMutation, UpdateSaleMutationVariables>) {
        return Apollo.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(UpdateSaleDocument, baseOptions);
      }
export type UpdateSaleMutationHookResult = ReturnType<typeof useUpdateSaleMutation>;
export type UpdateSaleMutationResult = Apollo.MutationResult<UpdateSaleMutation>;
export type UpdateSaleMutationOptions = Apollo.BaseMutationOptions<UpdateSaleMutation, UpdateSaleMutationVariables>;
export const DeleteSaleDocument = gql`
    mutation deleteSale($input: DeleteSaleMutationInput!) {
  deleteSale(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteSaleMutationFn = Apollo.MutationFunction<DeleteSaleMutation, DeleteSaleMutationVariables>;

/**
 * __useDeleteSaleMutation__
 *
 * To run a mutation, you first call `useDeleteSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSaleMutation, { data, loading, error }] = useDeleteSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSaleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSaleMutation, DeleteSaleMutationVariables>) {
        return Apollo.useMutation<DeleteSaleMutation, DeleteSaleMutationVariables>(DeleteSaleDocument, baseOptions);
      }
export type DeleteSaleMutationHookResult = ReturnType<typeof useDeleteSaleMutation>;
export type DeleteSaleMutationResult = Apollo.MutationResult<DeleteSaleMutation>;
export type DeleteSaleMutationOptions = Apollo.BaseMutationOptions<DeleteSaleMutation, DeleteSaleMutationVariables>;
export const GetCurrentSeasonDocument = gql`
    query getCurrentSeason {
  currentSeason {
    ...Season
  }
}
    ${SeasonFragmentDoc}`;

/**
 * __useGetCurrentSeasonQuery__
 *
 * To run a query within a React component, call `useGetCurrentSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSeasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSeasonQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>) {
        return Apollo.useQuery<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>(GetCurrentSeasonDocument, baseOptions);
      }
export function useGetCurrentSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>(GetCurrentSeasonDocument, baseOptions);
        }
export type GetCurrentSeasonQueryHookResult = ReturnType<typeof useGetCurrentSeasonQuery>;
export type GetCurrentSeasonLazyQueryHookResult = ReturnType<typeof useGetCurrentSeasonLazyQuery>;
export type GetCurrentSeasonQueryResult = Apollo.QueryResult<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>;
export function refetchGetCurrentSeasonQuery(variables?: GetCurrentSeasonQueryVariables) {
      return { query: GetCurrentSeasonDocument, variables: variables }
    }
export const GetSeasonsDocument = gql`
    query getSeasons($options: SeasonListOptionsInputType) {
  seasons(options: $options) {
    items {
      ...Season
    }
    count
  }
}
    ${SeasonFragmentDoc}`;

/**
 * __useGetSeasonsQuery__
 *
 * To run a query within a React component, call `useGetSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
        return Apollo.useQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
      }
export function useGetSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonsQuery, GetSeasonsQueryVariables>) {
          return Apollo.useLazyQuery<GetSeasonsQuery, GetSeasonsQueryVariables>(GetSeasonsDocument, baseOptions);
        }
export type GetSeasonsQueryHookResult = ReturnType<typeof useGetSeasonsQuery>;
export type GetSeasonsLazyQueryHookResult = ReturnType<typeof useGetSeasonsLazyQuery>;
export type GetSeasonsQueryResult = Apollo.QueryResult<GetSeasonsQuery, GetSeasonsQueryVariables>;
export function refetchGetSeasonsQuery(variables?: GetSeasonsQueryVariables) {
      return { query: GetSeasonsDocument, variables: variables }
    }
export const GetSeasonDocument = gql`
    query getSeason($id: UUID!) {
  season(id: $id) {
    ...Season
  }
}
    ${SeasonFragmentDoc}`;

/**
 * __useGetSeasonQuery__
 *
 * To run a query within a React component, call `useGetSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSeasonQuery(baseOptions?: Apollo.QueryHookOptions<GetSeasonQuery, GetSeasonQueryVariables>) {
        return Apollo.useQuery<GetSeasonQuery, GetSeasonQueryVariables>(GetSeasonDocument, baseOptions);
      }
export function useGetSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeasonQuery, GetSeasonQueryVariables>) {
          return Apollo.useLazyQuery<GetSeasonQuery, GetSeasonQueryVariables>(GetSeasonDocument, baseOptions);
        }
export type GetSeasonQueryHookResult = ReturnType<typeof useGetSeasonQuery>;
export type GetSeasonLazyQueryHookResult = ReturnType<typeof useGetSeasonLazyQuery>;
export type GetSeasonQueryResult = Apollo.QueryResult<GetSeasonQuery, GetSeasonQueryVariables>;
export function refetchGetSeasonQuery(variables?: GetSeasonQueryVariables) {
      return { query: GetSeasonDocument, variables: variables }
    }
export const CreateSeasonDocument = gql`
    mutation createSeason($input: CreateSeasonMutationInput!) {
  createSeason(input: $input) {
    season {
      ...Season
    }
    errors {
      field
      messages
    }
  }
}
    ${SeasonFragmentDoc}`;
export type CreateSeasonMutationFn = Apollo.MutationFunction<CreateSeasonMutation, CreateSeasonMutationVariables>;

/**
 * __useCreateSeasonMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMutation, { data, loading, error }] = useCreateSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonMutation, CreateSeasonMutationVariables>) {
        return Apollo.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(CreateSeasonDocument, baseOptions);
      }
export type CreateSeasonMutationHookResult = ReturnType<typeof useCreateSeasonMutation>;
export type CreateSeasonMutationResult = Apollo.MutationResult<CreateSeasonMutation>;
export type CreateSeasonMutationOptions = Apollo.BaseMutationOptions<CreateSeasonMutation, CreateSeasonMutationVariables>;
export const UpdateSeasonDocument = gql`
    mutation updateSeason($input: UpdateSeasonMutationInput!) {
  updateSeason(input: $input) {
    season {
      ...Season
    }
    errors {
      field
      messages
    }
  }
}
    ${SeasonFragmentDoc}`;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<UpdateSeasonMutation, UpdateSeasonMutationVariables>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>) {
        return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument, baseOptions);
      }
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>;
export const DeleteSeasonDocument = gql`
    mutation deleteSeason($input: DeleteSeasonMutationInput!) {
  deleteSeason(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteSeasonMutationFn = Apollo.MutationFunction<DeleteSeasonMutation, DeleteSeasonMutationVariables>;

/**
 * __useDeleteSeasonMutation__
 *
 * To run a mutation, you first call `useDeleteSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeasonMutation, { data, loading, error }] = useDeleteSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSeasonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>) {
        return Apollo.useMutation<DeleteSeasonMutation, DeleteSeasonMutationVariables>(DeleteSeasonDocument, baseOptions);
      }
export type DeleteSeasonMutationHookResult = ReturnType<typeof useDeleteSeasonMutation>;
export type DeleteSeasonMutationResult = Apollo.MutationResult<DeleteSeasonMutation>;
export type DeleteSeasonMutationOptions = Apollo.BaseMutationOptions<DeleteSeasonMutation, DeleteSeasonMutationVariables>;
export const GetSalesTargetsDocument = gql`
    query getSalesTargets($options: SalesTargetListOptionsInputType) {
  salesTargets(options: $options) {
    items {
      ...SalesTarget
    }
    count
  }
}
    ${SalesTargetFragmentDoc}`;

/**
 * __useGetSalesTargetsQuery__
 *
 * To run a query within a React component, call `useGetSalesTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesTargetsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSalesTargetsQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>) {
        return Apollo.useQuery<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>(GetSalesTargetsDocument, baseOptions);
      }
export function useGetSalesTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>(GetSalesTargetsDocument, baseOptions);
        }
export type GetSalesTargetsQueryHookResult = ReturnType<typeof useGetSalesTargetsQuery>;
export type GetSalesTargetsLazyQueryHookResult = ReturnType<typeof useGetSalesTargetsLazyQuery>;
export type GetSalesTargetsQueryResult = Apollo.QueryResult<GetSalesTargetsQuery, GetSalesTargetsQueryVariables>;
export function refetchGetSalesTargetsQuery(variables?: GetSalesTargetsQueryVariables) {
      return { query: GetSalesTargetsDocument, variables: variables }
    }
export const GetSalesTargetDocument = gql`
    query getSalesTarget($id: UUID!) {
  salesTarget(id: $id) {
    ...SalesTarget
  }
}
    ${SalesTargetFragmentDoc}`;

/**
 * __useGetSalesTargetQuery__
 *
 * To run a query within a React component, call `useGetSalesTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesTargetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSalesTargetQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesTargetQuery, GetSalesTargetQueryVariables>) {
        return Apollo.useQuery<GetSalesTargetQuery, GetSalesTargetQueryVariables>(GetSalesTargetDocument, baseOptions);
      }
export function useGetSalesTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesTargetQuery, GetSalesTargetQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesTargetQuery, GetSalesTargetQueryVariables>(GetSalesTargetDocument, baseOptions);
        }
export type GetSalesTargetQueryHookResult = ReturnType<typeof useGetSalesTargetQuery>;
export type GetSalesTargetLazyQueryHookResult = ReturnType<typeof useGetSalesTargetLazyQuery>;
export type GetSalesTargetQueryResult = Apollo.QueryResult<GetSalesTargetQuery, GetSalesTargetQueryVariables>;
export function refetchGetSalesTargetQuery(variables?: GetSalesTargetQueryVariables) {
      return { query: GetSalesTargetDocument, variables: variables }
    }
export const GetSalesTargetsByUserDocument = gql`
    query getSalesTargetsByUser($id: UUID!) {
  salesTargetsByUser(id: $id) {
    ...SalesTargetsByUser
  }
}
    ${SalesTargetsByUserFragmentDoc}`;

/**
 * __useGetSalesTargetsByUserQuery__
 *
 * To run a query within a React component, call `useGetSalesTargetsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesTargetsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesTargetsByUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSalesTargetsByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesTargetsByUserQuery, GetSalesTargetsByUserQueryVariables>) {
        return Apollo.useQuery<GetSalesTargetsByUserQuery, GetSalesTargetsByUserQueryVariables>(GetSalesTargetsByUserDocument, baseOptions);
      }
export function useGetSalesTargetsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesTargetsByUserQuery, GetSalesTargetsByUserQueryVariables>) {
          return Apollo.useLazyQuery<GetSalesTargetsByUserQuery, GetSalesTargetsByUserQueryVariables>(GetSalesTargetsByUserDocument, baseOptions);
        }
export type GetSalesTargetsByUserQueryHookResult = ReturnType<typeof useGetSalesTargetsByUserQuery>;
export type GetSalesTargetsByUserLazyQueryHookResult = ReturnType<typeof useGetSalesTargetsByUserLazyQuery>;
export type GetSalesTargetsByUserQueryResult = Apollo.QueryResult<GetSalesTargetsByUserQuery, GetSalesTargetsByUserQueryVariables>;
export function refetchGetSalesTargetsByUserQuery(variables?: GetSalesTargetsByUserQueryVariables) {
      return { query: GetSalesTargetsByUserDocument, variables: variables }
    }
export const GetCurrentUsersSalesTargetsDocument = gql`
    query getCurrentUsersSalesTargets($options: CurrentUsersSalesTargetsListOptionsInputType, $includeSelf: Boolean) {
  currentUsersSalesTargets(options: $options) {
    items {
      ...CurrentUsersSalesTargets
    }
    count
  }
}
    ${CurrentUsersSalesTargetsFragmentDoc}`;

/**
 * __useGetCurrentUsersSalesTargetsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUsersSalesTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUsersSalesTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUsersSalesTargetsQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeSelf: // value for 'includeSelf'
 *   },
 * });
 */
export function useGetCurrentUsersSalesTargetsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUsersSalesTargetsQuery, GetCurrentUsersSalesTargetsQueryVariables>) {
        return Apollo.useQuery<GetCurrentUsersSalesTargetsQuery, GetCurrentUsersSalesTargetsQueryVariables>(GetCurrentUsersSalesTargetsDocument, baseOptions);
      }
export function useGetCurrentUsersSalesTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUsersSalesTargetsQuery, GetCurrentUsersSalesTargetsQueryVariables>) {
          return Apollo.useLazyQuery<GetCurrentUsersSalesTargetsQuery, GetCurrentUsersSalesTargetsQueryVariables>(GetCurrentUsersSalesTargetsDocument, baseOptions);
        }
export type GetCurrentUsersSalesTargetsQueryHookResult = ReturnType<typeof useGetCurrentUsersSalesTargetsQuery>;
export type GetCurrentUsersSalesTargetsLazyQueryHookResult = ReturnType<typeof useGetCurrentUsersSalesTargetsLazyQuery>;
export type GetCurrentUsersSalesTargetsQueryResult = Apollo.QueryResult<GetCurrentUsersSalesTargetsQuery, GetCurrentUsersSalesTargetsQueryVariables>;
export function refetchGetCurrentUsersSalesTargetsQuery(variables?: GetCurrentUsersSalesTargetsQueryVariables) {
      return { query: GetCurrentUsersSalesTargetsDocument, variables: variables }
    }
export const GetAggregatedSalesTargetsDocument = gql`
    query getAggregatedSalesTargets {
  aggregatedSalesTargets {
    ...AggregatedSalesTarget
  }
}
    ${AggregatedSalesTargetFragmentDoc}`;

/**
 * __useGetAggregatedSalesTargetsQuery__
 *
 * To run a query within a React component, call `useGetAggregatedSalesTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregatedSalesTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregatedSalesTargetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAggregatedSalesTargetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAggregatedSalesTargetsQuery, GetAggregatedSalesTargetsQueryVariables>) {
        return Apollo.useQuery<GetAggregatedSalesTargetsQuery, GetAggregatedSalesTargetsQueryVariables>(GetAggregatedSalesTargetsDocument, baseOptions);
      }
export function useGetAggregatedSalesTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggregatedSalesTargetsQuery, GetAggregatedSalesTargetsQueryVariables>) {
          return Apollo.useLazyQuery<GetAggregatedSalesTargetsQuery, GetAggregatedSalesTargetsQueryVariables>(GetAggregatedSalesTargetsDocument, baseOptions);
        }
export type GetAggregatedSalesTargetsQueryHookResult = ReturnType<typeof useGetAggregatedSalesTargetsQuery>;
export type GetAggregatedSalesTargetsLazyQueryHookResult = ReturnType<typeof useGetAggregatedSalesTargetsLazyQuery>;
export type GetAggregatedSalesTargetsQueryResult = Apollo.QueryResult<GetAggregatedSalesTargetsQuery, GetAggregatedSalesTargetsQueryVariables>;
export function refetchGetAggregatedSalesTargetsQuery(variables?: GetAggregatedSalesTargetsQueryVariables) {
      return { query: GetAggregatedSalesTargetsDocument, variables: variables }
    }
export const CreateSalesTargetDocument = gql`
    mutation createSalesTarget($input: CreateSalesTargetMutationInput!) {
  createSalesTarget(input: $input) {
    salesTarget {
      ...SalesTarget
    }
    errors {
      field
      messages
    }
  }
}
    ${SalesTargetFragmentDoc}`;
export type CreateSalesTargetMutationFn = Apollo.MutationFunction<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>;

/**
 * __useCreateSalesTargetMutation__
 *
 * To run a mutation, you first call `useCreateSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesTargetMutation, { data, loading, error }] = useCreateSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>) {
        return Apollo.useMutation<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>(CreateSalesTargetDocument, baseOptions);
      }
export type CreateSalesTargetMutationHookResult = ReturnType<typeof useCreateSalesTargetMutation>;
export type CreateSalesTargetMutationResult = Apollo.MutationResult<CreateSalesTargetMutation>;
export type CreateSalesTargetMutationOptions = Apollo.BaseMutationOptions<CreateSalesTargetMutation, CreateSalesTargetMutationVariables>;
export const CreateMultipleSalesTargetsDocument = gql`
    mutation createMultipleSalesTargets($salesTargets: [SalesTargetFormInput!]!, $deleteSalesTargets: [UUID!]) {
  createMultipleSalesTargets(salesTargets: $salesTargets, deleteSalesTargets: $deleteSalesTargets) {
    salesTargets {
      ...SalesTarget
    }
    errors {
      field
      messages
    }
  }
}
    ${SalesTargetFragmentDoc}`;
export type CreateMultipleSalesTargetsMutationFn = Apollo.MutationFunction<CreateMultipleSalesTargetsMutation, CreateMultipleSalesTargetsMutationVariables>;

/**
 * __useCreateMultipleSalesTargetsMutation__
 *
 * To run a mutation, you first call `useCreateMultipleSalesTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipleSalesTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipleSalesTargetsMutation, { data, loading, error }] = useCreateMultipleSalesTargetsMutation({
 *   variables: {
 *      salesTargets: // value for 'salesTargets'
 *      deleteSalesTargets: // value for 'deleteSalesTargets'
 *   },
 * });
 */
export function useCreateMultipleSalesTargetsMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultipleSalesTargetsMutation, CreateMultipleSalesTargetsMutationVariables>) {
        return Apollo.useMutation<CreateMultipleSalesTargetsMutation, CreateMultipleSalesTargetsMutationVariables>(CreateMultipleSalesTargetsDocument, baseOptions);
      }
export type CreateMultipleSalesTargetsMutationHookResult = ReturnType<typeof useCreateMultipleSalesTargetsMutation>;
export type CreateMultipleSalesTargetsMutationResult = Apollo.MutationResult<CreateMultipleSalesTargetsMutation>;
export type CreateMultipleSalesTargetsMutationOptions = Apollo.BaseMutationOptions<CreateMultipleSalesTargetsMutation, CreateMultipleSalesTargetsMutationVariables>;
export const UpdateSalesTargetDocument = gql`
    mutation updateSalesTarget($input: UpdateSalesTargetMutationInput!) {
  updateSalesTarget(input: $input) {
    salesTarget {
      ...SalesTarget
    }
    errors {
      field
      messages
    }
  }
}
    ${SalesTargetFragmentDoc}`;
export type UpdateSalesTargetMutationFn = Apollo.MutationFunction<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>;

/**
 * __useUpdateSalesTargetMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTargetMutation, { data, loading, error }] = useUpdateSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>) {
        return Apollo.useMutation<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>(UpdateSalesTargetDocument, baseOptions);
      }
export type UpdateSalesTargetMutationHookResult = ReturnType<typeof useUpdateSalesTargetMutation>;
export type UpdateSalesTargetMutationResult = Apollo.MutationResult<UpdateSalesTargetMutation>;
export type UpdateSalesTargetMutationOptions = Apollo.BaseMutationOptions<UpdateSalesTargetMutation, UpdateSalesTargetMutationVariables>;
export const DeleteSalesTargetDocument = gql`
    mutation deleteSalesTarget($input: DeleteSalesTargetMutationInput!) {
  deleteSalesTarget(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteSalesTargetMutationFn = Apollo.MutationFunction<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>;

/**
 * __useDeleteSalesTargetMutation__
 *
 * To run a mutation, you first call `useDeleteSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesTargetMutation, { data, loading, error }] = useDeleteSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>) {
        return Apollo.useMutation<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>(DeleteSalesTargetDocument, baseOptions);
      }
export type DeleteSalesTargetMutationHookResult = ReturnType<typeof useDeleteSalesTargetMutation>;
export type DeleteSalesTargetMutationResult = Apollo.MutationResult<DeleteSalesTargetMutation>;
export type DeleteSalesTargetMutationOptions = Apollo.BaseMutationOptions<DeleteSalesTargetMutation, DeleteSalesTargetMutationVariables>;
export const DeleteMultipleSalesTargetsDocument = gql`
    mutation deleteMultipleSalesTargets($salesTargets: [UUID!]!) {
  deleteMultipleSalesTargets(salesTargets: $salesTargets) {
    ok
  }
}
    `;
export type DeleteMultipleSalesTargetsMutationFn = Apollo.MutationFunction<DeleteMultipleSalesTargetsMutation, DeleteMultipleSalesTargetsMutationVariables>;

/**
 * __useDeleteMultipleSalesTargetsMutation__
 *
 * To run a mutation, you first call `useDeleteMultipleSalesTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMultipleSalesTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMultipleSalesTargetsMutation, { data, loading, error }] = useDeleteMultipleSalesTargetsMutation({
 *   variables: {
 *      salesTargets: // value for 'salesTargets'
 *   },
 * });
 */
export function useDeleteMultipleSalesTargetsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMultipleSalesTargetsMutation, DeleteMultipleSalesTargetsMutationVariables>) {
        return Apollo.useMutation<DeleteMultipleSalesTargetsMutation, DeleteMultipleSalesTargetsMutationVariables>(DeleteMultipleSalesTargetsDocument, baseOptions);
      }
export type DeleteMultipleSalesTargetsMutationHookResult = ReturnType<typeof useDeleteMultipleSalesTargetsMutation>;
export type DeleteMultipleSalesTargetsMutationResult = Apollo.MutationResult<DeleteMultipleSalesTargetsMutation>;
export type DeleteMultipleSalesTargetsMutationOptions = Apollo.BaseMutationOptions<DeleteMultipleSalesTargetsMutation, DeleteMultipleSalesTargetsMutationVariables>;
export const ImportSalesTargetDocument = gql`
    mutation importSalesTarget($input: ImportSalesTargetMutationInput!) {
  importSalesTarget(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ImportSalesTargetMutationFn = Apollo.MutationFunction<ImportSalesTargetMutation, ImportSalesTargetMutationVariables>;

/**
 * __useImportSalesTargetMutation__
 *
 * To run a mutation, you first call `useImportSalesTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSalesTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSalesTargetMutation, { data, loading, error }] = useImportSalesTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSalesTargetMutation(baseOptions?: Apollo.MutationHookOptions<ImportSalesTargetMutation, ImportSalesTargetMutationVariables>) {
        return Apollo.useMutation<ImportSalesTargetMutation, ImportSalesTargetMutationVariables>(ImportSalesTargetDocument, baseOptions);
      }
export type ImportSalesTargetMutationHookResult = ReturnType<typeof useImportSalesTargetMutation>;
export type ImportSalesTargetMutationResult = Apollo.MutationResult<ImportSalesTargetMutation>;
export type ImportSalesTargetMutationOptions = Apollo.BaseMutationOptions<ImportSalesTargetMutation, ImportSalesTargetMutationVariables>;
export const CreateSalePdfDocument = gql`
    mutation createSalePDF($input: CreateSalePDFMutationInput!) {
  createSalePDF(input: $input) {
    salePDF {
      file
      slug
      sale {
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateSalePdfMutationFn = Apollo.MutationFunction<CreateSalePdfMutation, CreateSalePdfMutationVariables>;

/**
 * __useCreateSalePdfMutation__
 *
 * To run a mutation, you first call `useCreateSalePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalePdfMutation, { data, loading, error }] = useCreateSalePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalePdfMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalePdfMutation, CreateSalePdfMutationVariables>) {
        return Apollo.useMutation<CreateSalePdfMutation, CreateSalePdfMutationVariables>(CreateSalePdfDocument, baseOptions);
      }
export type CreateSalePdfMutationHookResult = ReturnType<typeof useCreateSalePdfMutation>;
export type CreateSalePdfMutationResult = Apollo.MutationResult<CreateSalePdfMutation>;
export type CreateSalePdfMutationOptions = Apollo.BaseMutationOptions<CreateSalePdfMutation, CreateSalePdfMutationVariables>;
export const AnnulSaleDocument = gql`
    mutation annulSale($input: AnnulSaleMutationInput!) {
  annulSale(input: $input) {
    sale {
      id
    }
  }
}
    `;
export type AnnulSaleMutationFn = Apollo.MutationFunction<AnnulSaleMutation, AnnulSaleMutationVariables>;

/**
 * __useAnnulSaleMutation__
 *
 * To run a mutation, you first call `useAnnulSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnnulSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [annulSaleMutation, { data, loading, error }] = useAnnulSaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnnulSaleMutation(baseOptions?: Apollo.MutationHookOptions<AnnulSaleMutation, AnnulSaleMutationVariables>) {
        return Apollo.useMutation<AnnulSaleMutation, AnnulSaleMutationVariables>(AnnulSaleDocument, baseOptions);
      }
export type AnnulSaleMutationHookResult = ReturnType<typeof useAnnulSaleMutation>;
export type AnnulSaleMutationResult = Apollo.MutationResult<AnnulSaleMutation>;
export type AnnulSaleMutationOptions = Apollo.BaseMutationOptions<AnnulSaleMutation, AnnulSaleMutationVariables>;
export const CreateSaleExportDocument = gql`
    mutation createSaleExport($options: SaleListOptionsInputType) {
  createSaleExport(options: $options) {
    job {
      ...ExportJob
    }
  }
}
    ${ExportJobFragmentDoc}`;
export type CreateSaleExportMutationFn = Apollo.MutationFunction<CreateSaleExportMutation, CreateSaleExportMutationVariables>;

/**
 * __useCreateSaleExportMutation__
 *
 * To run a mutation, you first call `useCreateSaleExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSaleExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSaleExportMutation, { data, loading, error }] = useCreateSaleExportMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateSaleExportMutation(baseOptions?: Apollo.MutationHookOptions<CreateSaleExportMutation, CreateSaleExportMutationVariables>) {
        return Apollo.useMutation<CreateSaleExportMutation, CreateSaleExportMutationVariables>(CreateSaleExportDocument, baseOptions);
      }
export type CreateSaleExportMutationHookResult = ReturnType<typeof useCreateSaleExportMutation>;
export type CreateSaleExportMutationResult = Apollo.MutationResult<CreateSaleExportMutation>;
export type CreateSaleExportMutationOptions = Apollo.BaseMutationOptions<CreateSaleExportMutation, CreateSaleExportMutationVariables>;
export const GetFeatureTutorialDocument = gql`
    query getFeatureTutorial($codename: String!) {
  featureTutorial(codename: $codename) {
    ...FeatureTutorial
  }
}
    ${FeatureTutorialFragmentDoc}`;

/**
 * __useGetFeatureTutorialQuery__
 *
 * To run a query within a React component, call `useGetFeatureTutorialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureTutorialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureTutorialQuery({
 *   variables: {
 *      codename: // value for 'codename'
 *   },
 * });
 */
export function useGetFeatureTutorialQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureTutorialQuery, GetFeatureTutorialQueryVariables>) {
        return Apollo.useQuery<GetFeatureTutorialQuery, GetFeatureTutorialQueryVariables>(GetFeatureTutorialDocument, baseOptions);
      }
export function useGetFeatureTutorialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureTutorialQuery, GetFeatureTutorialQueryVariables>) {
          return Apollo.useLazyQuery<GetFeatureTutorialQuery, GetFeatureTutorialQueryVariables>(GetFeatureTutorialDocument, baseOptions);
        }
export type GetFeatureTutorialQueryHookResult = ReturnType<typeof useGetFeatureTutorialQuery>;
export type GetFeatureTutorialLazyQueryHookResult = ReturnType<typeof useGetFeatureTutorialLazyQuery>;
export type GetFeatureTutorialQueryResult = Apollo.QueryResult<GetFeatureTutorialQuery, GetFeatureTutorialQueryVariables>;
export function refetchGetFeatureTutorialQuery(variables?: GetFeatureTutorialQueryVariables) {
      return { query: GetFeatureTutorialDocument, variables: variables }
    }
export const GetExportFileJobDocument = gql`
    query getExportFileJob($id: String!) {
  exportFileJob(id: $id) {
    ...ExportJob
  }
}
    ${ExportJobFragmentDoc}`;

/**
 * __useGetExportFileJobQuery__
 *
 * To run a query within a React component, call `useGetExportFileJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportFileJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportFileJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExportFileJobQuery(baseOptions?: Apollo.QueryHookOptions<GetExportFileJobQuery, GetExportFileJobQueryVariables>) {
        return Apollo.useQuery<GetExportFileJobQuery, GetExportFileJobQueryVariables>(GetExportFileJobDocument, baseOptions);
      }
export function useGetExportFileJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExportFileJobQuery, GetExportFileJobQueryVariables>) {
          return Apollo.useLazyQuery<GetExportFileJobQuery, GetExportFileJobQueryVariables>(GetExportFileJobDocument, baseOptions);
        }
export type GetExportFileJobQueryHookResult = ReturnType<typeof useGetExportFileJobQuery>;
export type GetExportFileJobLazyQueryHookResult = ReturnType<typeof useGetExportFileJobLazyQuery>;
export type GetExportFileJobQueryResult = Apollo.QueryResult<GetExportFileJobQuery, GetExportFileJobQueryVariables>;
export function refetchGetExportFileJobQuery(variables?: GetExportFileJobQueryVariables) {
      return { query: GetExportFileJobDocument, variables: variables }
    }
export const GetActiveTenantDocument = gql`
    query getActiveTenant {
  activeTenant {
    ...Tenant
  }
}
    ${TenantFragmentDoc}`;

/**
 * __useGetActiveTenantQuery__
 *
 * To run a query within a React component, call `useGetActiveTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTenantQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveTenantQuery, GetActiveTenantQueryVariables>) {
        return Apollo.useQuery<GetActiveTenantQuery, GetActiveTenantQueryVariables>(GetActiveTenantDocument, baseOptions);
      }
export function useGetActiveTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTenantQuery, GetActiveTenantQueryVariables>) {
          return Apollo.useLazyQuery<GetActiveTenantQuery, GetActiveTenantQueryVariables>(GetActiveTenantDocument, baseOptions);
        }
export type GetActiveTenantQueryHookResult = ReturnType<typeof useGetActiveTenantQuery>;
export type GetActiveTenantLazyQueryHookResult = ReturnType<typeof useGetActiveTenantLazyQuery>;
export type GetActiveTenantQueryResult = Apollo.QueryResult<GetActiveTenantQuery, GetActiveTenantQueryVariables>;
export function refetchGetActiveTenantQuery(variables?: GetActiveTenantQueryVariables) {
      return { query: GetActiveTenantDocument, variables: variables }
    }
export const UpdateQuoteDisclaimerDocument = gql`
    mutation updateQuoteDisclaimer($input: UpdateQuoteDisclaimerMutationInput!) {
  updateQuoteDisclaimer(input: $input) {
    tenant {
      id
      quotePdfDisclaimer
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateQuoteDisclaimerMutationFn = Apollo.MutationFunction<UpdateQuoteDisclaimerMutation, UpdateQuoteDisclaimerMutationVariables>;

/**
 * __useUpdateQuoteDisclaimerMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteDisclaimerMutation, { data, loading, error }] = useUpdateQuoteDisclaimerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteDisclaimerMutation, UpdateQuoteDisclaimerMutationVariables>) {
        return Apollo.useMutation<UpdateQuoteDisclaimerMutation, UpdateQuoteDisclaimerMutationVariables>(UpdateQuoteDisclaimerDocument, baseOptions);
      }
export type UpdateQuoteDisclaimerMutationHookResult = ReturnType<typeof useUpdateQuoteDisclaimerMutation>;
export type UpdateQuoteDisclaimerMutationResult = Apollo.MutationResult<UpdateQuoteDisclaimerMutation>;
export type UpdateQuoteDisclaimerMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteDisclaimerMutation, UpdateQuoteDisclaimerMutationVariables>;
export const UpdateSaleDisclaimerDocument = gql`
    mutation updateSaleDisclaimer($input: UpdateSaleDisclaimerMutationInput!) {
  updateSaleDisclaimer(input: $input) {
    tenant {
      id
      salePdfDisclaimer
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateSaleDisclaimerMutationFn = Apollo.MutationFunction<UpdateSaleDisclaimerMutation, UpdateSaleDisclaimerMutationVariables>;

/**
 * __useUpdateSaleDisclaimerMutation__
 *
 * To run a mutation, you first call `useUpdateSaleDisclaimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleDisclaimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSaleDisclaimerMutation, { data, loading, error }] = useUpdateSaleDisclaimerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSaleDisclaimerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSaleDisclaimerMutation, UpdateSaleDisclaimerMutationVariables>) {
        return Apollo.useMutation<UpdateSaleDisclaimerMutation, UpdateSaleDisclaimerMutationVariables>(UpdateSaleDisclaimerDocument, baseOptions);
      }
export type UpdateSaleDisclaimerMutationHookResult = ReturnType<typeof useUpdateSaleDisclaimerMutation>;
export type UpdateSaleDisclaimerMutationResult = Apollo.MutationResult<UpdateSaleDisclaimerMutation>;
export type UpdateSaleDisclaimerMutationOptions = Apollo.BaseMutationOptions<UpdateSaleDisclaimerMutation, UpdateSaleDisclaimerMutationVariables>;
export const UpdateQuoteExpirationDaysDocument = gql`
    mutation updateQuoteExpirationDays($input: UpdateQuoteExpirationDaysFormMutationInput!) {
  updateQuoteExpirationDays(input: $input) {
    tenant {
      id
      quoteExpirationDays
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateQuoteExpirationDaysMutationFn = Apollo.MutationFunction<UpdateQuoteExpirationDaysMutation, UpdateQuoteExpirationDaysMutationVariables>;

/**
 * __useUpdateQuoteExpirationDaysMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteExpirationDaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteExpirationDaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteExpirationDaysMutation, { data, loading, error }] = useUpdateQuoteExpirationDaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteExpirationDaysMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteExpirationDaysMutation, UpdateQuoteExpirationDaysMutationVariables>) {
        return Apollo.useMutation<UpdateQuoteExpirationDaysMutation, UpdateQuoteExpirationDaysMutationVariables>(UpdateQuoteExpirationDaysDocument, baseOptions);
      }
export type UpdateQuoteExpirationDaysMutationHookResult = ReturnType<typeof useUpdateQuoteExpirationDaysMutation>;
export type UpdateQuoteExpirationDaysMutationResult = Apollo.MutationResult<UpdateQuoteExpirationDaysMutation>;
export type UpdateQuoteExpirationDaysMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteExpirationDaysMutation, UpdateQuoteExpirationDaysMutationVariables>;
export const LogoDocument = gql`
    mutation logo($input: LogoMutationInput!) {
  logo(input: $input) {
    tenant {
      id
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type LogoMutationFn = Apollo.MutationFunction<LogoMutation, LogoMutationVariables>;

/**
 * __useLogoMutation__
 *
 * To run a mutation, you first call `useLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoMutation, { data, loading, error }] = useLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogoMutation(baseOptions?: Apollo.MutationHookOptions<LogoMutation, LogoMutationVariables>) {
        return Apollo.useMutation<LogoMutation, LogoMutationVariables>(LogoDocument, baseOptions);
      }
export type LogoMutationHookResult = ReturnType<typeof useLogoMutation>;
export type LogoMutationResult = Apollo.MutationResult<LogoMutation>;
export type LogoMutationOptions = Apollo.BaseMutationOptions<LogoMutation, LogoMutationVariables>;
export const UpdatePdfConfigDocument = gql`
    mutation updatePdfConfig($input: [PdfConfigInput!]!) {
  updatePdfConfig(pdfConfigs: $input) {
    ok
  }
}
    `;
export type UpdatePdfConfigMutationFn = Apollo.MutationFunction<UpdatePdfConfigMutation, UpdatePdfConfigMutationVariables>;

/**
 * __useUpdatePdfConfigMutation__
 *
 * To run a mutation, you first call `useUpdatePdfConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePdfConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePdfConfigMutation, { data, loading, error }] = useUpdatePdfConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePdfConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePdfConfigMutation, UpdatePdfConfigMutationVariables>) {
        return Apollo.useMutation<UpdatePdfConfigMutation, UpdatePdfConfigMutationVariables>(UpdatePdfConfigDocument, baseOptions);
      }
export type UpdatePdfConfigMutationHookResult = ReturnType<typeof useUpdatePdfConfigMutation>;
export type UpdatePdfConfigMutationResult = Apollo.MutationResult<UpdatePdfConfigMutation>;
export type UpdatePdfConfigMutationOptions = Apollo.BaseMutationOptions<UpdatePdfConfigMutation, UpdatePdfConfigMutationVariables>;
export const GetPdfConfigsDocument = gql`
    query getPdfConfigs {
  pdfConfigs {
    ...PdfConfig
  }
}
    ${PdfConfigFragmentDoc}`;

/**
 * __useGetPdfConfigsQuery__
 *
 * To run a query within a React component, call `useGetPdfConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdfConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdfConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPdfConfigsQuery(baseOptions?: Apollo.QueryHookOptions<GetPdfConfigsQuery, GetPdfConfigsQueryVariables>) {
        return Apollo.useQuery<GetPdfConfigsQuery, GetPdfConfigsQueryVariables>(GetPdfConfigsDocument, baseOptions);
      }
export function useGetPdfConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPdfConfigsQuery, GetPdfConfigsQueryVariables>) {
          return Apollo.useLazyQuery<GetPdfConfigsQuery, GetPdfConfigsQueryVariables>(GetPdfConfigsDocument, baseOptions);
        }
export type GetPdfConfigsQueryHookResult = ReturnType<typeof useGetPdfConfigsQuery>;
export type GetPdfConfigsLazyQueryHookResult = ReturnType<typeof useGetPdfConfigsLazyQuery>;
export type GetPdfConfigsQueryResult = Apollo.QueryResult<GetPdfConfigsQuery, GetPdfConfigsQueryVariables>;
export function refetchGetPdfConfigsQuery(variables?: GetPdfConfigsQueryVariables) {
      return { query: GetPdfConfigsDocument, variables: variables }
    }
export const GetAuthUserDocument = gql`
    query getAuthUser {
  authUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetAuthUserQuery__
 *
 * To run a query within a React component, call `useGetAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthUserQuery, GetAuthUserQueryVariables>) {
        return Apollo.useQuery<GetAuthUserQuery, GetAuthUserQueryVariables>(GetAuthUserDocument, baseOptions);
      }
export function useGetAuthUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthUserQuery, GetAuthUserQueryVariables>) {
          return Apollo.useLazyQuery<GetAuthUserQuery, GetAuthUserQueryVariables>(GetAuthUserDocument, baseOptions);
        }
export type GetAuthUserQueryHookResult = ReturnType<typeof useGetAuthUserQuery>;
export type GetAuthUserLazyQueryHookResult = ReturnType<typeof useGetAuthUserLazyQuery>;
export type GetAuthUserQueryResult = Apollo.QueryResult<GetAuthUserQuery, GetAuthUserQueryVariables>;
export function refetchGetAuthUserQuery(variables?: GetAuthUserQueryVariables) {
      return { query: GetAuthUserDocument, variables: variables }
    }
export const GetCustomPermissionsDocument = gql`
    query getCustomPermissions($options: PermissionListOptionsInputType) {
  customPermissions(options: $options) {
    items {
      ...Permission
    }
  }
}
    ${PermissionFragmentDoc}`;

/**
 * __useGetCustomPermissionsQuery__
 *
 * To run a query within a React component, call `useGetCustomPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomPermissionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetCustomPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomPermissionsQuery, GetCustomPermissionsQueryVariables>) {
        return Apollo.useQuery<GetCustomPermissionsQuery, GetCustomPermissionsQueryVariables>(GetCustomPermissionsDocument, baseOptions);
      }
export function useGetCustomPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomPermissionsQuery, GetCustomPermissionsQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomPermissionsQuery, GetCustomPermissionsQueryVariables>(GetCustomPermissionsDocument, baseOptions);
        }
export type GetCustomPermissionsQueryHookResult = ReturnType<typeof useGetCustomPermissionsQuery>;
export type GetCustomPermissionsLazyQueryHookResult = ReturnType<typeof useGetCustomPermissionsLazyQuery>;
export type GetCustomPermissionsQueryResult = Apollo.QueryResult<GetCustomPermissionsQuery, GetCustomPermissionsQueryVariables>;
export function refetchGetCustomPermissionsQuery(variables?: GetCustomPermissionsQueryVariables) {
      return { query: GetCustomPermissionsDocument, variables: variables }
    }
export const GetUserDocument = gql`
    query getUser($id: UUID!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export function refetchGetUserQuery(variables?: GetUserQueryVariables) {
      return { query: GetUserDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query getUsers($options: UserListOptionsInputType, $includeStaff: Boolean) {
  users(options: $options, includeStaff: $includeStaff) {
    items {
      ...User
    }
    count
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      options: // value for 'options'
 *      includeStaff: // value for 'includeStaff'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const GetUserOptionsDocument = gql`
    query getUserOptions($options: UserListOptionsInputType) {
  users(options: $options) {
    items {
      ...MinimalUser
    }
  }
}
    ${MinimalUserFragmentDoc}`;

/**
 * __useGetUserOptionsQuery__
 *
 * To run a query within a React component, call `useGetUserOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetUserOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserOptionsQuery, GetUserOptionsQueryVariables>) {
        return Apollo.useQuery<GetUserOptionsQuery, GetUserOptionsQueryVariables>(GetUserOptionsDocument, baseOptions);
      }
export function useGetUserOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOptionsQuery, GetUserOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetUserOptionsQuery, GetUserOptionsQueryVariables>(GetUserOptionsDocument, baseOptions);
        }
export type GetUserOptionsQueryHookResult = ReturnType<typeof useGetUserOptionsQuery>;
export type GetUserOptionsLazyQueryHookResult = ReturnType<typeof useGetUserOptionsLazyQuery>;
export type GetUserOptionsQueryResult = Apollo.QueryResult<GetUserOptionsQuery, GetUserOptionsQueryVariables>;
export function refetchGetUserOptionsQuery(variables?: GetUserOptionsQueryVariables) {
      return { query: GetUserOptionsDocument, variables: variables }
    }
export const GetSupervisorUserOptionsDocument = gql`
    query getSupervisorUserOptions($options: UserListOptionsInputType) {
  users(options: $options) {
    items {
      childUsers {
        id
      }
      parentsUsers {
        id
      }
      usernodeSet {
        id
        displayName
        level
      }
      childNodes {
        id
        displayName
        level
      }
      ...MinimalUser
    }
  }
}
    ${MinimalUserFragmentDoc}`;

/**
 * __useGetSupervisorUserOptionsQuery__
 *
 * To run a query within a React component, call `useGetSupervisorUserOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorUserOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorUserOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSupervisorUserOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSupervisorUserOptionsQuery, GetSupervisorUserOptionsQueryVariables>) {
        return Apollo.useQuery<GetSupervisorUserOptionsQuery, GetSupervisorUserOptionsQueryVariables>(GetSupervisorUserOptionsDocument, baseOptions);
      }
export function useGetSupervisorUserOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupervisorUserOptionsQuery, GetSupervisorUserOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetSupervisorUserOptionsQuery, GetSupervisorUserOptionsQueryVariables>(GetSupervisorUserOptionsDocument, baseOptions);
        }
export type GetSupervisorUserOptionsQueryHookResult = ReturnType<typeof useGetSupervisorUserOptionsQuery>;
export type GetSupervisorUserOptionsLazyQueryHookResult = ReturnType<typeof useGetSupervisorUserOptionsLazyQuery>;
export type GetSupervisorUserOptionsQueryResult = Apollo.QueryResult<GetSupervisorUserOptionsQuery, GetSupervisorUserOptionsQueryVariables>;
export function refetchGetSupervisorUserOptionsQuery(variables?: GetSupervisorUserOptionsQueryVariables) {
      return { query: GetSupervisorUserOptionsDocument, variables: variables }
    }
export const GetChildUsersDocument = gql`
    query getChildUsers($options: UserListOptionsInputType) {
  childUsers(options: $options) {
    items {
      ...User
    }
    count
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetChildUsersQuery__
 *
 * To run a query within a React component, call `useGetChildUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildUsersQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetChildUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetChildUsersQuery, GetChildUsersQueryVariables>) {
        return Apollo.useQuery<GetChildUsersQuery, GetChildUsersQueryVariables>(GetChildUsersDocument, baseOptions);
      }
export function useGetChildUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildUsersQuery, GetChildUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetChildUsersQuery, GetChildUsersQueryVariables>(GetChildUsersDocument, baseOptions);
        }
export type GetChildUsersQueryHookResult = ReturnType<typeof useGetChildUsersQuery>;
export type GetChildUsersLazyQueryHookResult = ReturnType<typeof useGetChildUsersLazyQuery>;
export type GetChildUsersQueryResult = Apollo.QueryResult<GetChildUsersQuery, GetChildUsersQueryVariables>;
export function refetchGetChildUsersQuery(variables?: GetChildUsersQueryVariables) {
      return { query: GetChildUsersDocument, variables: variables }
    }
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserMutationInput!) {
  createUser(input: $input) {
    user {
      ...User
    }
    errors {
      field
      messages
    }
  }
}
    ${UserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserMutationInput!) {
  updateUser(input: $input) {
    user {
      ...User
    }
    errors {
      field
      messages
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($id: UUID!) {
  role(id: $id) {
    ...Role
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export function refetchGetRoleQuery(variables?: GetRoleQueryVariables) {
      return { query: GetRoleDocument, variables: variables }
    }
export const GetRolesDocument = gql`
    query getRoles($options: RoleListOptionsInputType) {
  roles(options: $options) {
    items {
      ...Role
    }
    count
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, baseOptions);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, baseOptions);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export function refetchGetRolesQuery(variables?: GetRolesQueryVariables) {
      return { query: GetRolesDocument, variables: variables }
    }
export const GetRoleOptionsDocument = gql`
    query getRoleOptions($options: RoleListOptionsInputType) {
  roles(options: $options) {
    items {
      ...RoleOption
    }
  }
}
    ${RoleOptionFragmentDoc}`;

/**
 * __useGetRoleOptionsQuery__
 *
 * To run a query within a React component, call `useGetRoleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleOptionsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetRoleOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRoleOptionsQuery, GetRoleOptionsQueryVariables>) {
        return Apollo.useQuery<GetRoleOptionsQuery, GetRoleOptionsQueryVariables>(GetRoleOptionsDocument, baseOptions);
      }
export function useGetRoleOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleOptionsQuery, GetRoleOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetRoleOptionsQuery, GetRoleOptionsQueryVariables>(GetRoleOptionsDocument, baseOptions);
        }
export type GetRoleOptionsQueryHookResult = ReturnType<typeof useGetRoleOptionsQuery>;
export type GetRoleOptionsLazyQueryHookResult = ReturnType<typeof useGetRoleOptionsLazyQuery>;
export type GetRoleOptionsQueryResult = Apollo.QueryResult<GetRoleOptionsQuery, GetRoleOptionsQueryVariables>;
export function refetchGetRoleOptionsQuery(variables?: GetRoleOptionsQueryVariables) {
      return { query: GetRoleOptionsDocument, variables: variables }
    }
export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleMutationInput!) {
  createRole(input: $input) {
    role {
      ...Role
    }
    errors {
      field
      messages
    }
  }
}
    ${RoleFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($input: UpdateRoleMutationInput!) {
  updateRole(input: $input) {
    role {
      ...Role
    }
    errors {
      field
      messages
    }
  }
}
    ${RoleFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($input: DeleteRoleMutationInput!) {
  deleteRole(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetOrganizationDocument = gql`
    query getOrganization($id: UUID!) {
  organization(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, baseOptions);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, baseOptions);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export function refetchGetOrganizationQuery(variables?: GetOrganizationQueryVariables) {
      return { query: GetOrganizationDocument, variables: variables }
    }
export const GetOrganizationsDocument = gql`
    query getOrganizations($options: OrganizationListOptionsInputType) {
  organizations(options: $options) {
    items {
      ...Organization
    }
    count
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, baseOptions);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, baseOptions);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export function refetchGetOrganizationsQuery(variables?: GetOrganizationsQueryVariables) {
      return { query: GetOrganizationsDocument, variables: variables }
    }
export const GetChildOrganizationsDocument = gql`
    query getChildOrganizations($options: OrganizationListOptionsInputType) {
  childOrganizations(options: $options) {
    items {
      ...Organization
    }
    count
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useGetChildOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetChildOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildOrganizationsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetChildOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetChildOrganizationsQuery, GetChildOrganizationsQueryVariables>) {
        return Apollo.useQuery<GetChildOrganizationsQuery, GetChildOrganizationsQueryVariables>(GetChildOrganizationsDocument, baseOptions);
      }
export function useGetChildOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildOrganizationsQuery, GetChildOrganizationsQueryVariables>) {
          return Apollo.useLazyQuery<GetChildOrganizationsQuery, GetChildOrganizationsQueryVariables>(GetChildOrganizationsDocument, baseOptions);
        }
export type GetChildOrganizationsQueryHookResult = ReturnType<typeof useGetChildOrganizationsQuery>;
export type GetChildOrganizationsLazyQueryHookResult = ReturnType<typeof useGetChildOrganizationsLazyQuery>;
export type GetChildOrganizationsQueryResult = Apollo.QueryResult<GetChildOrganizationsQuery, GetChildOrganizationsQueryVariables>;
export function refetchGetChildOrganizationsQuery(variables?: GetChildOrganizationsQueryVariables) {
      return { query: GetChildOrganizationsDocument, variables: variables }
    }
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: CreateOrganizationMutationInput!) {
  createOrganization(input: $input) {
    organization {
      ...Organization
    }
    errors {
      field
      messages
    }
  }
}
    ${OrganizationFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, baseOptions);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($input: UpdateOrganizationMutationInput!) {
  updateOrganization(input: $input) {
    organization {
      ...Organization
    }
    errors {
      field
      messages
    }
  }
}
    ${OrganizationFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($input: DeleteOrganizationMutationInput!) {
  deleteOrganization(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, baseOptions);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    user {
      ...User
    }
    errors {
      field
      messages
    }
  }
}
    ${UserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordMutationInput!) {
  resetPassword(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($input: SetPasswordMutationInput!) {
  setPassword(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, baseOptions);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordMutationInput!) {
  updatePassword(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, baseOptions);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation sendResetPasswordEmail($input: SendResetPasswordEmailMutationInput!) {
  sendResetPasswordEmail(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, baseOptions);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const SendUserActivationEmailDocument = gql`
    mutation sendUserActivationEmail($input: SendUserActivationEmailMutationInput!) {
  sendUserActivationEmail(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type SendUserActivationEmailMutationFn = Apollo.MutationFunction<SendUserActivationEmailMutation, SendUserActivationEmailMutationVariables>;

/**
 * __useSendUserActivationEmailMutation__
 *
 * To run a mutation, you first call `useSendUserActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserActivationEmailMutation, { data, loading, error }] = useSendUserActivationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendUserActivationEmailMutation, SendUserActivationEmailMutationVariables>) {
        return Apollo.useMutation<SendUserActivationEmailMutation, SendUserActivationEmailMutationVariables>(SendUserActivationEmailDocument, baseOptions);
      }
export type SendUserActivationEmailMutationHookResult = ReturnType<typeof useSendUserActivationEmailMutation>;
export type SendUserActivationEmailMutationResult = Apollo.MutationResult<SendUserActivationEmailMutation>;
export type SendUserActivationEmailMutationOptions = Apollo.BaseMutationOptions<SendUserActivationEmailMutation, SendUserActivationEmailMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UpdateUserProfileMutationInput!) {
  updateUserProfile(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateCurrencyDocument = gql`
    mutation updateCurrency($input: UpdateCurrencyMutationInput!) {
  updateCurrency(input: $input) {
    tenant {
      currency
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type UpdateCurrencyMutationFn = Apollo.MutationFunction<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;

/**
 * __useUpdateCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyMutation, { data, loading, error }] = useUpdateCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>) {
        return Apollo.useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, baseOptions);
      }
export type UpdateCurrencyMutationHookResult = ReturnType<typeof useUpdateCurrencyMutation>;
export type UpdateCurrencyMutationResult = Apollo.MutationResult<UpdateCurrencyMutation>;
export type UpdateCurrencyMutationOptions = Apollo.BaseMutationOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export const ImportUsersDocument = gql`
    mutation importUsers($input: ImportUsersMutationInput!) {
  importUsers(input: $input) {
    errors {
      field
      messages
    }
  }
}
    `;
export type ImportUsersMutationFn = Apollo.MutationFunction<ImportUsersMutation, ImportUsersMutationVariables>;

/**
 * __useImportUsersMutation__
 *
 * To run a mutation, you first call `useImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersMutation, { data, loading, error }] = useImportUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportUsersMutation(baseOptions?: Apollo.MutationHookOptions<ImportUsersMutation, ImportUsersMutationVariables>) {
        return Apollo.useMutation<ImportUsersMutation, ImportUsersMutationVariables>(ImportUsersDocument, baseOptions);
      }
export type ImportUsersMutationHookResult = ReturnType<typeof useImportUsersMutation>;
export type ImportUsersMutationResult = Apollo.MutationResult<ImportUsersMutation>;
export type ImportUsersMutationOptions = Apollo.BaseMutationOptions<ImportUsersMutation, ImportUsersMutationVariables>;
export const ToggleEmailNotificationsDocument = gql`
    mutation toggleEmailNotifications {
  toggleEmailNotifications {
    ok
  }
}
    `;
export type ToggleEmailNotificationsMutationFn = Apollo.MutationFunction<ToggleEmailNotificationsMutation, ToggleEmailNotificationsMutationVariables>;

/**
 * __useToggleEmailNotificationsMutation__
 *
 * To run a mutation, you first call `useToggleEmailNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleEmailNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleEmailNotificationsMutation, { data, loading, error }] = useToggleEmailNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleEmailNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleEmailNotificationsMutation, ToggleEmailNotificationsMutationVariables>) {
        return Apollo.useMutation<ToggleEmailNotificationsMutation, ToggleEmailNotificationsMutationVariables>(ToggleEmailNotificationsDocument, baseOptions);
      }
export type ToggleEmailNotificationsMutationHookResult = ReturnType<typeof useToggleEmailNotificationsMutation>;
export type ToggleEmailNotificationsMutationResult = Apollo.MutationResult<ToggleEmailNotificationsMutation>;
export type ToggleEmailNotificationsMutationOptions = Apollo.BaseMutationOptions<ToggleEmailNotificationsMutation, ToggleEmailNotificationsMutationVariables>;
export const GetTreeDocument = gql`
    query getTree {
  tree {
    parents {
      ...TreeUser
    }
    childs {
      ...TreeUser
    }
    equal {
      ...TreeUser
    }
  }
}
    ${TreeUserFragmentDoc}`;

/**
 * __useGetTreeQuery__
 *
 * To run a query within a React component, call `useGetTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTreeQuery(baseOptions?: Apollo.QueryHookOptions<GetTreeQuery, GetTreeQueryVariables>) {
        return Apollo.useQuery<GetTreeQuery, GetTreeQueryVariables>(GetTreeDocument, baseOptions);
      }
export function useGetTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTreeQuery, GetTreeQueryVariables>) {
          return Apollo.useLazyQuery<GetTreeQuery, GetTreeQueryVariables>(GetTreeDocument, baseOptions);
        }
export type GetTreeQueryHookResult = ReturnType<typeof useGetTreeQuery>;
export type GetTreeLazyQueryHookResult = ReturnType<typeof useGetTreeLazyQuery>;
export type GetTreeQueryResult = Apollo.QueryResult<GetTreeQuery, GetTreeQueryVariables>;
export function refetchGetTreeQuery(variables?: GetTreeQueryVariables) {
      return { query: GetTreeDocument, variables: variables }
    }
export const GetUserNodesDocument = gql`
    query getUserNodes {
  userNodes {
    id
    parent {
      id
    }
    users {
      ...TreeUser
    }
  }
}
    ${TreeUserFragmentDoc}`;

/**
 * __useGetUserNodesQuery__
 *
 * To run a query within a React component, call `useGetUserNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNodesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNodesQuery, GetUserNodesQueryVariables>) {
        return Apollo.useQuery<GetUserNodesQuery, GetUserNodesQueryVariables>(GetUserNodesDocument, baseOptions);
      }
export function useGetUserNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNodesQuery, GetUserNodesQueryVariables>) {
          return Apollo.useLazyQuery<GetUserNodesQuery, GetUserNodesQueryVariables>(GetUserNodesDocument, baseOptions);
        }
export type GetUserNodesQueryHookResult = ReturnType<typeof useGetUserNodesQuery>;
export type GetUserNodesLazyQueryHookResult = ReturnType<typeof useGetUserNodesLazyQuery>;
export type GetUserNodesQueryResult = Apollo.QueryResult<GetUserNodesQuery, GetUserNodesQueryVariables>;
export function refetchGetUserNodesQuery(variables?: GetUserNodesQueryVariables) {
      return { query: GetUserNodesDocument, variables: variables }
    }
export const GetWorkflowDocument = gql`
    query getWorkflow {
  workflow {
    ...Workflow
  }
}
    ${WorkflowFragmentDoc}`;

/**
 * __useGetWorkflowQuery__
 *
 * To run a query within a React component, call `useGetWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkflowQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkflowQuery, GetWorkflowQueryVariables>) {
        return Apollo.useQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(GetWorkflowDocument, baseOptions);
      }
export function useGetWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkflowQuery, GetWorkflowQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(GetWorkflowDocument, baseOptions);
        }
export type GetWorkflowQueryHookResult = ReturnType<typeof useGetWorkflowQuery>;
export type GetWorkflowLazyQueryHookResult = ReturnType<typeof useGetWorkflowLazyQuery>;
export type GetWorkflowQueryResult = Apollo.QueryResult<GetWorkflowQuery, GetWorkflowQueryVariables>;
export function refetchGetWorkflowQuery(variables?: GetWorkflowQueryVariables) {
      return { query: GetWorkflowDocument, variables: variables }
    }
export const GetWorkflowStatesDocument = gql`
    query getWorkflowStates {
  workflowStates
}
    `;

/**
 * __useGetWorkflowStatesQuery__
 *
 * To run a query within a React component, call `useGetWorkflowStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkflowStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkflowStatesQuery, GetWorkflowStatesQueryVariables>) {
        return Apollo.useQuery<GetWorkflowStatesQuery, GetWorkflowStatesQueryVariables>(GetWorkflowStatesDocument, baseOptions);
      }
export function useGetWorkflowStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkflowStatesQuery, GetWorkflowStatesQueryVariables>) {
          return Apollo.useLazyQuery<GetWorkflowStatesQuery, GetWorkflowStatesQueryVariables>(GetWorkflowStatesDocument, baseOptions);
        }
export type GetWorkflowStatesQueryHookResult = ReturnType<typeof useGetWorkflowStatesQuery>;
export type GetWorkflowStatesLazyQueryHookResult = ReturnType<typeof useGetWorkflowStatesLazyQuery>;
export type GetWorkflowStatesQueryResult = Apollo.QueryResult<GetWorkflowStatesQuery, GetWorkflowStatesQueryVariables>;
export function refetchGetWorkflowStatesQuery(variables?: GetWorkflowStatesQueryVariables) {
      return { query: GetWorkflowStatesDocument, variables: variables }
    }
export const UpdateWorkflowDocument = gql`
    mutation updateWorkflow($input: UpdateWorkflowMutationInput!) {
  updateWorkflow(input: $input) {
    workflow {
      ...Workflow
    }
    errors {
      field
      messages
    }
  }
}
    ${WorkflowFragmentDoc}`;
export type UpdateWorkflowMutationFn = Apollo.MutationFunction<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>;

/**
 * __useUpdateWorkflowMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowMutation, { data, loading, error }] = useUpdateWorkflowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>) {
        return Apollo.useMutation<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>(UpdateWorkflowDocument, baseOptions);
      }
export type UpdateWorkflowMutationHookResult = ReturnType<typeof useUpdateWorkflowMutation>;
export type UpdateWorkflowMutationResult = Apollo.MutationResult<UpdateWorkflowMutation>;
export type UpdateWorkflowMutationOptions = Apollo.BaseMutationOptions<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>;